/*
 *   File : addFund.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : edit fund modal
 *   Integrations :14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import components*/
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { AggressiveFund } from '../../components/card/index';
import { SearchInput } from '../../components/input/index';
import { Toast } from '../note';
import { message } from 'antd';

/*import styles*/
import modalStyle from '../../styles/components/confirmation.module.scss';
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import SearchFunds from '../../assets/images/dataFormating/search-funds.svg'

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { Loader } from '../loader';


export default function AddFund(props) {
	const [search, setSearch] = useState("");
	const [getFundId, setGetFundId] = useState(null);
	const [funds, setFunds] = useState([]);
	const [error, setError] = useState(null);
	const [response, setResponse] = useState(null);
	const [searchLoader, setSearchLoader] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);


	/* ---- search funds api ---*/
	const searchFunds = () => {
		setSearchLoader(true)
		let url = devconfig.admin + '/funds/search-mf'

		let data = JSON.stringify({
			query: search,
			page_num: 1,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setFunds(response.data);
				setResponse(response.message);
				setSearchLoader(false);
			}
			else {
				setError(response.message);
				setSearchLoader(false);
			}
		})
		setTimeout(() => {
			setError(null);
		}, 3000);
	}

	/*-----update fund api---- */
	const updateFund = () => {
		let url = devconfig.admin + '/funds/update-risk-fund'
		let data = JSON.stringify({
			portfolio_id: props.editedData.portfolio_id,
			risk_score_id: props.riskId,
			scheme_code: getFundId,
			name: props.editedData.name,
			global_class_id: props.editedData.global_class_id,
			allocation: Number(props.editedData.allocation)
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success("Fund updated Successfully")
				$('#addFund').trigger('click');
				props.updatedFund(true)
			}
			else {
				setError(response.message);
			}
		})
		setTimeout(() => {
			setError(null);
		}, 3000);
	}
	return (

		<div className={`${modalStyle.e_confirmation} modal fade`} id="addFund" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h6>Edit fund</h6>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
							onClick={() => {
								setSearch("");
								setResponse(null);
								setFunds([])
							}}>
						</button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className="col-lg-12 position-relative mb-4">
								<SearchInput placeholder="Search for funds" value={search} valueChange={(value) => { setSearch(value); }} />
								{
									search && search.length ?
										<>
											<span
												className={`${styles.e_search_submit} `}
												onClick={() => searchFunds()}>
											</span>
											<button
												className={`${styles.e_search_close} pe-3`}
												onClick={() => {
													setSearch("");
													setResponse(null);
													setFunds([]);
												}} >
											</button>
										</>
										:
										null
								}

							</div>
						</div>
						{
							response !== null ?
								funds?.length > 0 ?
									<div className="row">
										<p>Results</p>
										{
											funds.map((item, key) => {
												return (
													<>
														<div className="col-lg-6 mb-3 pb-2" key={key} >
															<AggressiveFund
																data={item}
																selectedFund={getFundId}
																selectedFundData={(temp) => {
																	setGetFundId(temp);
																}} />
														</div>
													</>
												)
											})
										}
									</div>
									:
									<div className="row">
										<h6 className="text-center"> No Mutual Funds Found </h6>
									</div>
								:
								searchLoader === false ?
									<div align="center">
										<img src={SearchFunds} alt="" />
										<p >Search Funds</p>
									</div>
									:
									<Loader />
						}
						{/* {
							searchLoader === false ?
								search !== "" ?
									null
									:
									<div align="center">
										<img src={SearchFunds} alt="" />
										<p >Search Funds</p>
									</div>
								:
								searchLoader === true ?
									<Loader />
									:
									null
						} */}

						<div className="row pt-4 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn
										name="Cancel"
										className="e-cancel"
										cancel="modal"
										handleClick={() => {
											setSearch("");
											setResponse(null);
											setFunds([]);
										}} />
									{
										getFundId ?
											<PrimaryBtn
												name="Save fund"
												className="e-modal-submit"
												handleClick={() => updateFund()}
											/>
											:
											<PrimaryBtn
												name="Save fund"
												className="e-disabled"
											/>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
		</div>
	);
}
