/*
 *   File : profile.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : profile
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
*/

/*import packages*/
import { useState } from 'react';
import { Modal } from 'bootstrap'

/*import components*/
import { ProfileKyc } from '../../components/card/index';
// import { EditUser } from '../../components/modal/index';

const Profile = (props) => {
	//eslint-disable-next-line
	const [editableData, setEditableData] = useState("");

	/* --- func to open edit user detail modal ---*/
	const setData = (temp) => {
		setEditableData(temp);
		var myModal = new Modal(document.getElementById("editDetails"), {});
		myModal.show();
	}

	return (
		<>
			<div className="col-lg-12" >
				<ProfileKyc
					data={props.data}
					title="Profile overview"
					type={1}
					Cliked={(temp) => setData(temp)}
					edit={true}
				/>
			</div>
			<div className="col-lg-12" >
				<ProfileKyc
					data={props.data}
					title="KYC details"
					type={2}
					Cliked={(temp) => setData(temp)}
					edit={true} verified={props.data.address_data.is_verified}
				/>
			</div>
			<div className="col-lg-6" >
				<ProfileKyc
					data={props.data}
					title="Bank details"
					type={3}
					Cliked={(temp) => setData(temp)}
					verified={props.data.bank_account_data.is_verified}
				/>
			</div>
			{/* <EditUser id="editDetails" data={editableData} userId={props.userId} editUserDetails={(data) => props.editUserDetails(data)} /> */}
		</>
	);
}

export default Profile;