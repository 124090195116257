/*
 *   File : Approve.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Delete Modal
 *   Integrations : 21-02-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect } from 'react';
import $ from 'jquery';


/*import styles*/
import styles from '../../styles/components/approve.module.scss';

/* import assets */
import approve from '../../assets/images/modal/checkmark.svg'
import Loader from '../../assets/images/loader/loader_ring.gif';
import Failed from '../../assets/images/note/remove.png';

export default function Approve(props) {

	useEffect(()=>{
		if(!props.loader){
			setTimeout(()=>{
				$('#approve').trigger('click');
				props.reload();
			},2000)	
		}
	},[props.loader])


	return (
		<div className={`${styles.e_approve} modal fade`} id="approve" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className="modal-header">
						{/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button> */}
					</div>
					<div className="container px-3">
						<div className="row">
							{
								// props.loader ?
								// 	<div className='col-lg-12 text-center'>
								// 		<img src={Loader} className={`${styles.e_approve_loader}`}/>
								// 	</div>
								// :
									<>
										<div className="col-lg-12 text-center mb-3">
											{
												!props.message ?
													<img src={approve} alt="" width="46px" height="46px"/>
												:
													<img src={Failed} alt="" width="46px" height="46px"/>
											}
											
										</div>
										{
											!props.message ?
												<h6 className={ `${styles.e_approved_txt}`}>{ props.type === 1 ? 'Request approved' : 'Request rejected'}</h6>
											:
												<h6 className={ `${styles.e_failed_txt}`}>{ props.type === 1 ? 'Failed to approved' : 'Failed to rejected'}</h6>
										}
										
										<div className='row'>
											{
												!props.message ?	
													<div className='col-lg-10 offset-lg-2'>
														{
															props.type === 1 ?
																<p >You have successfully approved the client’s request.</p>
															:
																<p >You have rejected the client’s request.</p>
														}
													</div>
												:
													<div className='col-lg-10 text-center offset-1'>
														<p className={`ms-3 ${styles.e_failed_message}`}>{props.message}</p>
													</div>
											}
											
										</div>
									</>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
