
import { useState, useEffect} from 'react';

import styles from '../../styles/components/historyModal.module.scss';

const ReportHistory = (props) => {

    useEffect(()=>{
        var myModalEl = document.getElementById('historyModal')
        myModalEl.addEventListener('hidden.bs.modal', function () {
            props.modalClose()
        })
    },[])
    var count = 0;
    const colors =  ["#1329E6", "#6A79FF", "#2F3DB9", "#644DEC", "#AF93F6", "#C6D9FF", "#72BBFF", "#127CC9"]
    return(
        <div className={`${styles.e_history} modal fade`} id="historyModal" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className="modal-content">
					<div className="modal-header">
						<h6>Monthly Report History</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
                    <div className={`container ${styles.e_card_container}`}>
                        <div className='row'>
                            {
                                props.data.map((item, key) => {
                                    if ( count >= 7) count = 0;
                                    else  count = count+1;
                                    return(
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 mb-4' key={key}>
                                            <div className={`${styles.e_history_card} px-4 py-3`}>
                                                <div className='row'>
                                                    <div className='col-3'>
                                                        {console.log('count', count)}
                                                        <span 
                                                            style={{
                                                                backgroundColor: colors[count] 
                                                            }}
                                                            className={styles.e_placeholder}
                                                        >
                                                        {item.name.charAt(0)}     
                                                        </span>
                                                    </div>
                                                    <div className='col-9 mt-1'>
                                                        <span className={styles.e_name}>
                                                            {item.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportHistory;