/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 20-01-2022
 *   Version : v1.0
 *   Created : 10-12-2021
 */
/*import pakcages*/
import { useState, useEffect, useRef, } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

/*import components*/
import { DataTable, Tab } from '../../components/dataFormating/index';
import { FilterBtn, DownloadBtn } from '../../components/cta/index';
import { SearchInput, DateRangePicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Loader } from '../../components/loader';
import { EmptyNote, Toast } from '../../components/note';
import Pending from '../../assets/images/info.png'

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import View from '../../assets/images/dataFormating/view.png';
import Block from '../../assets/images/dataFormating/warning.png';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';

import { IconStyleToolTip } from '../../components/badge';
import { message } from 'antd';


const Index = (props) => {

	const navigate = useNavigate();
	const wrapperRef = useRef(null);
	const [search, setSearch] = useState('');
	const [more, setMore] = useState(false);
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: null, end: null });
	const [refKey, setRefKay] = useState('');
	const [check, setCheck] = useState([])
	const [selectedRisk, setSelectedRisk] = useState({});
	const [selectedCity, setSelectedCity] = useState({});
	const [userData, setUserData] = useState([]);
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [cityFilter, setCityFilter] = useState([]);
	const [riskFilter, setRiskFilter] = useState([]);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [tabIndex, setTabIndex] = useState(1);
	const [isShownCurrent, setIsShownCurrent] = useState(false);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		filterData();

		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);


	useEffect(() => {
		if (tabIndex === 1 && search == "") {
			getUsersList();
		}
		//eslint-disable-next-line
	}, [dates, selectedRisk, selectedCity, tabIndex, search]);

	useEffect(() => {
		if ((tabIndex === 2 || tabIndex === 3) && search === "") {
			getInactiveList();
		}
		//eslint-disable-next-line
	}, [dates, tabIndex, search]);

	const customDateSort = (a, b, selector, given) => {
		let format = given ? given : "DD MMM YYYY"
		if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
		else if (a[selector]) return -1;
		else if (b[selector]) return 1;
		else return 0;
	}

	/* ---- columns for user table ---*/
	const columns = [
		{
			name: 'Cust. ID',
			selector: (row) => row.id ? row.id : '--',
			sortable: true,
			left: true,
			maxWidth: "120px",
			defaultSortAsc: false,
		},
		{
			name: 'Name',
			selector: (row) => row.name ? row.name : '--',
			sortable: true,
			left: true,

		},
		{
			name: 'Email',
			selector: (row) => row.email ? row.email : '--',
			sortable: false,
			left: true,
			minWidth: "300px",
		},
		{
			name: 'Joined on',
			selector: "joined_date",
			sortable: false,
			right: false,
			sortFunction: (a, b) => customDateSort(a, b, 'joined_date',),
			Cell: (row) => row.joined_date ? moment(row.joined_date).format("MM/DD/YYYY") : '--',
		},
		{
			name: 'Risk',
			selector: (row) => row.risk_score >= 0 ? row.risk_score : '--',
			sortable: true,
			maxWidth: "80px",
			right: false,
		},
		{
			name: 'Phone',
			selector: (row) => row.phone ? row.phone : '--',
			sortable: false,
			right: false,
			maxWidth: "180px",
		},
		{
			name: '',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: row => <div onClick={() => { setRefKay(row.id); setMore(true) }} ref={wrapperRef}>
				<span className="e-more" ></span>
				{
					more && row.id === refKey ?
						<div className="e-card" ref={wrapperRef} >
							<div onClick={() => navigate(`/user-detail?id=${row.id}`)}><img src={View} alt="View details" className="me-3" /><span>View details</span></div>
							{
								row.is_blocked === true ?
									<div className='mt-3' onClick={() => blockUnblockUser(row.id, row.is_blocked)}><img src={Block} alt="Block" className="me-3" />Unblock user</div>
									:
									<div className='mt-3' onClick={() => blockUnblockUser(row.id, row.is_blocked)}><img src={Block} alt="Block" className="me-3" />Block user</div>
							}
						</div>
						:
						null
				}
			</div>
		},
	];

	/*---- filter api for risk score and city ---*/
	const filterData = () => {
		let city_options = [];
		let risk_options = [];
		let url = devconfig.admin + '/user/active-filter-data'

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				response.data.city.map((item) => {
					city_options.push({ value: item.id, label: item.name });
					return true;
				});
				response.data.risk_score.map((item) => {
					risk_options.push({ value: item.id, label: item.name });
					return true;
				});
				setCityFilter(city_options);
				setRiskFilter(risk_options);
			}
			else {
				setError(response.message);
			}
		});
	}

	/* ------------ active user search api -----------*/
	const searchUsers = (value) => {
		setRiskFilter([]);
		setCityFilter([]);
		// setSearch(value);
		setLoader(true);
		let url = devconfig.admin + '/user/active-list-search'
		let data = JSON.stringify({
			query: search || value
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				setUserData(response.data);
				filterData();
				setSearch(value);
				setSearch(search);
			}
			else {
				setUserData([])
				setLoader(false);
				// setError(response.message);
			}
		});
	}

	/*--------- active users list -----------*/
	const getUsersList = () => {
		setLoader(true)
		let url = devconfig.admin + '/user/active-list';
		let data = JSON.stringify({
			from_date: dates.start,
			till_date: dates.end,
			city: selectedCity.value,
			risk_score: selectedRisk.value,
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setUserData(response.data);
				setLoader(false);
			}
			else {
				setUserData([]);
				setLoader(false)
			}
		})
	}

	/*--------- inactive and drop users list -----------*/
	const getInactiveList = (temp) => {
		setLoader(true)
		let url = devconfig.admin + '/user/list';
		let data = JSON.stringify({
			from_date: dates.start,
			till_date: dates.end,
			query: temp ? temp.query : search,
			type: tabIndex
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setUserData(response.data);
				setLoader(false);
			}
			else {
				setUserData([]);
				setLoader(false)
			}
		})
	}

	/*---- download list api--*/
	const downloadData = () => {
		setDownloadLoader(true);

		let url = devconfig.admin + '/user/download-active-users';

		let data = JSON.stringify({
			user_ids: check
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					let a = document.createElement("a");
					a.href = response.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setCheck([]);
					setDownloadLoader(false);
					setSearch("");
				} else {
					setError(response.message);
					setDownloadLoader(false);
					setCheck([]);
				}
			}
			else {
				setError(response.message);
				setDownloadLoader(false);
				setCheck([]);
			}
		})
	}

	/*---- download list api--*/
	const downloadInactiveData = () => {
		setDownloadLoader(true);

		let url = devconfig.admin + '/user/download';

		let data = JSON.stringify({
			user_ids: check,
			type: tabIndex
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					let a = document.createElement("a");
					a.href = response.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setCheck([]);
					setDownloadLoader(false);
					setSearch("");
				} else {
					setError(response.message);
					setDownloadLoader(false);
					setCheck([]);
				}
			}
			else {
				setError(response.message);
				setDownloadLoader(false);
				setCheck([]);
			}
		})
	}

	/*--- block user---*/
	const blockUnblockUser = (id, block_status) => {
		let url = devconfig.admin + '/user/block';

		let data = JSON.stringify({
			user_id: id
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (block_status === true) {
					message.success("User unblocked");
					setMore(false)
				} else {
					message.success("User blocked");
					setMore(false)
				}

				let userIndex = userData.findIndex((user) => user.id === id); // user index

				if (userIndex !== -1) {
					userData[userIndex]['is_blocked'] = !block_status;
				}
			} else {
				setError(response.message);
			}
		});
	}

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false);
		}
	}

	/* --- selected row pushed to array ---*/
	const handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['id']);
		}
		setCheck(selected);
	}

	const downloadUsers = () => {
		let url = devconfig.admin + '/user/download';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				window.open(response.data.url)
			}
		})
	}

	return (
		<div className="row">
			<div className="col-lg-12">
				<div className="card e-dashboard-card border-0">
					<div className="row pt-2">
						<div className="col-lg-5">
							{/* <Tab
								data={["Active", "Inactive", "Drop Off"]}
								setTab={(temp) => {
									setTabIndex(temp);
									setSearch("");
									setCheck([]);
									setFilterDown(false);
									setDates({
										start: null,
										end: null
									})
								}}
								currentIndex={tabIndex} /> */}
						</div>
						<div className="col-lg-3 align-items-center text-end" >
							<DownloadBtn
								check={check}
								onClick={() => {
									if (tabIndex === 1) {
										downloadData()
									} else {
										downloadInactiveData()
									}
								}}
							/>
						</div>
						{
							tabIndex === 1 ?
								<div className="col-lg-3 position-relative">
									<SearchInput
										placeholder="Search with name"
										value={search}
										valueChange={(value) => { setSearch(value) }}
										onSearch={(value) => searchUsers(value)}
									/>
									{
										search?.length > 0 ?
											<>
												<span className={`${styles.e_search_submit} `} onClick={() => { searchUsers(); }} ></span>
												<button className={`${styles.e_search_close} pe-3`}
													onClick={() => {
														setSearch("");
														getUsersList();
													}} ></button>
											</>
											:
											null
									}
								</div>
								:
								<div className="col-lg-3 position-relative">
									<SearchInput
										placeholder="Search with name"
										value={search}
										valueChange={(value) => { setSearch(value) }}
										onSearch={(value) => getInactiveList({ query: value })}
									/>
									{
										search?.length > 0 ?
											<>
												<span className={`${styles.e_search_submit} `} onClick={() => { getInactiveList(); }} ></span>
												<button className={`${styles.e_search_close} pe-3`}
													onClick={() => {
														setSearch("");
													}} ></button>
											</>
											:
											null
									}
								</div>
						}
						<div className="col-lg-1">
							<FilterBtn
								className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
								handleClick={() => {
									setFilterDown(!filterDown); setSearch("");
									if (filterDown === true) {
										setDates({ start: null, end: null });
										setSelectedCity({});
										setSelectedRisk({});
									}
								}}
							/>
						</div>
						<DownloadBtn
							optionalDisable={true}
							name="Download entire users"
							className="e-user-download"
							onClick={() => downloadUsers()}
						/>
						<img src={Pending} className="e-header-tooltip"
							onMouseEnter={() => setIsShownCurrent(true)}
							onMouseLeave={() => setIsShownCurrent(false)}
						/>
						{
							isShownCurrent ?
								<IconStyleToolTip
									className="e-tooltip-user-list"
									content="Data includes investors and prospects data "
								/>
								:
								null
						}

						{
							riskFilter.length && cityFilter.length && filterDown ?
								<div className="e-filter-wraper e-sort mt-3">
									<div className="row">
										<div className="col-lg-3 pe-0">
											<label className="mb-2">Joined from - till</label>
											<DateRangePicker
												start={dates.start}
												end={dates.end}
												selectPrevious={true}
												maxDate={moment().format('DD-MM-YYY')}
												onDatesChange={(start, end) => {
													setDates({ start: start, end: end });
												}}
											/>
										</div>
										{
											tabIndex === 1 ?
												<>
													<div className="col-lg-2 px-2">
														<label className="mb-2">Risk Score</label>
														<SelectBox
															value={Object.keys(selectedRisk).length > 0 ? selectedRisk : null}
															options={riskFilter}
															selectchange={(temp) => setSelectedRisk(temp)}
															isSearchable={false}
														/>
													</div>
													<div className="col-lg-2 p-0">
														<label className="mb-2">City</label>
														<SelectBox
															value={Object.keys(selectedCity).length > 0 ? selectedCity : null}
															options={cityFilter}
															selectchange={(temp) => setSelectedCity(temp)}
															isSearchable={true}
														/>
													</div>
												</>
												:
												null
										}
									</div>
								</div>
								:
								null
						}
						{
							loader ?
								<Loader />
								: userData.length > 0 ?
									<div className="col-lg-12 px-0">
										<DataTable
											pagination={true}
											columns={columns}
											data={userData}
											clearSelectedRows={downloadLoader ? true : false}
											selectableRows={true}
											selectRows={(row) => { handleChange(row) }}
											rowClick={(row) => navigate(`/user-detail?id=${row.id}`)}
										/>
									</div>
									:
									<EmptyNote note="User list not found" />
						}
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
		</div>
	);
}

export default Index;

