/*
 *   File : addGlobalClass.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Change Allocation Success Modal
 *   Integrations :29-04-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/risk.module.scss';
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

/*import services */


export default function AddGlobalClass(props) {

    const [name, setName] = useState("");
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    /* add global class */
    const addGlobalClass = () => {
        setLoader(true);
        let url = devconfig.admin + '/global-class/add'
        let data = JSON.stringify({
            class_name: name
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.status === true) {
                    $("#addGlobalClass").trigger('click');
                    props.addedClass(true);
                    setLoader(false);
                    setName("");
                } else {
                    setError(response.message)
                    setLoader(false);
                }
            }
            else {
                setError(response.message);
                setLoader(false);
            }
        });
    }

    return (
        <div className={`${styles.e_risk} modal fade`} id="addGlobalClass" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>Add new global fund class</h6>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setName("")}>
                        </button>
                    </div>
                    <div className="container px-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <label>Global class name </label>
                                <Input
                                    placeholder="Equity"
                                    value={name}
                                    valueChange={(value) => {
                                        setName(value)
                                    }}
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="row pt-4 mt-2 pb-2">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-end">
                                    <SecondaryBtn
                                        name="Cancel"
                                        className={`${styles.e_cancel} e-cancel`}
                                        cancel="modal"
                                        handleClick={() => setName("")}
                                    />
                                    {
                                        name ?
                                            <PrimaryBtn
                                                name="Submit"
                                                className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                handleClick={() => addGlobalClass()}
                                            />
                                            :
                                            <PrimaryBtn
                                                name="Submit"
                                                className='e-modal-submit e-disabled'
                                            />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} type="fail" format={1} />
                    :
                    null
            }
        </div>
    );
}
