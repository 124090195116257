/*
 *   File : primary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/* import packages */
import React from 'react';

/*Style Import*/
import styles from '../../styles/components/primary.module.scss';

const PrimaryBtn = (props) => {

	/* --- on press enter key ---*/
	const keyPress = (e) => {
		if (e.keyCode === 13) {
			props.onEnter();
		}
	}

	return (
		<button id={props.id}
			className={`${styles.e_p_btn} ${props.className}`}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
			onKeyDown={(e) => keyPress(e)}
			type={props.type ? props.type : "submit"}
		><span>{props.name}</span></button>
	);
}

export default PrimaryBtn;
