/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Transactions container;
 *   Integrations : 17-02-2022
 *   Version : v1.0
 *   Created : 13-12-2021
*/
/*import packages*/
import { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';

/*import components*/
import SelectBox from '../../components/dropdown/selectBox';
import { EmptyNote, Toast } from '../../components/note';
import { DataTable } from '../../components/dataFormating/index';
import { FilterBtn, PrimaryBtn, PrimaryLink } from '../../components/cta/index';
import { Approve } from '../../components/modal/index';
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import { Loader } from '../../components/loader';
import APIPost from '../../services/postApi';


const Index = (props) => {

	const [filterDown, setFilterDown] = useState(false);
	const [error, setError] = useState(null);
	const [selectedType, setSelectedType] = useState({});
	const [list, setList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [apiLoader, setApiLoader] = useState(false);
	const [requestType, setRequestType] = useState();
	const [messaage, setMessage] = useState();
	const [options, setOptions] = useState();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

	}, []);

	useEffect(() => {
		getFilters();
	}, [])

	useEffect(() => {
		getRiskScoreRequest()
		//eslint-disable-next-line
	}, [selectedType])

	const getFilters = () => {
		var temp = [];
		let url = devconfig.admin + '/request/filter-data';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				//eslint-disable-next-line
				response.data.map((item, key) => {
					temp.push({ value: item.id, label: item.name })
				})
			}
		})

		setOptions(temp);
	}

	useEffect(() => {
		if (options) {
			setSelectedType(options[0])
		}
	}, [options])

	const getRiskScoreRequest = () => {
		setLoader(true);
		let url = devconfig.admin + '/request/list';
		let data = JSON.stringify({
			type: selectedType ? selectedType.value : null,
		})
		APIPost(url, data).then((response) => {
			if (response.status) {
				setLoader(false);
				setList(response.data);
			}
			else {
				setLoader(false);
				setList(null);
			}
		})
	}

	const manageScore = (type, id) => {
		setApiLoader(true);
		setRequestType(type);
		modalOpen();
		let url = devconfig.admin + '/request/manage';
		let data = JSON.stringify({
			type: parseInt(type),
			request_id: id
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setTimeout(() => { setApiLoader(false) }, 1000);
			} else {
				setMessage(response.message);
				setTimeout(() => { setApiLoader(false) }, 2000);
			}
		})
	}

	/*---  modal ---*/
	const modalOpen = (row) => {
		var myModal = new Modal(document.getElementById("approve"), {});
		myModal.show();
	}

	useEffect(() => {
		setTimeout(() => {
			if (error !== null) {
				setError(null);
			}
		}, 3000);
	}, [error]);

	// const typeOptions = [
	// 	{
	// 		value: 1, label: "All"
	// 	},
	// 	{
	// 		value: 2, label: "Edit score"
	// 	},
	// 	{
	// 		value: 3, label: "Remove score"
	// 	},
	// ]



	/*---- transactions table columns -----*/

	const columns = [
		{
			name: 'User name',
			selector: (row) => row.name ? row.name : '--',
			sortable: true,
			left: true,
			maxWidth: "160px",
		},
		{
			name: 'Raised on',
			selector: (row) => row.requested_date,
			sortable: false,
			left: props.tabIndex ? false : true,
			maxWidth: "160px",
		},
		{
			name: 'Type',
			selector: (row) => row.type === 1 ? 'Edit' : 'Remove',
			sortable: true,
			left: true,
			maxWidth: "160px",
		},
		{
			name: 'Description',
			left: true,
			minWidth: "250px",
			maxWidth: "400px",
			cell: (row) => <div>
				{row.message}
			</div>
		},
		{
			name: 'Action',
			left: true,
			maxWidth: "150px",
			cell: (row) => <div className='justify-content-end '>
				{
					row.status === "Requested" ?
						<PrimaryLink name="Reject" className="e-reject-link" handleClick={() => { manageScore(2, row.id) }} />
						:
						<div className='pt-3'>
							<h6 className={row.status === 'REJECTED' ? 'e-request-rejected' : 'e-request-status'}>{row.status}</h6>
							<p className='e-request-status-date'>{row.modified_date}</p>
						</div>
				}
			</div>,
		},
		{
			name: '',
			// selector: 'actions',
			sortable: false,
			right: true,
			maxWidth: "150px",

			cell: (row) => <div className='mx-auto'>
				{
					row.status === "Accepted" || row.status === "Rejected" ?
						null
						:
						<PrimaryBtn name="Approve" handleClick={() => { manageScore(1, row.id) }} />
				}
			</div>
		},
	]




	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<div className="card e-dashboard-card border-0 e-request-card">
						<div className="row pt-2">
							<div className="col-lg-11">
								<h6 className="e-card-title p-2">All requests</h6>
							</div>
							<div className="col-lg-1">
								<FilterBtn
									className={filterDown ? "e-filter-btn e-active ms-auto" : "e-filter-btn ms-auto"}
									handleClick={() => {
										setFilterDown(!filterDown);
									}}
								/>
							</div>
							{/*-------------- Filters  ---------------*/}
							{
								filterDown ?
									<div className="e-filter-wraper e-sort mt-3">
										<div className="row ps-2">
											<div className="col-lg-2  p-0">
												<label className="mb-2">Type</label>
												<SelectBox
													value={selectedType}
													options={options}
													selectchange={(temp) => { setSelectedType(temp); }}
													isSearchable={false}
													defaultValue={options[0]}
												/>
											</div>
										</div>
									</div>
									:
									null
							}

							{/*--------------- Request table -----------*/}
							<div className="col-lg-12 px-1">
								{
									loader ?
										<Loader />
										:
										list.length > 0 ?
											<DataTable
												pagination={true}
												columns={columns}
												data={list}
											/>
											:
											<EmptyNote note="There is no request found" />
								}

							</div>
						</div>
					</div>
				</div>
			</div>

			{/*--------- Approve modal component ----------------*/}
			<div>
				<Approve
					loader={apiLoader}
					type={requestType}
					message={messaage}
					reload={() => getRiskScoreRequest()}
				/>
			</div>
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
		</>
	);
}

export default Index;

