/*
 *   File : editQuestion.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : edit Question Modal
 *   Integrations : 17-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { message } from 'antd';

/*import styles*/
import styles from '../../styles/components/fundClass.module.scss';
import SelectBox from '../dropdown/selectBox';

/* import assets */
import AddNew from '../../assets/images/dataFormating/add-new.svg';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { Toast } from '../note';

export default function EditQuestion(props) {

	const [question, setQuestion] = useState("");
	const [weightage, setWeightage] = useState(null);
	const [description, setDescription] = useState("");
	const [type, setType] = useState({});
	const [error, setError] = useState(null);
	let [answersArrMCQ, setAnswersArrMCQ] = useState([{ answer: "", score: "", }])
	let [answersArrYN, setanswesArrYN] = useState([{ answer: "", score: "", }])

	useEffect(() => {
		console.log(props.data)
		setQuestion(props?.data?.question)
		setDescription(props?.data?.question_description)
		setWeightage(props?.data?.weightage)
		if (props?.data?.question_type === 1) {
			setAnswersArrMCQ(props?.data?.answers);
			setType({ value: props?.data?.question_type, label: props?.data?.question_type === 1 ? "MCQ (Multiple Choice Questions" : props?.data?.question_type === 2 ? "(Y/N) Yes or No" : props?.data?.question_type === 3 ? "Fill" : "Score" })
		}
		else if (props?.data?.question_type === 2) {
			setanswesArrYN(props?.data?.answers);
			setType({ value: props?.data?.question_type, label: props?.data?.question_type === 1 ? "MCQ (Multiple Choice Questions" : props?.data?.question_type === 2 ? "(Y/N) Yes or No" : props?.data?.question_type === 3 ? "Fill" : "Score" })
		} else {
			setType({ value: props?.data?.question_type, label: props?.data?.question_type === 1 ? "MCQ (Multiple Choice Questions" : props?.data?.question_type === 2 ? "(Y/N) Yes or No" : props?.data?.question_type === 3 ? "Fill" : "Score" })
			setAnswersArrMCQ([{ answer: "", score: "", }]);
			setanswesArrYN([{ answer: "", score: "", }]);
		}
	}, [props?.data]);

	const options = [
		{ value: 1, label: "MCQ (Multiple Choice Questions)" },
		{ value: 2, label: "Y/N (Yes or No)" },
		{ value: 3, label: "Fill" },
		{ value: 4, label: 'Score' }
	]

	/*----add Fields for mcq type ------*/
	const addFieldMCQ = () => {
		setAnswersArrMCQ([...answersArrMCQ, { answer: "", score: "", }])
	}

	/*----add Fields for yn type ------*/
	const addFieldYN = () => {
		setanswesArrYN([...answersArrYN, { answer: "", score: "", }])
	}

	/*----push values of field to mcq array------*/
	const handleChange = (val, e, i) => {
		const { name } = e.target
		const list = [...answersArrMCQ]
		list[i][name] = val;
		setAnswersArrMCQ(list)
	}

	/*----push values of field to y/n array------*/
	const handleInputChange = (val, e, i) => {
		const { name } = e.target
		const list1 = [...answersArrYN]
		list1[i][name] = val
		setanswesArrYN(list1);
	}

	/*--- edit question api ----*/
	const editQuestion = () => {
		let arr = [];
		let url = devconfig.admin + '/quiz/edit-question';
		let data = JSON.stringify({
			question_id: props?.data?.question_id,
			question: question,
			question_type: type?.value,
			description: description,
			weightage: Number(weightage),
			answers: type?.value === 1 ? answersArrMCQ : type?.value === 2 ? answersArrYN : type?.value === 3 ? arr : arr
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success('Topic edited successfully')
				$('#editQuestion').trigger('click');
				props.edit(true)
			} else {
				setError(response.message)
			}
		})
		setTimeout(() => {
			setError(null)
		}, 3000)
	}

	/*----   form validation ---*/
	// const validateForm = () => {
	// 	let valid = true;
	// 	if (type.value === 1) {
	// 		for (let index = 0; index < answersArrMCQ?.length; index++) {
	// 			const element = answersArrMCQ[index];
	// 			if (isNaN(element.score) || element.answer === '') valid = false;
	// 		}
	// 		if (question && description && weightage ) {
	// 			return true;
	// 		}
	// 		else {
	// 			return false;
	// 		}
	// 	}
	// 	if (type.value === 2) {
	// 		for (let index = 0; index < answersArrYN?.length; index++) {
	// 			const element = answersArrYN[index];
	// 			if (isNaN(element.score) || element.answer === '') valid = false;
	// 		}
	// 		if (question && description && weightage ) {
	// 			return true;
	// 		}
	// 		else {
	// 			return false;
	// 		}
	// 	}
	// 	if (type.value === 3 || type.value === 4) {
	// 		if (question && description) {
	// 			return true;
	// 		}
	// 		else {
	// 			return false;
	// 		}
	// 	}

	// 	return false;
	// }

	return (
		<>
			<div className={`${styles.e_add_fund} modal fade`} id="editQuestion" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h6> Edit question</h6>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="container px-3">
							<div className="row">
								<div className="col-lg-10 mb-3">
									<label>Question</label>
									<Input placeholder="What if" value={question} valueChange={(value) => setQuestion(value)} type="text" />
								</div>
								<div className='col-lg-2 mb-3 ps-0 position-relative'>
									<label>Weightage</label>
									<Input suffix="%" className={styles.e_input_box} placeholder="0" value={parseInt(weightage)} valueChange={(value) => setWeightage(parseInt(value))} type="number" />
								</div>
							</div>
							<div className="col-lg-12">
								<label>Description</label>
								<Input placeholder="Enter description" value={description} valueChange={(value) => setDescription(value)} type="text" />
							</div>
							<div className="row pt-2">
								<div className="col-lg-12 mb-3">
									<label>Type</label>
									{/* {
										props?.data?.question_type ? */}
									<SelectBox placeholder="Fill" value={type} isSearchable={false} isDisabled={true} options={options} selectchange={(value) => setType(value)} type="text" />
									{/* :
											null
									} */}

								</div>
							</div>
							{
								type?.value === 1 ?
									<div className="row ms-1 ">
										<p className="ps-0 mt-4 mb-1">Choices</p>

										{
											answersArrMCQ?.map((item, key) => (
												<>
													<h6 className='ps-0 mb-2 mt-1'>Option {key + 1}</h6>
													<div className="row px-0">
														<div className="col-lg-10 mb-3">
															<label>Answer</label>
															<Input placeholder="Answer" name="answer" value={item.answer} valueChange={(value, e) => handleChange(value, e, key)} type="text" />
														</div>
														<div className='col-lg-2 mb-3 ps-0 pe-0 position-relative'>
															<label>Score</label>
															<Input placeholder="0" name="score" value={parseFloat(item.score)} valueChange={(value, e) => handleChange(parseFloat(value), e, key)} type="number" />
														</div>
													</div>
												</>
											))
										}
										<div className={`${styles.e_add_new_quiz_sec} col-lg-12 ps-1 mt-1`} onClick={() => addFieldMCQ()}>
											<h6><span className="pe-2" ><img src={AddNew} alt="add" /></span>Add New</h6>
										</div>
									</div>
									:
									type?.value === 2 ?
										<>
											<div className="row ms-1">
												<p className="ps-0 mt-4 mb-1">Choices</p>
												{
													answersArrYN?.map((item, key) => (
														<>
															<h6 className='ps-0 mb-2 mt-1'>Option {key + 1}</h6>
															<div className="row px-0">
																<div className="col-lg-10 mb-3">
																	<label>Answer</label>
																	<Input placeholder="Answer" name="answer" value={item.answer} valueChange={(value, e) => handleInputChange(value, e, key)} type="text" />
																</div>
																<div className='col-lg-2 mb-3 ps-0 pe-0 position-relative'>
																	<label>Score</label>
																	<Input placeholder="0" name="score" value={parseFloat(item.score)} valueChange={(value, e) => handleInputChange(parseFloat(value), e, key)} type="number" />
																</div>
															</div>
														</>
													))
												}
												<div className={`${styles.e_add_new_quiz_sec} col-lg-12 ps-1 mt-1`} onClick={() => addFieldYN()}>
													<h6><span className="pe-2" ><img src={AddNew} alt="" /></span>Add New</h6>
												</div>
											</div>
										</>
										:
										type?.value === 3 ?
											null
											:
											null
							}
							<div className="row pt-4 mt-2 pb-2">
								<div className="col-lg-12">
									<div className="d-flex justify-content-end">
										<SecondaryBtn name="Cancel" className={`${styles.e_cancel} e-cancel`} cancel="modal" />
										{
											question && weightage !== null && weightage <= 100 && description ?
												<PrimaryBtn name="Save question" className="e-modal-submit" handleClick={() => editQuestion()} />
												:
												<PrimaryBtn name="Save question" className={`${styles.e_disabled}`} />

										}

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				{
					error !== null ?
						<Toast data={error} format={1} type="fail" />
						:
						null
				}
			</div>
		</>
	);
}
