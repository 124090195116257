/*
 *   File : orderDetail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order detail Modal
 *   Integrations : 24-05-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';

/*import componets*/
import { Badge } from '../../components/badge/index';
import EmptyNote from '../note/empty';

/*import styles*/
import styles from '../../styles/components/transactionDetail.module.scss';
import { Loader } from '../loader';


/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';



export default function OrderDetail(props) {
    const [orderData, setOrderData] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            orderDetail()
        }
    }, [props.data.id])

    const orderDetail = () => {
        setLoader(true)
        if (props.id) {
            var url = devconfig.admin + "/user/order/detail";
        } else {
            var url = devconfig.admin + "/order/detail";
        }

        if (props.id) {
            var data = JSON.stringify({
                user_id: Number(props.id),
                order_id: props.data.id
            })
        } else {
            var data = JSON.stringify({
                order_id: props.data.id
            })
        }

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data);
                setLoader(false);
            } else {
                setOrderData([]);
                setLoader(false)
            }
        })
    }
    return (
        <>

            <div className={`${styles.e_transaction_detail} modal fade`} id="orderDetail" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className={`${styles.e_modal_content} modal-content`}>
                        <div className="modal-header px-4 pt-4">
                            <h6 className={styles.e_modal_title}>Order detail</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {
                            loader ?
                                <Loader />
                                :

                                orderData.length > 0 ?
                                    <div className='e-order-detail '>
                                        <div className={`${styles.e_transaction_content}`}>
                                            <div className="row p-4">
                                                <div className="col-lg-9 col-9 d-flex">
                                                    <span className={`${styles.e_icon}`}>{props?.data?.name ? props?.data?.name?.charAt(0) : props.fName?.charAt(0)}</span>
                                                    <span className={`${styles.e_span} ms-3`}>
                                                        <h6>{props.data.name ? props.data.name : props.fName + props.lName}</h6>
                                                        <p className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.transaction_date}</p>
                                                    </span>
                                                </div>
                                                <div className="col-lg-3 text-end">
                                                    <p className='mb-0 pb-1 pt-0 pe-2'>{props.data.type}</p>
                                                    {
                                                        props.data.status !== null ?
                                                            <Badge type={props.data.status} text={props?.data?.status?.toLowerCase()} className={`${styles.e_badge}`} />
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container-fluid'>
                                            <div className='row mt-4 pe-3 ps-1'>
                                                <div className={`col-lg-3 ${styles.e_details} mb-2`}>
                                                    <h6>Transaction ID:</h6>
                                                </div>
                                                <div className={`col-lg-2 ${styles.e_details} mb-2`}>
                                                    <h6>Amount & units</h6>
                                                </div>
                                                <div className={`col-lg-5 ${styles.e_details} mb-2 `}>
                                                    <h6>Invested in</h6>
                                                </div>
                                                <div className={`col-lg-2 ${styles.e_details} mb-2 `}>
                                                    <h6>Status</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            orderData.map((item,key) => {
                                                return (
                                                    <>
                                                        <div className={`container-fluid  `}>
                                                            <div className={key % 2 !== 0 ? `row pt-3 mt-1 me-1 pe-2 ps-1` : `e-order-row row pt-3 mt-1 me-1 pe-2 ps-1`}>
                                                                <div className={`col-lg-3 ${styles.e_details} mb-2`}>
                                                                    <p className={`${styles.e_transaction_id}`}>{item.transaction_id} </p>
                                                                </div>
                                                                <div className={`col-lg-2 ${styles.e_details} mb-2`}>
                                                                    <p className={`${styles.e_transaction_id} mb-0 pb-0`}>{item.amount} </p>
                                                                    <p className={`${styles.e_transaction_id} mt-0 pt-0`}>{item.units.toFixed(2)} </p>
                                                                </div>
                                                                <div className={`col-lg-5 ${styles.e_details} mb-2`}>
                                                                    <p className={`${styles.e_transaction_id}`}>{item.mutual_fund} </p>
                                                                </div>
                                                                <div className={`col-lg-2 ${styles.e_details} mb-2`}>
                                                                    <Badge type={item.status} text={item.status?.toLowerCase()} className={`${styles.e_badge}`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <EmptyNote note="No data found" />

                        }
                    </div>
                </div>
            </div>

        </>
    );
}
