/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Risk and Funds container;
 *   Integrations :19-01-2022
 *   Version : v1.0
 *   Created : 14-12-2021
*/

/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { Accordion } from '../../components/dataFormating/index';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';
import { Loader } from '../../components/loader';
import { FundClass } from '../../components/modal';

/*import assets*/
import AddNew from '../../assets/images/dataFormating/add-new.svg';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import { Toast } from '../../components/note';
import { Modal } from 'bootstrap';
import { message } from 'antd';


const Index = (props) => {
	const [riskData, setRiskData] = useState([]);
	const [error, setError] = useState(null);
	const [riskId, setRiskId] = useState(null);
	const [fundData, setFundData] = useState([]);
	const [fundLoader, setFundLoader] = useState(true);
	const [riskLoader, setRiskLoader] = useState(true);
	const [loader,setLoader] = useState(false);
	//eslint-disable-next-line
	const [updated, setUpdated] = useState("");
	const [ updating, setUpdating] = useState(false);

	useEffect(() => {
		window.scrollTo({ 
			top: 0, 
			behavior: 'smooth' 
		});

		getRisk();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	useEffect(() => {
		if (riskId !== null) {
			getFunds();
		}
		//eslint-disable-next-line
	}, [riskId]);

	/*---- get risk scores api ----*/

	const getRisk = () => {
		setRiskLoader(true);

		let url = devconfig.admin + '/risks/get-risks';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setRiskLoader(false);
				setRiskData(response.data.risks);
				setRiskId(response?.data.risks[0].risk_id);
			}
			else {
				setRiskLoader(false);
				setError(response?.message);
			}
		});
	}

	/* ---- get mutual funds ---*/
	const getFunds = () => {
		setFundLoader(true);

		let url = devconfig.admin + '/risks/get-risk-fund'

		let data = JSON.stringify({
			risk_score_id: riskId
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.data !== undefined) {
					setFundData(response.data);
				} else {
					setFundData([]);
				}

				setFundLoader(false);
			} else {
				setFundData([]);
				setError(response.message);
				setFundLoader(false);
			}
		})
	}

	/*---- save portfolio api ....... ---*/
	const savePortfolio = (e) => {
		setLoader(true);

		let url = devconfig.admin + '/funds/publish-portfolio';

		let data = JSON.stringify({
			risk_score_id: riskId
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					message.success("Portfolio saved");
					setLoader(false);
				} else {
					setError(response.message);
					setLoader(false);
				}
			} else {
				setError(response.message);
				setLoader(false);
			}
		})
	}

	/* ------ open add fund Modal -----*/
	const addFund = () => {
		var myModal = new Modal(document.getElementById("addFundClass"), {});
		myModal.show();
	}
	
	const publishRebalance = () =>{
		setUpdating(true)
		let url = devconfig.admin+'/risks/update-rebalance';
		let data = JSON.stringify({
			'risk_score_id' : riskId
		})
		APIPost(url, data).then((response)=>{
			if(response.status_code === 200){
				setUpdating(false);
				message.success('Initiated successfully');
			}
			else{
				setError(response.message);
				setUpdating(false);
			}
		})
		setTimeout(()=>{ setError(null)}, 4000)
	}

	return (
		<>
			{
				riskLoader ?
					<Loader />
					:
					<div className="row pb-5">
						<div className="col-lg-12">
							<div className="card e-dashboard-card e-faq-card border-0 p-4 position-relative">
								<div className="row">
									<div className="col-lg-12 ">
										<h6 className="e-card-title">Risk scores</h6>
									</div>

									{/*----------- Risk score 0.5 to 10 ------------------*/}
									<div className="col-lg-12 d-flex mt-3">
										{
											riskData?.map((item) => (
												<div onClick={() => setRiskId(item.risk_id)} className={item.risk_id === riskId ? "e-btn-active-wrapper" : "e-btn-wrapper"}>
													<span className="e-btn-active">{item.name}</span>
												</div>
											))
										}
									</div>
								</div>
								<div className="col-lg-12 d-flex pt-3 justify-content-between">
									<h6 className="e-card-title pt-4 mt-2">Funds and distributions</h6>
									<div className='card e-dashboard-card-alloc p-3'>
										<h6 className="e-card-title">Allocation:</h6>
										<span>
											{
												fundData.length > 0 ?
													fundData?.reduce((a, b) => a = a + b.allocation, 0) : 0
											}/100
										</span>
									</div>
								</div>

								{/*----------- list of funds added ------------------*/}
								{
									fundLoader ?
										<Loader />
										:
										<>
											<div className="col-lg-12 pt-3">
												<Accordion
													data={fundData}
													riskId={riskId}
													updatedFund={(data) => { getFunds(); setUpdated(data); }}
												/>
											</div>
											<div className="row mx-0 mb-5 pb-5">
												<div className="e-add-sec col-lg-12 mb-5" onClick={() => addFund()}>
													<h6><span className="pe-3"><img src={AddNew} alt="add" /></span>Add New</h6>
												</div>
											</div>
										</>
								}
								<div className="row pt-4 mt-2 pb-2">
									<div className="col-lg-12">
										<div className="d-flex justify-content-end">
											<SecondaryBtn name={ updating ? "Initiating" : "Initiate Rebalance" }className={ updating ? 'e-btn-loader' : null}  handleClick={()=> publishRebalance()}/>
											{
												Number(fundData?.reduce((a, b) => a = a + b.allocation, 0)) >= Number(100) && riskId ?
													<PrimaryBtn
														name="Save portfolio"
														className={loader === true ? "ms-3 e-btn-loader" : "ms-3"}
														handleClick={() => savePortfolio()}
													/>
													:
													<PrimaryBtn
														name="Save portfolio"
														className="e-disabled ms-3"
													/>
											}

										</div>
									</div>
								</div>
							</div>
						</div>

						{/*----------- error message toast------------------*/}
						{
							error !== null ?
								<Toast
									data={error}
									type="fail"
									format={1}
								/>
								:
								null
						}
					</div>
			}

			{/*----------- Modal to add fund ------------------*/}
			<FundClass
				risk_id={riskId}
				addedFund={(data) => { getFunds(); setUpdated(data) }}
			/>

		</>
	)
}

export default Index;