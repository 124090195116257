/*
 *   File : detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 27-04-2022
 *   Version : v1.0
 *   Created : 10-12-2021
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

/*import components*/
import { Tab, } from '../../components/dataFormating/index';
import { UserDetail } from '../../components/card/index';
import { TransactionDetail } from '../../components/modal/index';
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';

/*import container*/
import Profile from './profile.js';
import Holdings from './holdings.js';
import Risk from './risk.js'
import Transactions from '../transactions/index'


/*import assets*/

/*import services*/
import GetVars from '../../services/getUrl';
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';
import Orders from './orders';

const UserDetails = (props) => {
	const [tabIndex, setTabindex] = useState(1);
	const [id, setId] = useState(null);
	const [detail, setDetail] = useState({});
	const [loader, setLoader] = useState(true);
	const [risk, setRisk] = useState([]);
	const [requestData, setRequestData] = useState({});
	const [error, setError] = useState(null);
	const [type, setType] = useState(null);

	useEffect(() => {
		GetVars().then((query) => {
			if (query && query.tab) {
				setTabindex(Number(query.tab));
				console.log('holding')
			}
			if (query && query.id) {
				setId(query.id);
				getDetail(query.id);
				getRiskData(query.id);
				getScoreRequest(query.id)
			}
			if(query && query.type){
				setType(query.type);
			}
		});

	}, []);

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null)
			}, 3000)
		}
	}, [error])

	/* ----- get user's deatil api -----*/

	const getDetail = (temp) => {
		setLoader(true);
		let url = devconfig.admin + '/user/detail';
		let data = JSON.stringify({
			"user_id": parseInt(temp)
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setDetail(response.data)
				setLoader(false)
			}
			else {
				setLoader(false)
			}
		})
	}

	/* ----- fetching risk data -----*/

	const getRiskData = (temp) => {
		let url = devconfig.admin + '/user/get-riskassessment';
		let data = JSON.stringify({
			"user_id": parseInt(temp)
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setRisk(response.data);
			}
		})
	}

	/* ----- fetching edit score request -----*/

	const getScoreRequest = (temp) => {
		let url = devconfig.admin + '/user/edit-score-request';
		let data = JSON.stringify({
			"user_id": parseInt(temp)
		})

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setRequestData(response.data);
			} else {
				setRequestData({});
			}
		});
	}

	return (
		<>
			{
				loader ?
					<div className='col-lg-12 '>
						<Loader />
					</div>

					:
					<>
						<div className="row">
							<div className="col-lg-12">
								{
									type == 2 ?
										<span className="e-breadcrumb-link"><Link to="/analytics ">Analytics</Link>/{detail?.personal_data?.first_name}</span>
									:
										<span className="e-breadcrumb-link"><Link to="/users ">Users</Link>/{detail?.personal_data?.first_name}</span>
								}
								
							</div>
						</div>
						<div className="row">

							{/*-----------card displaying basic details------------------*/}
							<div className="col-lg-12">
								<UserDetail
									data={detail}
									userId={id}
								/>
							</div>

							{/*----------- tabs ------------------*/}
							<div className="col-lg-12 mt-4 mb-3">
								<Tab
									data={['Profile & KYC', 'Orders','Transactions',  'Holdings', 'Risk assessment']}
									setTab={(temp) => setTabindex(temp)}
									currentIndex={tabIndex}
								/>
							</div>
							{
								tabIndex === 1 ?
									<Profile
										data={detail}
										userId={id}
										editUserDetails={() => getDetail()}
									/>
									: tabIndex === 2 ?
										<Orders
											id={id}
											fName={detail.personal_data.first_name}
											lName={detail.personal_data.last_name}
										/>

										: tabIndex === 3 ?
											<Transactions
												placeholder="search with id"
												tabIndex={tabIndex}
												id={id}
											/>
											:
											tabIndex === 4 ?
												<Holdings
													id={id}
												/>
												:
												<Risk
													data={risk}
													requestData={requestData}
													updated={() => { getScoreRequest(id) }}
												/>
							}

						</div>
						<TransactionDetail />

						{/* Toast error */}
						{
							error !== null ?
								<Toast data={error}
									type="fail"
									format={1} />
								:
								null
						}
					</>
			}
		</>
	);
}

export default UserDetails;
