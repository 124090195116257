/*
 *   File : innerHeader.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Inner Header
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/*import components*/
import { ChangePassword } from '../modal/index';
import { NotificationMenu } from '../navigation/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/innerHeader.module.scss';

/*import assets*/
import Notification from '../../assets/images/navigation/notification.png';
import Menu from '../../assets/images/navigation/menu.png';
import More from '../../assets/images/navigation/more.png';
import Password from '../../assets/images/navigation/password.png';
import Logout from '../../assets/images/navigation/logout.png';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

export default function InnerHeader(props) {

  const [notificationDown, setNotificationDown] = useState(true);
  const [logoutdDown, setLogoutdDown] = useState(false);
  const [header, setHeader] = useState();
  const wrapperRef = useRef(null);
  const wrapRef = useRef(null);
  const current = useLocation();
  const [notificationData, setNotificationData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getNotification()
  }, [])

  useEffect(() => {
    getCurrentLocation();
    //eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    document.addEventListener('mouseup', closeMenu);
    return () => {
      document.removeEventListener('mouseup', closeMenu);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', closeNotification);
    return () => {
      document.removeEventListener('mouseup', closeNotification);
    };
  }, []);

  const getCurrentLocation = () => {
    var temp = '';
    //eslint-disable-next-line
    devconfig.sidebar.map((item, key) => {
      if (item.link === current.pathname) {
        return (
          temp = item.header
        )
      }
    })
    setHeader(temp);
  }

  const getNotification = () => {
    let url = devconfig.admin + '/get-received-notification'
    let data = JSON.stringify({
      page_num: 1
    });
    APIPost(url, data).then((response) => {
      if (response.status_code === 200) {
        setNotificationData(response.data);
      }
      else {
        setError(response.message);
      }
    });
  }

  const closeMenu = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setLogoutdDown(false)
    }
  }

  const closeNotification = (event) => {
    if (wrapRef.current && !wrapRef.current.contains(event.target)) {
      setNotificationDown(true);
    }
  }

  return (
    <>
      <div className={`${styles.e_inner_header_wrap} `} id="header" >
        <div className="px-4">
          <div className="row pt-4 ">
            <div className="col-lg-6 gx-0">
              <img src={Menu} className={`${styles.e_menu}`} alt="Cashyu Menu" onClick={() => props.collapse()} />
            </div>
            <div className="col-lg-6 text-end">
              <img src={Notification} alt="Notification" className={`${styles.e_notification}`} onClick={() => setNotificationDown(!notificationDown)} />
              <span ref={wrapperRef} className={`${styles.e_drop_down_wrap} pe-2 ms-4`} onClick={() => setLogoutdDown(true)}>
                <img src={More} className={`${styles.e_header_more} `} alt="" />
              </span>
              {
                logoutdDown ?
                  <div>
                    <div className={`${styles.e_dropdown}`} ref={wrapperRef}>
                      <span data-bs-toggle="modal" data-bs-target="#changePassword"><img src={Password} alt="Change Password" className="me-2" />Change password</span>
                      <span className={`${styles.e_logout}`} onClick={() => { localStorage.clear(); window.location.href = "/" }}><img src={Logout} alt="Logout" className="me-2" />Logout</span>
                    </div>
                  </div>
                  :
                  null
              }
              {
                !notificationDown ?
                  <div ref={wrapRef}>
                    <NotificationMenu
                      close={() => { setNotificationDown(true); }}
                      update={() => getNotification()}
                      notificationData={notificationData}
                    />
                  </div>
                  :
                  null
              }
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12">
              <h3 className="ps-4 ms-2">{header}</h3>
            </div>
          </div>
        </div>
      </div>
      <ChangePassword />
      {
        error !== null ?
          <Toast data={error} type="fail" format={1} />
          :
          null
      }
    </>
  )
}

