/*
 *   File : funds.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Funds Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 13-12-2021
*/

/*import packages*/
import React from 'react';

/*import styles*/
import styles from '../../styles/components/aggressiveFund.module.scss';

/*import assets*/
import Add from '../../assets/images/card/add.svg';
import Remove from '../../assets/images/card/remove.svg';

const AggressiveFund = (props) => {

	/*--- send id to add fund modal and add fund class modal---*/
	// const sendData = () => {
	// 	props.sendVal(props.data.scheme_code)
	// }

	console.log(props.selected)

	return (
		<>
			<div className={`${styles.e_fund_card} card border-0`}>
				<div className="row">
					<div className="col-lg-12 mt-3">
						<div className={`${styles.e_inner_card} p-3`}>
							<div className="row ps-2">
								{/* <div className="col-lg-2 ps-0">
									<img src={props.data.mf_image} className={`${styles.e_logo} rounded-circle`} alt="" />
								</div> */}
								<div className="col-lg-12 ps-0">
									<h6 className={styles.e_inner_card_title}>{props.data.scheme_name}</h6>
								</div>
							</div>
							<div className="row pt-1 ps-2">
								<div className="col-lg-12 ps-0">
									<label>Returns</label>
								</div>
							</div>
							<div className="row ps-0 ps-2">
								{
									props.data.returns.slice(0, 3).map((item, key) => (
										<div className="col-lg-3 px-0" key={key} >
											<p className={styles.e_returns}>
												<span>{Object.keys(item)}:</span>{Object.values(item)}%
											</p>
										</div>
									))
								}
								<div className="col-lg-3 px-0 pt-0">
									<>
										<div onClick={() => { props.selectedFundData(props.data.scheme_code) }} >
											{
												props?.data?.scheme_code !== props.selectedFund ?
													<div
														className={styles.e_add}
														onClick={() => props.selectedFundData(null)}
													>
														<img src={Add} alt="" />
													</div>

													:

													null
											}
										</div>
										<div onClick={() => { props.selectedFundData(null) }} >
											{
												props?.data?.scheme_code === props.selectedFund ?
													<div
														className={styles.e_remove}
														onClick={() => {
															props.selectedFundData(props.data.scheme_code)
														}}>
														<img src={Remove} alt="" />
													</div>
													:

													null
											}
										</div>
									</>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AggressiveFund;
