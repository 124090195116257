/*
 *   File : summary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Summary Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 13-12-2021
 */

/*import packages*/
import React from 'react';
import { Modal } from 'bootstrap';

/*Style Import*/
import styles from '../../styles/components/summary.module.scss';
import { EditRevenue } from '../modal';

const Summary = (props) => {

	/* func to open modal edit revenue */
	const openModal = () => {
		var myModal = new Modal(document.getElementById("editRevenue"), {});
		myModal.show();
	}

	return (
		<>
			<div className={`${props?.revenue?.className ? styles.e_blue_box : styles.e_summary_card} card`}>
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12">
						<h5>
							{
								props.revenue ?
									props.revenue?.title
									:
									props.data?.title
							}
						</h5>
					</div>
					<div className="col-lg-9 col-md-10 col-10">
						<span className='e-dashboard-card-text'>
							<span className={`${styles.e_text}`}>
								{
									props.revenue
										?
										props.revenue.currency
										:
										props.data.currency}
							</span>
							{
								props.revenue ?
									props.revenue.count
									:
									props.data.count
							}
							<span className={styles.e_text}>
								{
									props.revenue ?
										props.revenue.text
										:
										props.data.text
								}
							</span>
						</span>
					</div>
					<div className="col-lg-3 col-md-2 col-2 text-end">
						<div className={styles.e_img_bg}>
							<img
								src={
									props.revenue ?
										props.revenue?.icon
										:
										props.data?.icon
								}
								className={styles.e_summary_card_icon}
								onClick={() => 
									props.revenue?.icon ?
										openModal()
										:
										null
								} alt="" />
						</div>
					</div>
				</div>
			</div>
			<EditRevenue />
		</>

	)
}

export default Summary;