/*import packages*/
import React, { useState, useEffect, useRef } from 'react';

const Editor = (props) => {
    const editorRef = useRef()
    const [editorLoaded, setEditorLoaded] = useState(false)
    const { CKEditor, ClassicEditor } = editorRef.current || {}
    const [data, setData] = useState("");

    useEffect(() => {
        editorRef.current = {
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, //Added .CKEditor
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
        }
        
        setEditorLoaded(true);
    }, []);

    useEffect(()=> {
        console.log('prosp.value', props.value)
        if ( props.value !== null ) {
            setData(props.value);
        }
        else{
            setData("")
        }
    },[props.value])

    return (
        <>
            {
                editorLoaded ?
                    <CKEditor
                        activeClass="p10"
                        editor={ClassicEditor}
                        config={{
                            removePlugins: ["EasyImage","ImageUpload","MediaEmbed", "Table"]
                        }}
                        onChange={(evt, editor) => {
                            var tempData = "";
                            tempData = editor.getData();
                            props.editorChange(tempData);
                        }}
                        data={data} 
                    />
                    :
                    null
            }
        </>
    )
}

export default Editor