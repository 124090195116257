/*
 *   File : changePassword.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useState } from 'react';

/*import assets*/

/*import components*/
import { OuterHeader, Footer } from '../../components/navigation/index';
import { Input } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';

/*import container*/

const ChangePassword = (props) => {
	const [email, setEmail] = useState("");
	const [otp, setOtp] = useState(true);
	const [verifyOtp, setVerifyOtp] = useState(null);

	return (
		<>
			<div className="container-fluid e-auth-wrapper">
				<OuterHeader
					type={2}
				/>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 mt-2  pt-5 offset-lg-4">
							<div className="e-form-wrap">
								{
									otp ?
										<>
											<h4>Enter email address</h4>
											<div className="pt-3">
												<label>Email</label>
												<Input
													placeholder="Email"
													value={email}
													valueChange={(value) => { setEmail(value) }}
													type="email"
												/>
											</div>
											<div className="row mt-4 pt-2 mb-4">
												<div className="col-lg-12">
													{
														email ?
															<PrimaryBtn
																name="Send OTP"
																handleClick={() => { setOtp(false) }}
															/>
															:
															<PrimaryBtn
																name="Send OTP"
																handleClick={() => { setOtp(false) }}
																className="e-disabled"
															/>
													}
												</div>
											</div>
										</>
										:
										<>
											<h4>OTP Verification</h4>
											<p className="py-3">Please enter the One Time Password send to the email:<span className="ms-2">abc@gmail.com</span> </p>
											<div className="">
												<label>Enter OTP</label>
												<Input
													placeholder="OTP"
													value={verifyOtp}
													valueChange={(value) => { setVerifyOtp(value) }}
													type="number"
												/>
											</div>
											<div className="row mt-4 pt-2 mb-4">
												<div className="col-lg-12">
													{
														verifyOtp !== null ?
															<PrimaryBtn
																name="Verify OTP"
															/>
															:
															<PrimaryBtn
																name="Verify OTP"
																className="e-disabled"
															/>
													}
												</div>
											</div>
										</>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="e-auth-footer">
					<Footer />
				</div>
			</div>
		</>
	);
}

export default ChangePassword;

