/*
 *   File : transactions.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Riskcontainer;
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-12-2021
*/
/*import packages*/
import React, { useState } from 'react';
import { Modal } from 'bootstrap';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta';
import { Approve } from '../../components/modal';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

const Risk = (props) => {
	const [apiLoader, setApiLoader] = useState(false);
	const [requestType, setRequestType] = useState(null);
	const [messaage, setMessage] = useState("");

	const manageScore = (type, id) => {
		setApiLoader(true);
		setRequestType(type);
		modalOpen();
		let url = devconfig.admin + '/request/manage';
		let data = JSON.stringify({
			type: parseInt(type),
			request_id: id
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setTimeout(() => {
					setApiLoader(false);
					props.updated()
				}, 1000);
			} else {
				setMessage(response.message);
				setTimeout(() => {
					setApiLoader(false)
				}, 2000);
			}
		})
	}


	/*---  modal ---*/
	const modalOpen = (row) => {
		var myModal = new Modal(document.getElementById("approve"), {});
		myModal.show();
	}
	return (
		<>
			<div className="row">
				<div className="col-md-12">
					<div className="card e-dashboard-card border-0 p-4 e-holding-table">
						<div className="row p-4 e-holding-table-heading">
							<div className="col-md-6 text-start ps-4">
								<span className="e-current-title">Question</span>
							</div>
							<div className="col-md-6 text-start">
								<span className="e-current-title">Answer</span>
							</div>
						</div>
						{
							props.data && props.data.map((item, key) => {
								return (
									<div key={key} className="px-4">
										<h4 className="pt-4 ps-3">{item.topic}</h4>
										<div className="row mt-3 p-4 pb-0 e-holding-table-data">
											{
												item.assessment && item.assessment.map((quiz, key) => {
													return (
														<>
															<div className="col-md-6 text-start mb-4">
																<span className="e-table-data">{quiz.question}</span>
															</div>
															<div className="col-md-6 text-start mb-4">
																<span className="e-table-data">{quiz.answer}</span>
															</div>
														</>
													)
												})
											}
										</div>
									</div>
								)
							})
						}

						<div className="card  border-0 p-4 e-user-request">
							<h4>User requests</h4>
							<div className="row mt-3 p-4 e-user-request-sec">
								{
									Object.keys(props.requestData).length > 0 ?
										<>
											<div className="col-md-12 text-start">
												<p>I need to change risk score</p>
												<p className='mt-4 d-flex'>
													<span className='e-bold'>Reason: </span>{props.requestData.message}
												</p>
											</div>
											<div className='col-md-12 text-start'>
												<SecondaryBtn name="Deny" className="e-deny" handleClick={() => { manageScore(2, props.requestData.id) }} />
												<PrimaryBtn name="Accept" className="e-accept ms-2" handleClick={() => { manageScore(1, props.requestData.id) }} />
											</div>
										</>

										:
										"no change score requests yet"
								}
							</div>
						</div>

					</div>
				</div>
			</div>
			{/*--------- Approve modal component ----------------*/}
			<div>
				<Approve
					loader={apiLoader}
					type={requestType}
					message={messaage}
					reload={() => { }}
				/>
			</div>
			
		</>
	);
}

export default Risk;

