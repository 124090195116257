/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Risk and Funds container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 16-12-2021
*/

/*import packages*/
import { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';

/*import components*/
import { Tab } from '../../components/dataFormating/index';
import { Toast } from '../../components/note';
import { Loader } from '../../components/loader';
import AccordionQuiz from '../../components/dataFormating/accordionquiz';
import { AddQuestion, AddTopic } from '../../components/modal';

/*import services */
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';

/*import assets*/
import AddNew from '../../assets/images/dataFormating/add-new.svg';


const Index = (props) => {
	const [tabIndex, setTabindex] = useState(1);
	const [error, setError] = useState(null);
	const [details, setTopicDetails] = useState({});
	const [getTopics, setTopicData] = useState([]);
	const [allQuestion, setQuestionData] = useState([]);

	useEffect(() => {
		setTopicDetails(getTopics[0])
	}, [getTopics]);

	useEffect(() => {
		if (getTopics.filter((data) => data.topic_id === tabIndex)) {
			getQuestions();
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	});

	/*------ add topic modal----*/
	const openModal = () => {
		var myModal = new Modal(document.getElementById("addTopic"), {});
		myModal.show();
	}

	/* ----- add Question Modal ---*/
	const addQuestion = () => {
		var myModal = new Modal(document.getElementById("addFundClass"), {});
		myModal.show();
	}

	/*----- api for listing questions for each topic in tab----*/
	const getQuestions = () => {
		let url = devconfig.admin + '/quiz/get-questions';
		APIGet(url).then((response) => {
			if (response?.status_code === 200) {
				if (response.status === true) {
					setQuestionData(response.data)
				}
			}
			else {
				setError(response?.message)
			}
		})
	}

	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<div className="card e-dashboard-card e-faq-card e-quiz-card border-0 p-4">
						<div className="row">
							<div className="col-lg-10 mt-4 mb-3 pe-0 e-quiz-scroll">

								{/*----------- Topic tabs ------------------*/}
								<div className="e-tab-container">
									<Tab
										data={getTopics}
										setTab={(temp, data) => {
											setTabindex(temp);
											setTopicDetails(data);
										}}
										className="e-quiz-tab"
										toggle="tool-tip"
										title={(item) => item.topic}
										tooltip={true}
										currentIndex={tabIndex}
									/>
								</div>
							</div>
							<div className="col-lg-2 mt-4 pt-2 mb-3 pe-0 ps-2">
								<span className="e-add-quiz" onClick={() => openModal()}>Add/Edit</span>
							</div>
						</div>

						{/*----------- Questions list ------------------*/}
						{
							allQuestion && allQuestion.length > 0 ?
								<>
									<div className="col-lg-12 d-flex pt-3 justify-content-between">
										<h6 className="e-card-title pt-4 mt-2">Questions in {details?.topic}</h6>
										<div className='card e-dashboard-card-alloc p-3'>
											<h6 className="e-card-title">Weightage:</h6>
											{
												allQuestion.map((data, key) => (
													data?.topic_id === details?.topic_id ?
														<span>
															{
																data.questions.length > 0 ?
																	data.questions?.reduce((a, b) => a = a + b.weightage, 0) :
																	0
															}/100
														</span>
														:
														null
												))
											}

										</div>
									</div>
									{
										allQuestion?.map((data, key) => (
											data?.topic_id === details?.topic_id ?
												<>
													<div className="col-lg-12 pt-3">
														<AccordionQuiz
															data={data.questions}
															topicDetails={data}
															details={details} edit={() => getQuestions()}
														/>
													</div>
												</>
												:
												null
										))
									}
								</>
								:
								<Loader />
						}

						{/*----------- Add Question on click ------------------*/}
						<div className="row mx-0 e-add-sec">
							<div className="col-lg-10 ps-0" >
								<h6>
									<span onClick={() => addQuestion()}>
										<span className="pe-2" >
											<img src={AddNew} alt="add" />
										</span>
										<span>Add New</span>
									</span>
								</h6>
							</div>
							<div className="col-lg-2 position-relative" >
								<span className="e-quiz-icon" onClick={() => addQuestion()}></span>
							</div>
						</div>
					</div>

					{/*----------- Modal to add topic ------------------*/}
					<AddTopic
						topics={(data) => setTopicData(data)}
					/>

					{/*----------- Modal to add questions ------------------*/}
					<AddQuestion
						details={details}
						addedQuestion={() => getQuestions()}
					/>
				</div>

				{/*----------- error message toast ------------------*/}
				{
					error !== null ?
						<Toast
							data={error}
							format={1}
							type="fail"
						/>
						:
						null
				}
			</div>
		</>
	);
}

export default Index;