/*import package*/
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/*import containers*/
// import Contents from './containers/contents.js';
import Login from './containers/auth/login.js';
import Dashboard from './containers/dashboard/index';
import Users from './containers/users/index';
import UserDetails from './containers/users/detail.js';
import ChangePassword from './containers/auth/changePassword.js';
import CreatePassword from './containers/auth/createPassword.js';
import Notification from './containers/notification/index';
import RiskFunds from './containers/Risk and Funds';
import Transactions from './containers/transactions/index';
import Analytics from './containers/analytics/index';
import AnalyticsDetails from './containers/analytics/useranalytics.js';
import Quiz from './containers/quiz/index';
import Revenue from './containers/revenue/index';
import AllNotification from './containers/allnotification/index';
import Report from './containers/report/index'
import Request from './containers/scorerequest/index'
import Manage from './containers/globalclass/index'
import Orders from './containers/orders/index'

/*import component*/
import { Footer, InnerHeader, Sidebar } from './components/navigation/index';


export default function App(props) {
  const [collapseMenu, setCollapseMenu] = useState(false);
  return (
    <>
      {
        localStorage.getItem("access_token") ?
          <Router>
            <div className="container-fluid d-flex flex-column flex-lg-row p-0">
              <div className={collapseMenu ? "e-sidebar-collapsed sticky-top p-0 " : "e-sidebar-wrap sticky-top p-0"}>
                <Sidebar is_menu_collapse={collapseMenu} />
              </div>
              <div className="e-content-wrap position-relative p-0">
                <InnerHeader collapse={() => { setCollapseMenu(!collapseMenu) }} />
                <div className="p-5 pt-4 ms-2 e-content-body">
                  <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    {/* <Route path="/home" element={<Contents />} /> */}
                    <Route path="/users" element={<Users />} />
                    <Route path="/user-detail" element={<UserDetails />} />
                    <Route path="/analytics-detail" element={<AnalyticsDetails />} />
                    <Route path="/notification" element={<Notification />} />
                    <Route path="/risk-funds" element={<RiskFunds />} />
                    <Route path="/analytics" element={<Analytics />} />
                    <Route path="/transactions" element={<Transactions />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/quiz" element={<Quiz />} />
                    <Route path="/revenue" element={<Revenue />} />
                    <Route path="/all-notification" element={<AllNotification />} />
                    <Route path="/report" element={<Report />} />
                    <Route path="/request" element={<Request />} />
                    <Route path="/manage-class" element={<Manage />} />
                  </Routes>
                </div>
                <Footer />
              </div>
            </div>
          </Router>
          :
          <Router>
            <div>
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/change-password" element={<ChangePassword />} />
                <Route exact path="/create-password" element={<CreatePassword />} />
              </Routes>
            </div>
          </Router>
      }
    </>
  )

}