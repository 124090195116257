/*
 *   File : loader.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*Import Packages*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/loader.module.scss';
import LoaderImg from '../../assets/images/loader/mintd-loader.gif';


export default function Loader(props) {
	return (
		<>
			<div className={`${styles.e_loader} text-center`}><img src={LoaderImg} alt=""/></div>
		</>
	)
}
