/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for dashboard container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 29-04-2022
 */

/*import packages*/
import { Modal } from 'bootstrap';
import React, { useState, useRef, useEffect } from 'react';

/* import assets */
import Delete from '../../assets/images/cta/delete.png';

/* import components */
import { Summary } from '../../components/card';
import { PrimaryBtn } from '../../components/cta';
import { Loader } from '../../components/loader';
import { AddGlobalClass, Confirmation } from '../../components/modal';

/* import services */
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';

const Index = () => {
    const [globalList, setGlobalList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [id, setId] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        getGlobalClassList();
    }, [])

    const DeleteClass = (id) => {
        setId(id)
        var modal = new Modal(document.getElementById("confirmation"), {});
        modal.show()
    }

    /* list global class api */

    const getGlobalClassList = () => {
        setLoader(true);
        let url = devconfig.admin + "/global-class/list";

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setGlobalList(response.data);
            } else {
                setGlobalList([]);
                setLoader(false);
                setError(response.message);
            }
        });
    }
    return (
        <>
            {/*------------------- Cards -------------------- */}
            <div className="row ">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card e-faq-card e-quiz-card border-0 p-4">
                        <div className='row'>
                            <div className="col-lg-6">
                                <h6 className="e-card-title p-2 pt-3">Global class</h6>
                            </div>
                            <div className="col-lg-6 text-end">
                                <PrimaryBtn name="Add global class" target="#addGlobalClass" toggle="modal" />
                            </div>
                        </div>
                        {
                            loader ?
                                <div className='position-relative'>
                                    <Loader />
                                </div>
                                :
                                globalList && globalList.length > 0 ?

                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            {
                                                globalList.map((item, key) => {
                                                    return (
                                                        <div className="col-lg-4 mb-3" key={key}>
                                                            <div className="e-global-class-card">
                                                                {item.class}
                                                                <span className="float-end">
                                                                    <img src={Delete} alt="" className='ms-auto' onClick={() => DeleteClass(item.id)} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='text-center'>
                                        No data found
                                    </div>
                        }
                    </div>
                </div>
                {/* <div className="col-lg-3">
                    <Summary
                        data={{ title: 'Name', count: "Equity", icon: Delete, text: "", delete: true }}
                    />
                </div>
    */}
            </div>
            <AddGlobalClass addedClass={() => { getGlobalClassList() }} />
            <Confirmation
                title="global fund class"
                content="Do you want to delete the global fund class?"
                deleteClass={true}
                globalId={id}
                deletedClass={() => getGlobalClassList()} />
        </>
    )
}

export default Index;