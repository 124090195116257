/*
 *   File : transactions.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Holdingscontainer;
 *   Integrations : 07-02-2022
 *   Version : v1.0
 *   Created : 10-12-2021
*/
/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { PieChart } from '../../components/chart';

/*import assets*/
import Pending from '../../assets/images/card/pending.svg'

/*import services*/
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';
import { Loader } from '../../components/loader';
import { EmptyNote } from '../../components/note';
import { IconStyleToolTip } from '../../components/badge';

const Holdings = (props) => {
	const [click, setClick] = useState('1D');
	//eslint-disable-next-line
	const [holding, setHolding] = useState({});
	//eslint-disable-next-line
	const [id, setId] = useState(null);
	const [loader, setLoader] = useState(true);
	const [holdingGraphData, setHoldingGraphData] = useState([])
	const [holdingGraphColor, setHoldingGraphColor] = useState([])
	const [holdingGraphLabel, setHoldingGraphLabel] = useState([]);
	const [portfolio, setPortfolio] = useState({});
	const [index, setIndex] = useState();
	const [isShownCurrent, setIsShownCurrent] = useState(false);

	useEffect(() => {
		getHolding(props.id);
		setId(props.id)
		//eslint-disable-next-line
	}, [props.id]);

	useEffect(() => {
		let arr = [];
		for (let i = 0; i < holding?.graph_data?.fund_class.length; i++) {
			arr.push(i)
		}
		setIndex(arr)
	}, [holding.graph_data]);



	/* ---- get holdings api ---*/

	const getHolding = (temp) => {
		setLoader(true)
		let url = devconfig.admin + '/user/get-holding'
		let data = JSON.stringify({
			"user_id": parseInt(temp),
			filter_id: click
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					setHolding(response.data);
					setPortfolio(response.data.portfolio_by_class);
					setHoldingGraphData(response.data.graph_data.allocation);
					setHoldingGraphColor(response.data.graph_data.colors);
					setHoldingGraphLabel(response.data.graph_data.fund_class);
					setLoader(false)
				}
			} else {
				setLoader(false)
			}
		})
	}

	// find time weighted returns of corresponding asset 
	const filterReturn = (temp) => {
		let performance;
		if (holding.allocation) {
			let arr = holding.allocation
			for (let index = 0; index < arr.length; index++) {
				const element = arr[index];
				if (element.portfolio_id === temp) {
					performance = element.performance;
				}
			}
		}
		console.log('per', performance)
		var data;


		// map performance objects from equities and match with selected filter value
		 Object.keys(performance).forEach((key) => {

			if (Object.keys(performance[key])[0] === click) {
				data = Object.values(performance[key])[0]

			} // set object value to data if object value match with filter value
		});
		console.log("A", data)
		if (data && data < 0) {
			console.log(data)
			return (
				<span style={{ color: 'red' }}>{data}%</span>
			)
		} // return negative values with color red
		else if (data && data > 0) return <span style={{ color: '#0FD99C' }}>+{data}%</span> //return positive values with color green
		else { return <span style={{ color: '#ccc' }}>---</span>; }  // return "---" if nothing found with color gray;

	}



	return (
		<>
			{

				loader ?
					<div className="card e-dashboard-card border-0 p-4 pt-5 e-profile-holdings">
						<Loader />
					</div>
					:
					Object.keys(holding).length > 0 ?
						<>
							<div className="row">
								<div className="col-md-4">
									<div className="card e-dashboard-card border-0 p-4 pt-5 e-profile-holdings">
										<h4 className='px-2'>Portfolio summary</h4>
										<div className="pt-2 px-2">
											<span className="e-current-title">Current value</span>
											<p className="e-current-value">{holding.invested_data.current_value}</p>
										</div>
										<div className="pt-2 px-2">
											<span className="e-current-title">Amount invested</span>
											<p >{holding.invested_data.invested_amount}</p>
										</div>
										{/* <div className="pt-2 px-2">
											<span className="e-current-title">XIRR(%)</span>
											<p >{holding.invested_data.xirr}</p>
										</div> */}
									</div>
								</div>
								<div className="col-md-8">

									<div className="card e-dashboard-card border-0 p-4 e-profile-graph">
										<h4>Current allocation<span className="ps-1">
											<img src={Pending} alt="pending"
												style={{ cursor: "pointer" }}
												onMouseEnter={() => setIsShownCurrent(true)}
												onMouseLeave={() => setIsShownCurrent(false)}
											/></span>
											{
												isShownCurrent ?
													<IconStyleToolTip
														className="e-tooltip"
														content="The Securities and Exchange Board of India (SEBI), which regulates mutual funds and other securities, requires us to collect this information as part of a mandatory Know Your Client (KYC) process. "
													/>
													:
													null
											}
										</h4>

										<div className="row justify-content-center pt-4">
											{
												holdingGraphData && holdingGraphData.length &&
													holdingGraphLabel && holdingGraphLabel.length &&
													holdingGraphColor && holdingGraphColor.length ?
													<div className="col-md-4">
														<PieChart
															data={holdingGraphData}
															colors={holdingGraphColor}
															labels={holdingGraphLabel}
														/>
													</div>
													:
													null
											}
											<div className="col-md-4 pt-3">
												<div className='e-graph-labels'>
													{

														index.map((idx) => (
															<>
																<h4><div className="rounded-circle me-2" style={{ backgroundColor: `${holdingGraphColor[idx]}`, width: '9px', height: '10px', display: 'inline-block' }}></div>{holdingGraphData[idx]}%</h4>
																<p className="ps-3">{holdingGraphLabel[idx]}</p>
															</>
															// <p className=""><div className="rounded-circle me-2" style={{ backgroundColor: `${riskData.graph_data.colors[idx]}`, width: '16px', height: '16px', display: 'inline-block', position: 'relative', top: '3px' }}></div>{riskData.graph_data.fund_class[idx]}: <span>{riskData.graph_data.allocation[idx]}%</span></p>
														))
													}
												</div>

												{/* <h4><div className="rounded-circle me-2" style={{ backgroundColor: '#6439EC', width: '9px', height: '10px', display: 'inline-block' }}></div>10%</h4>
												<p className="ps-3">International Entities</p>
												<h4><div className="rounded-circle me-2" style={{ backgroundColor: '#8D43F1', width: '9px', height: '10px', display: 'inline-block' }}></div>10%</h4>
												<p className="ps-3">Indian Debt</p> */}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-md-12">
									<div className="card e-dashboard-card border-0 p-4 e-holding-table">
										<div className="row justify-content-end mb-4">
											<div className="col-md-4">
												<h4>Asset class breakdown</h4>
											</div>
											<div className="col-md-8">
												<div className="text-end">
												<span className={click === "1D" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("1D")}>
														1D
													</span>
													<span className={click === "1M" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("1M")}>
														1M
													</span>
													<span className={click === "3M" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("3M")}>
														3M
													</span>
													<span className={click === "6M" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("6M")}>
														6M
													</span>
													{/* <span className={click === "4" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("4")}>
														YTD
													</span> */}
													<span className={click === "1Y" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("1Y")}>
														1Y
													</span>
													{/* <span className={click === 5 ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick(5)}>
														Since 1st Desposit
													</span> */}
												</div>
											</div>
										</div>
										<div className="row p-1 e-holding-table-heading pb-4">
											<div className="col-md-5 text-start">
												<span className="e-current-title ps-3">Asset class</span>
											</div>
											<div className="col-md-5 text-start">
												<span className="e-current-title">Current weight</span>
											</div>
											<div className="col-md-2 text-start ps-0">
												<span className="e-current-title ">Returns</span>
											</div>
										</div>


										{
											Object.keys(portfolio).map((className, index) => {
												let mfs_in_assetclass = portfolio[className];
												return (
													<>
														<h4 className="pt-4 ps-3">{className}</h4>
														<div className="row mt-3 p-4 e-holding-table-data ">
															{
																mfs_in_assetclass.map((item) => (
																	<>
																		<div className="col-md-5 text-start pb-3">
																			<div className="e-table-data">{item.scheme_name}</div>
																		</div>
																		<div className="col-md-5 text-start pt-2 pb-3">
																			<div className="d-inline position-relative">
																				<div class="progress ">
																					<div class="progress-bar me-3" role="progx`ressbar" style={{ width: `${item.allocation}%`, height: '8px' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
																					<span className="e-table-data-percent pe-3">{item.allocation}%</span>
																				</div>
																			</div>
																		</div>
																		<div className="col-md-2 text-start pb-3">
																			<span className="e-current-data-desc ">{filterReturn(item.portfolio_id)}</span>
																		</div>
																	</>
																)
																)
															}
														</div>
													</>
												)
											})
										}
									</div>
								</div>
							</div>
						</>
						:
						<EmptyNote note="There is no holdings to show" />
			}
		</>

	)
}

export default Holdings;

