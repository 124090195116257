/*
 *   File : summary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Summary Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 13-12-2021
 */

/*import packages*/
import React from 'react';

/*Style Import*/
import styles from '../../styles/components/summary.module.scss';

const SummaryRevenue = (props) => {

	return (
		<div className={`${styles.e_summary_card} card `}>
			<div className="row">
				<div className="col-lg-12 col-md-12 col-sm-12 col-12">
					<h5>
						{props.data.title}
					</h5>
				</div>
				<div className="col-lg-8 col-md-10 col-10">
					<span><span className={`${styles.e_text}`}>{props.data.currency}</span><span className={styles.e_text}>{props.data.text}</span>{props.data.count} </span>
				</div>
				<div className="col-lg-4 col-md-2 col-2 text-end">
					<div className={styles.e_img_bg}>
						<img src={props.data.icon} className={styles.e_summary_card_icon} alt=""/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SummaryRevenue