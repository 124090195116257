/*
 *   File : changeAllocation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Change Allocation Success Modal
 *   Integrations :14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { message } from 'antd';

/*import styles*/
import styles from '../../styles/components/risk.module.scss';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { Toast } from '../note';

export default function ChangeAllocation(props) {

	const [allocation, setAllocation] = useState(null);
	const [getFundId, setGetFundId] = useState(null);
	const [error, setError] = useState(null);
	const [globalId, setGlobalId] = useState(null)

	useEffect(() => {
		setGetFundId(props?.editedData?.scheme_code)
		setAllocation(props?.editedData?.allocation)
		setGlobalId(props.editedData?.global_class_id);
	}, [props?.editedData]);

	/*-----update allocation---- */
	const updateAllocation = () => {
		let url = devconfig.admin + '/funds/update-risk-fund'
		let data = JSON.stringify({
			portfolio_id: props.editedData.portfolio_id,
			risk_score_id: props.riskId,
			scheme_code: getFundId,
			name: props.editedData.name,
			global_class_id: globalId,
			allocation: allocation
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					message.success("Allocation updated successfully");
					$("#change").trigger('click');
					props.updatedAllocation(true);
				} else {
					setError(response.message)
				}
			}
			else {
				setError(response.message);
			}
		})
		setTimeout(() => {
			setError(null);
		}, 3000);
	}

	return (
		<div className={`${styles.e_risk} modal fade`} id="change" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className="modal-content">
					<div className="modal-header">
						<h6>Change allocation</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className="col-lg-12">
								<label>Allocation (%)</label>
								<Input
									placeholder="Allocation"
									value={allocation}
									valueChange={(value) => {
										props?.editedData ?
											setAllocation(value)
											:
											setAllocation(value)
									}}
									type="text"
								/>
							</div>
						</div>
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn
										name="Cancel"
										className={`${styles.e_cancel} e-cancel`}
										cancel="modal"
										handleClick={() => setAllocation("")}
									/>
									{
										allocation ?
											<PrimaryBtn
												name="Change"
												className="e-modal-submit"
												handleClick={() => updateAllocation()}
											/>
											:
											<PrimaryBtn
												name="Change"
												className='e-modal-submit e-disabled'
											/>
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast data={error} type="fail" format={1} />
					:
					null
			}
		</div>
	);
}
