/*
 *   File : orders.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order list;
 *   Integrations : 24-05-2022
 *   Version : v1.0
 *   Created :24-05-2022
*/
/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';
import moment from 'moment';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { FilterBtn, DownloadBtn } from '../../components/cta/index';
import { OrderDetail } from '../../components/modal/index';
import { SearchInput, DateRangePicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';
import { EmptyNote } from '../../components/note';
/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import View from '../../assets/images/dataFormating/view.png';
import Tooltip from '../../components/badge/tooltip';


/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';


const Orders = (props) => {

	const wrapperRef = useRef(null);

	const [search, setSearch] = useState("");
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: null, end: null });
	const [more, setMore] = useState(false);
	const [refKey, setRefKay] = useState();
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState({ label: "Select", value: null });
	const [selectedType, setSelectedType] = useState({ label: "Select", value: null });
	const [statusOptions, setStatusOptions] = useState([]);
	const [typeOptions, setTypeOptions] = useState([]);
	const [check, setCheck] = useState([])
	const [show, setShow] = useState(null);
	const [orderData, setOrderData] = useState([]);
	const [details, setDetails] = useState({});
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [showOder, setShowOrder] = useState(null)

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	useEffect(() => {

		if (search === "") {
			getOrderList();
		}

		//eslint-disable-next-line
	}, [dates, selectedType, selectedStatus, search]);

	useEffect(() => {
		filerData();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (error !== null) {
				setError(null);
			}
		}, 3000);
	}, [error]);

	const customDateSort = (a, b, selector, given) => {
		let format = given ? given : "DD MMM YYYY"
		if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
		else if (a[selector]) return -1;
		else if (b[selector]) return 1;
		else return 0;
	}

	/*---- order table columns -----*/

	const columns = [
		{
			name: 'Order ID',
			selector: (row) => row.order_id ? row.order_id : '--',
			sortable: true,
			left: true,
			maxWidth: "160px",
			defaultSortAsc: false,
		},
		{
			name: 'Dated on',
			selector: "transaction_date",
			sortable: false,
			left: true,
			sortFunction: (a, b) => customDateSort(a, b, 'transaction_date',),
			cell: row => <div className="col-lg-12">
				{row.transaction_date}
			</div>
		},
		{
			name: 'Type',
			selector: 'type',
			sortable: true,
			left: true,
			cell: (row) => <div className="col-lg-12" onClick={() => modalOpen(row)} >
				{row.type}
			</div>
		},
		{
			name: 'Amount',
			selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
			sortable: true,
			left: true,
		},
		{
			name: 'Payment status',
			selector: 'payment_status',
			sortable: false,
			left: true,
			// cell: row => <div className="">
			//   <Badge type="success" text="success"/>
			// </div>

			cell: row => <>{row.payment_status ?
				<div className="d-inline-flex">
					<div className="col-lg-10">
						<div className={
							row.payment_status === "Partially completed" ? `e-status-badge e-status-badge-partially` :
								row.payment_status === "Success" ? `e-status-badge e-status-badge-success` :
									row.payment_status === "Failed" ? `e-status-badge e-status-badge-fail` :
										row.payment_status === "Pending" || row.status === "PENDING" ? `e-status-badge e-status-badge-pending` :
											'e-status-badge'
						}>
							{row.payment_status.toLowerCase()}
						</div>
					</div>
					<div className="col-lg-2">
						<span onMouseEnter={() => setShow(row.id)} onMouseLeave={() => setShow(null)} className={
							row.payment_status === "Failed" ? `e-icon` :
								row.payment_status === "Pending" || row.payment_status === "PENDING" ? `e-icon` :
									null
						}>

						</span>
					</div>
					{
						show === row.id ?
							<Tooltip content={row.payment_status === "Pending" ? "Transaction pending" : row.payment_failed_reason ? row.payment_failed_reason : "Transaction failed"} />
							:
							null
					}
				</div>
				:
				"--"}</>
		},
		// {
		// 	name: 'status',
		// 	selector: 'status',
		// 	sortable: true,
		// 	left: true,
		// 	// cell: row => <div className="">
		// 	//   <Badge type="success" text="success"/>
		// 	// </div>

		// 	cell: row => <>{row.status !== null ?
		// 		<div className="d-inline-flex">
		// 			<div className="col-lg-10">
		// 				<div className={
		// 					row.status === "Partially completed" ? `e-status-badge e-status-badge-partially` :
		// 						row.status === "Success" ? `e-status-badge e-status-badge-success` :
		// 							row.status === "Failed" ? `e-status-badge e-status-badge-fail` :
		// 								row.status === "Pending" || "PENDING" ? `e-status-badge e-status-badge-pending` :
		// 									'e-status-badge'
		// 				}>
		// 					{row.status.toLowerCase()}
		// 				</div>
		// 			</div>
		// 			<div className="col-lg-2">
		// 				<span onMouseEnter={() => setShowOrder(row.id)} onMouseLeave={() => setShowOrder(null)} className={
		// 					row.status === "Failed" ? `e-icon` :
		// 						row.status === "Pending"  || row.status ==="PENDING"  ? `e-icon` :
		// 							null
		// 				}>

		// 				</span>
		// 			</div>
		// 			{
		// 				showOder === row.id ?
		// 					<Tooltip content={row.status === "Pending" ? "Transaction pending" : "Transaction failed"} />
		// 					:
		// 					null
		// 			}
		// 		</div>
		// 		:
		// 		"--"}</>
		// },
		{
			name: '',
			selector: 'actions',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: row => <div className="d-flex align-items-end" onClick={() => { setRefKay(row.id); setMore(!more); }} ref={wrapperRef}>
				<span className="e-more" >
				</span>
				{
					more && row.id === refKey ?
						<div className="e-card-transaction" ref={wrapperRef} onClick={() => modalOpen(row)}>
							<span >
								<img src={View} alt="View details" className="me-2" />View Details
							</span>
						</div>
						:
						null
				}

			</div>
		},
	]

	/*--- func to open order detail modal ---*/
	const modalOpen = (row) => {
		setDetails(row);
		var myModal = new Modal(document.getElementById("orderDetail"), {});
		myModal.show();
	}

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false);
		}
	}

	/* --------selecting rows---------*/
	const handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['id']);
		}
		setCheck(selected);
	}

	/*--------- order list -----------*/
	const getOrderList = () => {
		setLoader(true)
		let url = devconfig.admin + '/user/order/list';

		let data = JSON.stringify({
			user_id: Number(props.id),
			from_date: dates.start,
			till_date: dates.end,
			status_id: selectedStatus.value,
			transaction_type_id: selectedType.value,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {

				setOrderData(response.data);
				setLoader(false);
			} else if (response.status_code === 400) {
				setOrderData(response.data);
				setLoader(false);
			}
			else {
				setLoader(false)
				setOrderData([]);
				setError(response.message);
			}
		});
	}



	/*---- filter data for particular user --*/
	const filerData = () => {
		let type_options_temp = [];
		let status_options_temp = [];

		let url = devconfig.admin + '/user/transactions-filter-data';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				response.data.transaction_type.map((item) => {
					type_options_temp.push({ value: item.id, label: item.label });
					return true;
				});
				response.data.transaction_status.map((item) => {
					status_options_temp.push({ value: item.id, label: item.label });
					return true;
				});
				setStatusOptions(status_options_temp);
				setTypeOptions(type_options_temp);
			}
			else {
				setError(response.message);
			}
		});
	}



	/*---- download orders--*/
	const downloadTransactions = () => {
		setDownloadLoader(true);
		let url = devconfig.admin + '/user/order/download';
		let data = JSON.stringify({
			user_id: Number(props.id),
			order_ids: check
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					setDownloadLoader(false);
					let a = document.createElement("a");
					a.href = response.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setSearch("");
					setCheck([]);
				} else {
					setDownloadLoader(false);
					setError(response.message)
				}
			}
			else {
				setDownloadLoader(false);
				setError(response.message);
			}
		})
	}

	/* serach orders */
	const searchOrders = (value) => {
		setStatusOptions([]);
		setTypeOptions([]);
		// setSearch(value);
		let url = devconfig.admin + '/user/order/search'
		let data = JSON.stringify({
			user_id: props.id,
			query: search || value,
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setOrderData(response.data);
				filerData();
				setSearch(value);
				setSearch(search);
			}
			else {
				setOrderData([]);
				setError(response.message);
			}
		});
	}

	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<div className="card e-dashboard-card border-0">
						<div className="row pt-2">
							<div className="col-lg-5">
								<h6 className="e-card-title p-2">All Orders</h6>
							</div>
							<>
								<div className="col-lg-3 align-items-center text-end" >
									<DownloadBtn
										check={check}
										onClick={() => {

											downloadTransactions()

										}}
									/>
								</div>
								<div className="col-lg-3 position-relative">
									<SearchInput
										placeholder="Search with id"
										value={search}
										valueChange={(value) => { setSearch(value) }}
										onSearch={(value) => {
											searchOrders(value)
										}}
									/>
									{
										search && search.length ?
											<>
												<span
													className={`${styles.e_search_submit} `}
													onClick={() => {
														searchOrders()
													}}>
												</span>
												<button
													className={`${styles.e_search_close} pe-3`}
													onClick={() => {
														setSearch("");
														getOrderList();

													}}>
												</button>
											</>
											:
											null
									}

								</div>
								<div className="col-lg-1">
									<FilterBtn
										// img={Filter}
										className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
										handleClick={() => {
											setFilterDown(!filterDown);
											setSearch("");
											if (filterDown === true) {
												setDates({ start: null, end: null });
												setSelectedType({ label: "Select", value: null });
												setSelectedStatus({ label: "Select", value: null });
											}
										}}
									/>
								</div>
							</>
							{/*-------------- Filters  ---------------*/}
							{
								filterDown ?
									<div className="e-filter-wraper e-sort mt-3">
										<div className="row">
											<div className="col-lg-3 pe-0">
												<label className="mb-2">Transactions from - till</label>
												<DateRangePicker
													onDatesChange={(start, end) => {
														setDates({ start: start, end: end })
													}}
												/>
											</div>
											<div className="col-lg-2 px-2">
												<label className="mb-2">Status</label>
												<SelectBox
													value={selectedStatus}
													options={statusOptions}
													selectchange={(temp) => setSelectedStatus(temp)}
													isSearchable={false}
												/>
											</div>
											<div className="col-lg-2  p-0">
												<label className="mb-2">Type</label>
												<SelectBox
													value={selectedType}
													options={typeOptions}
													selectchange={(temp) => setSelectedType(temp)}
													isSearchable={false}
												/>
											</div>
										</div>
									</div>
									:
									null
							}

							{/*--------------- order table -----------*/}
							{
								loader ?
									<Loader />
									: (!orderData.length) ?
										<EmptyNote note="There is no transaction to show" />
										:
										<div className="col-lg-12 px-0">
											<DataTable
												pagination={true}
												columns={columns}
												data={orderData}
												selectableRows={true}
												clearSelectedRows={downloadLoader ? true : false}
												selectRows={(row) => { handleChange(row) }}
												rowClick={(row) => modalOpen(row)}
											/>
										</div>
							}

						</div>
					</div>
				</div>
			</div>

			{/*--------- Order detail modal component ----------------*/}
			<div ref={wrapperRef}>
				<OrderDetail
					data={details}
					id={props.id}
					fName={props.fName}
					lName={props.lName}

				/>
			</div>
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
		</>
	);
}

export default Orders;

