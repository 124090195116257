/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Report container;
 *   Integrations :null
 *   Version : v1.0
 *   Created : 16-02-2022
*/

/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';
import { Loader } from '../../components/loader';
import SelectBox from '../../components/dropdown/selectBox';
import { Toast } from '../../components/note';
import { TextArea } from '../../components/input';
import Editor from '../../components/input/editor';
import { ReportHistory } from '../../components/modal';
import { message } from 'antd';
import { Modal } from 'bootstrap';
/*import services */
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';

const Index = (props) => {
	const [riskData, setRiskData] = useState([]);
	const [error, setError] = useState(null);
	const [riskId, setRiskId] = useState(null);
	const [riskLoader, setRiskLoader] = useState(true);
	const [riskName, setRiskName] = useState(null);
	//eslint-disable-next-line
	const [selectedMonth, setSelectedMonth] = useState({});
	const [lastMessageDate, setLastMessageDate] = useState("");
	const [reportMessage, setReportMessage] = useState(null);
	const [loader, setLoader] = useState(false);
	const [options, setOptions] = useState();
	const [sendLoader, setSendLoader] = useState(false);
	const [historyData, setHistoryData] = useState(null);
	const [historyLoader, setHistoryLoader] = useState(false);
	const [openHistoryModal, setOpenHistoryModal] = useState(false);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
		getMonths();
		// console.log(new Date().toLocaleString('default', { month: 'long' }))
		// console.log(new Date().getMonth())
		getRisk();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	/*------- select box options --------*/
	const getMonths = () => {
		let url = devconfig.admin + '/report/get-months';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				var resultArray = response.data.map(function (elm) {
					return { value: elm.id, label: elm.name };
				});
				setOptions(resultArray)
			}
		})
	}


	/*---- get risk scores api for report ----*/

	const getRisk = () => {
		setRiskLoader(true);

		let url = devconfig.admin + '/report/score';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setRiskData(response.data);
				setRiskId(response?.data[0].risk_id);
				setRiskName(response?.data[0].name);
				setReportMessage(response?.data[0].message);
				setLastMessageDate(response?.data[0]?.last_message_on);
				setRiskLoader(false);
			}
			else {
				setRiskLoader(false);
				setError(response?.message);
			}
		});
	}

	useEffect(()=> {
		if ( openHistoryModal ) {
			const modal = new Modal(document.getElementById("historyModal"), {})
        	modal.show()  
		} 
	},[openHistoryModal])

	/*---- save message api for report ----*/
	const saveMessage = () => {
		setLoader(true);

		let url = devconfig.admin + '/report/save-message';

		let data = JSON.stringify({
			risk_score_id: riskId,
			message: reportMessage
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success("Message saved");
				setLoader(false);
				let scoreIndex = riskData.findIndex((score) => score.risk_id === riskId);

				if (scoreIndex !== -1) {
					riskData[scoreIndex]['message'] = reportMessage;
				}
			} else {
				setError(response.message);
				setLoader(false);
			}
		});
	}

	/*-----send monthly report api---*/
	const sendReport = () => {
		setSendLoader(true);

		let url = devconfig.admin + '/report/send-message';

		let data = JSON.stringify({
			risk_score_id: riskId,
			month_id: selectedMonth?.value
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success("Report sent");
				setSendLoader(false);
				getRisk()

			} else {
				setSendLoader(false);
				setError(response.message);
			}
		})
	}

	const getHistory = () =>{
		setHistoryLoader(true);
		let url = devconfig.admin+'/report/history';
		let data = JSON.stringify({
			risk_score_id: riskId,
			month_id: selectedMonth?.value
		})
		APIPost(url, data).then((response) =>{
			if (response.status_code === 200 ) {
				setHistoryData(response.data);
				setHistoryLoader(false);
				setOpenHistoryModal(true);
			}else{
				setHistoryLoader(false);
				setError(response.message);
			}
		})
	}

	return (
		<>
			{
				riskLoader ?
					<Loader />
					:
					<div className="row pb-5">
						<div className="col-lg-12">
							<div className="card e-dashboard-card e-report border-0 p-4 position-relative">
								<div className="row">
									<div className="col-lg-12 ">
										<h6 className="e-card-title">Risk scores</h6>
									</div>

									{/*----------- Risk scores from 0.5 to 10------------------*/}
									<div className="col-lg-12 d-flex mt-3">
										{
											riskData?.map((item, key) => (
												<div
													key={key}
													onClick={() => {
														setRiskId(item.risk_id);
														setRiskName(item.name);
														setLastMessageDate(item.last_message_on);
														setReportMessage(item.message);
														setSelectedMonth({});
													}}
													className={
														item.risk_id === riskId ?
															"e-btn-active-wrapper"
															:
															"e-btn-wrapper"
													}>
													<span className="e-btn-active">{item.name}</span>
												</div>
											))
										}
									</div>
								</div>

								{/*----------- Create report fields ------------------*/}
								<div className="col-lg-12 d-flex pt-3 justify-content-between">
									<h6 className="e-card-title pt-3 mt-2">Create report for risk score {riskName}</h6>
								</div>
								<div className="col-lg-12 d-flex pt-3 justify-content-between">
									<div className='col-lg-4 '>
										<label>Choose the month</label>
										<SelectBox
											value={Object.values(selectedMonth).length > 0 ? selectedMonth : ""}
											options={options}
											selectchange={(temp) => setSelectedMonth(temp)}
											isSearchable={false}
										/>
									</div>
									<div className='col-lg-6 text-end'>
									{
											Object.values(selectedMonth).length > 0 ?
												<SecondaryBtn
													name="View report history"
													className={historyLoader === true ? "e-send-report e-btn-loader me-2" : "e-send-report me-2"}
													handleClick={() => getHistory()}
												/>
												:
												<SecondaryBtn
													name="View report history"
													className="e-send-report e-disabled me-2"
												/>
										}

										{
											Object.values(selectedMonth).length > 0 ?
												<PrimaryBtn
													name="Send monthly report"
													className={sendLoader === true ? "e-send-report e-btn-loader" : "e-send-report"}
													handleClick={() => sendReport()}
												/>
												:
												<PrimaryBtn
													name="Send monthly report"
													className="e-send-report e-disabled"
												/>
										}
										<div className='d-flex justify-content-end pt-2'>
											<p>Last report sent on:  </p>
											<h6>{lastMessageDate !== "" ? lastMessageDate : null}</h6>
										</div>
									</div>
								</div>
								<div className='col-lg-12 mt-4'>
									<label>Content</label>
									<Editor 
										editorChange={(temp)=>{setReportMessage(temp)}}
										value={reportMessage}
										className="e-notification-form-element"
									/>
									{/* <TextArea
										placeholder="Add message"
										value={reportMessage}
										className="e-notification-form-element"
										rows="7"
										valueChange={(temp) => { setReportMessage(temp) }}
									/> */}
								</div>
								<div className='col-lg-12 mt-5'>
									{
										reportMessage !== null ?
											<PrimaryBtn
												name="Save"
												className={loader === true ? "e-report-save-btn e-btn-loader" : "e-report-save-btn"}
												handleClick={() => saveMessage()}
											/>
											:
											<PrimaryBtn
												name="Save"
												className="e-disabled e-report-save-btn"
												handleClick={() => saveMessage()}
											/>
									}
								</div>
							</div>
						</div>

						{/*----------- error message toast ------------------*/}
						{
							error !== null ?
								<Toast
									data={error}
									type="fail"
									format={1}
								/>
								:
								null
						}
						{
							openHistoryModal && (
								<ReportHistory 
									data={historyData}
									modalClose={()=>{ 
										setOpenHistoryModal(false);
										setHistoryData(null);
									}}
								/>
							)
						}
					</div>
			}
		</>
	);
}

export default Index;