/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations : 17-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import $ from 'jquery'

/*import componets*/
import { Input, TextArea } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/risk.module.scss';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

export default function EditTopic(props) {

	const [topic, setTopic] = useState("");
	const [error, setError] = useState(null);
	const [id, setId] = useState(null);
	const [desc, setDesc] = useState("");

	useEffect(() => {
		if (props.data) {
			setId(props.data.topic_id)
			setTopic(props.data.topic)
			setDesc(props.data?.description)
		}
	}, [props.data]);

	/*-------edit topic api for quiz ---*/
	const editTopic = () => {
		let url = devconfig.admin + '/quiz/edit-topic'

		let data = JSON.stringify({
			topic_id: id,
			topic: topic,
			description: desc,
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success('Topic edited successfully')
				$('#editTopic').trigger('click');
				props.edit(true)
			} else {
				setError(response.message)
			}
		})
		setTimeout(() => {
			setError(null)
		}, 3000)
	}

	return (
		<div className={`${styles.e_risk} modal fade`} id="editTopic" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className="modal-content">
					<div className="modal-header">
						<h6>Edit topic</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className="col-lg-12">
								<label>Topic name</label>
								<Input placeholder="Eg : Indian equities" value={props.data ? topic : topic} valueChange={(value) => { props?.data ? setTopic(value) : setTopic(value) }} type="text" />
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<label>Description </label>
								<TextArea placeholder="Topic description" value={props.data ? desc : desc} valueChange={(value) => { props?.data ? setDesc(value) : setDesc(value) }} type="text" />
							</div>
						</div>
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn name="Cancel" className={`${styles.e_cancel} e-cancel`} cancel="modal" />
									{
										topic ?
											<PrimaryBtn name="Change" className="e-modal-submit" handleClick={() => editTopic()} />
											:
											<PrimaryBtn name="Change" className='e-disabled' />
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast data={error} format={1} type="fail" />
					:
					null
			}
		</div>
	);
}
