/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for dashboard container;
 *   Integrations :01-02-2022
 *   Version : v1.0
 *   Created : 11-12-2021
 */

/*import packages*/
import { useState, useEffect } from 'react';

/*import assets*/
import Transaction from '../../assets/images/card/total.png';
import Users from '../../assets/images/card/users.png';
import Gold from '../../assets/images/card/pending.png';
import Total from '../../assets/images/card/transcations.png';

/*import components*/
import { Summary } from '../../components/card/index';
import { LineChart, BarChartSecond, BarChartLast, LineChartSecond } from '../../components/chart/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Loader } from '../../components/loader';
import DateRange from '../../components/input/dateRangepicker';
/* import services */
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';
import { Toast } from '../../components/note';

const Index = (props) => {
	const [cumulativeFilter, setCumulativeFilter] = useState(1);
	const [periodicFilter, setPeriodicFilter] = useState(2);
	const [dataLoader, setDataLoader] = useState(true);
	const [dashboardData, setDashboardData] = useState({});
	const [error, setError] = useState(null);
	// const [aumPeriodicData, setAumPeriodicData] = useState([]);
	// const [aumPeriodicLabel, setAumPeriodicLabel] = useState([]);
	const [userPeriodicData, setUserPeriodicData] = useState([]);
	const [userPeriodicLabel, setUserPeriodicLabel] = useState([]);
	const [performancePeriodicData, setPerformancePeriodicData] = useState([]);
	const [performancePeriodicLabel, setPerformancePeriodicLabel] = useState([]);
	const [periodicLoader, setPeriodicLoader] = useState(true);
	// const [aumCumulativeData, setAumCumulativeData] = useState([]);
	// const [aumCumulativeLabel, setAumCumulativeLabel] = useState([]);
	const [userCumulativeData, setUserCumulativeData] = useState([]);
	const [userCumulativeLabel, setUserCumulativeLabel] = useState([]);
	const [revenueCumulativeData, setRevenueCumulativeData] = useState([]);
	const [revenueCumulativeLabel, setRevenueCumulativeLabel] = useState([]);
	const [cumulativeLoader, setCumulativeLoader] = useState(true);
	const [selected, setSelected] = useState({value: 1, label: 'Weekly',});
	const [selectedPeriod, setSelectedPeriod] = useState();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 300);
	}, [error]);

	useEffect(() => {
		getDashboardData();
	}, []);

	useEffect(() => {
		getPeriodicPerformance();
		//eslint-disable-next-line
	}, [])

	// useEffect(() => {
	// 	getCumulativePerformace(1);
	// 	//eslint-disable-next-line
	// }, [])

	useEffect(()=>{
			 getCumulativePerformace(selected.value);
	},[startDate, endDate])
	/* select box options ---*/

	const cumulativeOptions = [
		{
			value: 1, label: 'Weekly',
		},
		{
			value: 2, label: 'Monthly'
		},
		{
			value: 3, label: 'Daily'
		},
	]

	const periodicOptions = [
		{
			value: 1, label: 'Weekly',
		},
		{
			value: 2, label: 'Monthly'
		},
		{
			value: 3, label: 'Daily'
		},
	]

	/*----- dashboard data api ---*/
	const getDashboardData = () => {
		setDataLoader(true)
		let url = devconfig.admin + '/get-dashboard'
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setDataLoader(false);
				setDashboardData(response.data);
			}
			else {
				setDataLoader(false);
				setError(response?.message);
			}
		});
	}

	/* ---- get periodic performance api---*/
	const getPeriodicPerformance = (value) => {
		setPeriodicFilter(value)
		if (value) {
			// setAumPeriodicData([]);
			// setAumPeriodicLabel([]);
			setUserPeriodicData([]);
			setUserPeriodicLabel([]);
			setPerformancePeriodicData([]);
			setPerformancePeriodicLabel([]);
		}
		setPeriodicLoader(true)
		let url = devconfig.admin + '/get-periodic-performance'
		let data = JSON.stringify({
			filter_id: value || periodicFilter
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setPeriodicLoader(false);
				// setAumPeriodicData(response.data.aum.data);
				// setAumPeriodicLabel(response.data.aum.label);
				setUserPeriodicData(response.data.users.data);
				setUserPeriodicLabel(response.data.users.label);
				setPerformancePeriodicData(response.data.revenue.data);
				setPerformancePeriodicLabel(response.data.revenue.label);

			}
			else {
				setPeriodicLoader(false);
				setError(response.message);
			}
		});
	}
	/*---- get cumulative performance ---*/
	const getCumulativePerformace = (value) => {
		setCumulativeFilter(value)
		
			// setAumCumulativeData([]);
			// setAumCumulativeLabel([]);
			setUserCumulativeData([]);
			setUserCumulativeLabel([]);
			setRevenueCumulativeData([]);
			setRevenueCumulativeLabel([]);
		
		setCumulativeLoader(true)
		let url = devconfig.admin + '/get-cumulative-performance'
		let data;
		if ( startDate && endDate ){
			data = JSON.stringify({
				from_date : startDate,
				till_date : endDate
			});
		}
		else{
			data = JSON.stringify({
				filter_id : value,
				from_date : startDate,
				till_date : endDate
			});
		}
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setCumulativeLoader(false);
				// setAumCumulativeData(response.data.aum.data);
				// setAumCumulativeLabel(response.data.aum.label);
				setUserCumulativeData(response.data.users.data);
				setUserCumulativeLabel(response.data.users.label);
				setRevenueCumulativeData(response.data.revenue.data);
				setRevenueCumulativeLabel(response.data.revenue.label);

			}
			else {
				setCumulativeLoader(false);
				setError(response.message);

			}
		});
	}
	return (
		<>
			{
				dataLoader === false ?
					<>
						{/*-------------- Dashboard cards---------------- */}

						<div className="row e-dashboard">
							{/* <div className="col-lg-3">
								<Summary 
								data={{ title: 'AUM', currency: "₹", count: dashboardData.aum_fee, icon: Transaction, text: "" }} 
								/>
							</div> */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<Summary
									data={{ title: 'Users', count: dashboardData.users, icon: Users, text: "" }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary 
								data={{ title: 'AUM', count: (dashboardData?.aum)?.split(" ")[0], icon: Transaction, text: (dashboardData?.aum)?.split(" ")[1] }} 
								/>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<Summary
									data={{ title: 'All time revenue', count: (dashboardData?.revenue)?.split(" ")[0], icon: Gold, text: (dashboardData?.revenue)?.split(" ")[1] }}
								/>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<Summary
									data={{ title: 'Pending AUM', count: dashboardData.pending_aum, icon: Total }}
								/>
							</div>
							
						</div>

						{/*------------------ Dashboard line graph------------------ */}

						<div className="row mt-5 e-sort">
							<div className='col-lg-6'>
								<h6 className="e-card-title">Cumulative performance</h6>
							</div>
							<div className='col-lg-6 d-flex justify-content-end'>
								<DateRange 
									onDatesChange={(start, end)=>{ 
										setStartDate(start);
										setEndDate(end);
									}}
								/>
								<SelectBox
									value={selected}
									isDisabled={startDate && endDate ? true : false}
									options={cumulativeOptions}
									defaultValue={cumulativeOptions[0]}
									className="e-dashboard-card-sort ms-3"
									selectchange={(temp) => { getCumulativePerformace(temp.value); setSelected(temp) }}
									isSearchable={false}
								/>
							</div>
							
						</div>
						<div className="row mt-3">
							{/* <div className="col-lg-4">
								<div className="card e-dashboard-card e-round-chart-container border-0 p-4">
									<h6 className="e-card-title">AUM</h6>
									{
										cumulativeLoader === false ?
											aumCumulativeData && aumCumulativeData.length &&
												aumCumulativeLabel && aumCumulativeLabel.length ?
												<div className="col-lg-12 ms-3  ">
													<LineChart colors={['#1329E6', '#FFFFFF']} data={aumCumulativeData} label={aumCumulativeLabel} />
												</div>
												:
												null
											:
											null
									}
								</div>
							</div> */}
							<div className="col-lg-6">
								<div className="card e-dashboard-card e-round-chart-container border-0 p-4">
									<h6 className="e-card-title">Users</h6>
									{
										cumulativeLoader === false ?
											userCumulativeData && userCumulativeData.length &&
												userCumulativeLabel && userCumulativeLabel.length ?
												<div className="col-lg-12 ms-3  ">
													<LineChartSecond
														colors={['#1329E6', '#FFFFFF']}
														data={userCumulativeData}
														label={userCumulativeLabel}
													/>
												</div>
												:
												null
											:
											null
									}
								</div>
							</div>
							<div className="col-lg-6">
								<div className="card e-dashboard-card e-round-chart-container border-0 p-4">
									<h6 className="e-card-title">AUM</h6>
									{
										cumulativeLoader === false ?
											revenueCumulativeData && revenueCumulativeData.length &&
												revenueCumulativeLabel && revenueCumulativeLabel.length ?
												<div className="col-lg-12 ms-3  ">
													<LineChart
														colors={['#1329E6', '#FFFFFF']}
														data={revenueCumulativeData}
														label={revenueCumulativeLabel}
													/>
												</div>
												:
												null
											:
											null
									}
								</div>
							</div>
						</div>

						{/* -------- Dashboard Periodic performance bar graph--------------*/}

						<div className="d-flex justify-content-between mt-5 e-sort">
							<h6 className="e-card-title">Periodic Performance</h6>
							<SelectBox
								value={selectedPeriod}
								options={periodicOptions}
								defaultValue={periodicOptions[1]}
								className="e-dashboard-card-sort"
								selectchange={(temp) => { getPeriodicPerformance(temp.value); setSelectedPeriod(temp) }}
								isSearchable={false}
							/>
						</div>
						<div className="row">
							{/* <div className="col-lg-4 mt-2">
								<div className="card e-dashboard-card e-dashboard-row-2 border-0 p-3">
									<h6 className="e-card-title">AUM</h6>
									{
										periodicLoader === false ?
											aumPeriodicData && aumPeriodicData.length &&
												aumPeriodicLabel && aumPeriodicLabel.length ?
												<div className='e-bar-scroll'>
													<div className="col-lg-12 ms-3  ">
														<BarChart data={aumPeriodicData} label={aumPeriodicLabel} />
													</div>
												</div>
												:
												null
											:
											null
									}
								</div>
							</div> */}
							<div className="col-lg-6 mt-2">
								<div className="card e-dashboard-card e-dashboard-row-2 border-0 p-3">
									<h6 className="e-card-title">Users</h6>
									{
										periodicLoader === false ?
											userPeriodicData && userPeriodicData.length &&
												userPeriodicLabel && userPeriodicLabel.length ?
												<div className='e-bar-scroll'>
													<div className="col-lg-12 ms-3  ">
														<BarChartSecond
															data={userPeriodicData}
															label={userPeriodicLabel}
														/>
													</div>
												</div>
												:
												null
											:
											null
									}
								</div>
							</div>
							<div className="col-lg-6 mt-2">
								<div className="card e-dashboard-card e-dashboard-row-2 border-0 p-3">
									<h6 className="e-card-title">AUM</h6>
									{
										periodicLoader === false ?
											performancePeriodicData && performancePeriodicData.length &&
												performancePeriodicLabel && performancePeriodicLabel.length ?
												<div className='e-bar-scroll'>
													<div className="col-lg-12 ms-4  ">
														<BarChartLast
															data={performancePeriodicData}
															label={performancePeriodicLabel}
														/>
													</div>
												</div>
												:
												null
											:
											null
									}
								</div>
							</div>
						</div>
					</>
					:
					<Loader />
			}

			{/* --------------- error message toast---------- */}
			{
				error !== null ?
					<Toast
						data={error}
						format={1}
						type="fail"
					/>
					:
					null
			}
		</>
	);
}
export default Index;