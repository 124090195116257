/*
 *   File : detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 21-12-2021
 */
/*import packages*/
import { useState } from 'react';
import moment from 'moment';

/*import assets*/
import Users from '../../assets/images/card/users.png';
import Most from '../../assets/images/card/most-drop.svg';
import Drop from '../../assets/images/card/drop.svg';
import Notinvested from '../../assets/images/card/notinvested.svg';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { FilterBtn } from '../../components/cta/index';
import { Tab, } from '../../components/dataFormating/index';
import { SearchInput, DateRangePicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Summary } from '../../components/card/index';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

const AnalyticsDetails = (props) => {
	const [search, setSearch] = useState();
	const [tabIndex, setTabindex] = useState(1);
	//eslint-disable-next-line
	const [selected, setSelected] = useState({});
	//eslint-disable-next-line
	const [selectedType, setSelectedType] = useState({});
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: null, end: null });

	const data = [
		{ id: 'CYC121', name: 'Ralph Edwards', joined: '12 Jun 2020', risk: '5', aum: '₹ 5000', score: 7.5 / 10, email: 'ralphedwards@outlook.com ', mobile: '+91 8414584132' },
		{ id: 'CYC122', name: 'Devon Lane', joined: '12 Jun 2020', risk: '10', aum: '₹ 5500', score: 7.5 / 10, email: 'ralphedwards@outlook.com ', mobile: '+91 8414584132' },
		{ id: 'CYC123', name: 'Brooklyn Simmons', joined: '12 Jun 2020', risk: '4', aum: '₹ 6000', score: 7.5 / 10, email: 'ralphedwards@outlook.com ', mobile: '+91 8414584132' },
		{ id: 'CYC124', name: 'Jane Cooper', joined: '12 Jun 2020', risk: '5', aum: '₹ 8000', score: 7.5 / 10, email: 'ralphedwards@outlook.com ', mobile: '+91 8414584132' },
		{ id: 'CYC125', name: 'Marvin McKinney', joined: '12 Jun 2020', risk: '7.5', aum: '₹ 7000', score: 7.5 / 10, email: 'ralphedwards@outlook.com ', mobile: '+91 8414584132' },
		{ id: 'CYC126', name: 'Kathryn Murphy', joined: '12 Jun 2020', risk: '6.8', aum: '₹ 4000', score: 7.5 / 10, email: 'ralphedwards@outlook.com ', mobile: '+91 8414584132' },
	];

	const columns = [
		{
			name: 'Cust ID',
			selector: (row) => row.id,
			sortable: true,
			left: true,
			maxWidth: "160px",

		},
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
			left: true,

		},
		{
			name: 'Joined on',
			selector: (row) => row.joined,
			sortable: true,
			right: false,
		},
		{
			name: 'Risk',
			selector: (row) => row.risk,
			sortable: true,
			right: false,
			maxWidth: "100px",
		},
		{
			name: 'AUM',
			selector: (row) => row.aum,
			sortable: true,
			right: false,
			maxWidth: "100px",
		},
		{
			name: 'Score',
			selector: (row) => row.score,
			sortable: true,
			right: false,
			maxWidth: "100px",
		},
		// {
		//   name: 'Email',
		//   selector: 'email',
		//   sortable: false,
		//   right: false,
		//   maxWidth: "180px",
		//   cell: row => <div className="d-block">{row.email}</div>
		// },
		{
			name: 'Phone',
			selector: (row) => row.mobile,
			sortable: false,
			right: false,
			// cell: row => <div className="d-block" style={{minWidth:"130px"}}>{row.mobile}</div>
		},

	];

	/*---- select box options ----*/

	const options = [
		{
			value: 1, label: 'weekly',
		},
		{
			value: 2, label: 'monthly'
		},
		{
			value: 3, label: 'yearly'
		},
	]

	const type = [
		{
			value: 1, label: 'All',
		},
		{
			value: 2, label: 'Open'
		},
		{
			value: 3, label: 'Hold'
		},
	]

	return (
		<>
			<div className="row e-dashboard">
				<div className="col-lg-3">
					<Summary data={{ title: 'Total Users', count: '25', icon: Users, text: "k" }} />
				</div>
				<div className="col-lg-3">
					<Summary data={{ title: 'Users Added', count: '2', icon: Most, text: "k" }} />
				</div>
				<div className="col-lg-3">
					<Summary data={{ title: 'Users Lost', count: '2', icon: Drop, text: "k" }} />
				</div>
				<div className="col-lg-3">
					<Summary data={{ title: 'Retention rate', count: '92.50', icon: Notinvested, text: "%" }} />
				</div>
			</div>
			<div className="row">

				<div className="col-lg-12 mt-4 mb-3">
					<Tab data={['Drop offs', 'Not Invested']} setTab={(temp) => setTabindex(temp)} />
				</div>
				{
					tabIndex === 1 ?
						<div className="row mt-3 pe-0">
							<div className="col-lg-12 pe-0">
								<div className="card e-dashboard-card border-0 ">
									<div className="row">
										<div className="col-lg-8">
											<h6 className="e-card-title p-2">Drop Off users</h6>
										</div>
										<div className="col-lg-3 position-relative">
											<SearchInput placeholder="Search with users" value={search} valueChange={(value) => { setSearch(value) }} />
											{
												search && search.length ?
													<>
														<span className={`${styles.e_search_submit} `} onClick={() => setSearch("")} ></span>
														<button className={`${styles.e_search_close} pe-3`} onClick={() => setSearch("")} ></button>
													</>
													:
													null
											}

										</div>
										<div className="col-lg-1">
											<FilterBtn className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"} handleClick={() => setFilterDown(!filterDown)} />
										</div>
										{
											filterDown ?
												<div className="e-filter-wraper e-sort mt-3">
													<div className="row">
														<div className="col-lg-3 pe-0">
															<label className="mb-2">Joined from - till</label>
															<DateRangePicker start={dates.start} end={dates.end} selectPrevious={true} maxDate={moment().format('DD-MM-YYY')} onDatesChange={(start, end) => { setDates({ start: start, end: end }); }} />
														</div>
														<div className="col-lg-2 px-2">
															<label className="mb-2">Status</label>
															<SelectBox options={options} defaultValue={options[0]} selectchange={(temp) => setSelected(temp)} isSearchable={false} />

														</div>
														<div className="col-lg-2  p-0">
															<label className="mb-2">Type</label>
															<SelectBox options={type} defaultValue={options[0]} selectchange={(temp) => setSelectedType(temp)} isSearchable={false} />

														</div>
													</div>
												</div>
												:
												null
										}
										<div className="col-lg-12 px-0">
											<DataTable pagination={true} columns={columns} data={data} rowClick={() => { window.location.href = '/user-detail' }} />
										</div>
									</div>
								</div>
							</div>
						</div>
						:
						<div className="row mt-3 pe-0">
							<div className="col-lg-12 pe-0">
								<div className="card e-dashboard-card border-0 p-3">
									<div className="row">
										<div className="col-lg-8">
											<h6 className="e-card-title">Not Invested users</h6>
										</div>
										<div className="col-lg-3 position-relative">
											<SearchInput placeholder="Search with users" value={search} valueChange={(value) => { setSearch(value) }} />
											{
												search && search.length ?
													<>
														<span className={`${styles.e_search_submit} `} onClick={() => setSearch("")} ></span>
														<button className={`${styles.e_search_close} pe-3`} onClick={() => setSearch("")} ></button>
													</>
													:
													null
											}

										</div>
										<div className="col-lg-1">
											<FilterBtn className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"} handleClick={() => setFilterDown(!filterDown)} />
										</div>
										{
											filterDown ?
												<div className="e-filter-wraper mt-3">
													<div className="row">
														<div className="col-lg-3 pe-0">
															<label className="mb-2">Joined from - till</label>
															<DateRangePicker start={dates.start} end={dates.end} selectPrevious={true} maxDate={moment().format('DD-MM-YYY')} onDatesChange={(start, end) => { setDates({ start: start, end: end }); }} />
														</div>
														<div className="col-lg-2 px-2">
															<label className="mb-2">Status</label>
															<SelectBox options={options} defaultValue={options[0]} selectchange={(temp) => setSelected(temp)} isSearchable={false} />

														</div>
														<div className="col-lg-2  p-0">
															<label className="mb-2">Type</label>
															<SelectBox options={type} defaultValue={options[0]} selectchange={(temp) => setSelectedType(temp)} isSearchable={false} />

														</div>
													</div>
												</div>
												:
												null
										}
										<div className="col-lg-12 px-0">
											<DataTable pagination={true} columns={columns} data={data} rowClick={() => { window.location.href = '/user-detail' }} />
										</div>
									</div>
								</div>
							</div>
						</div>
				}
			</div>
		</>
	)
}

export default AnalyticsDetails;


