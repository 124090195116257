/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Transactions container;
 *   Integrations : 17-02-2022
 *   Version : v1.0
 *   Created : 13-12-2021
*/
/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';
import moment from 'moment';
/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { FilterBtn, DownloadBtn } from '../../components/cta/index';
import { TransactionDetail } from '../../components/modal/index';
import { SearchInput, DateRangePicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';
import { EmptyNote } from '../../components/note';
/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import View from '../../assets/images/dataFormating/view.png';
import Tooltip from '../../components/badge/tooltip';


/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';


const Index = (props) => {

	const wrapperRef = useRef(null);

	const [search, setSearch] = useState("");
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: null, end: null });
	const [more, setMore] = useState(false);
	const [refKey, setRefKay] = useState();
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState({ label: "Select", value: null });
	const [selectedType, setSelectedType] = useState({ label: "Select", value: null });
	const [statusOptions, setStatusOptions] = useState([]);
	const [typeOptions, setTypeOptions] = useState([]);
	const [check, setCheck] = useState([])
	const [show, setShow] = useState(null);
	const [transactionData, setTransactionData] = useState([]);
	const [details, setDetails] = useState({});
	const [userTransactionData, setUserTransactionData] = useState([]);
	const [userLoader, setUserLoader] = useState(false);
	const [searchLoader, setSearchLoader] = useState(true);
	const [downloadLoader, setDownloadLoader] = useState(false);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	useEffect(() => {
		if (search === "") {
			if (props.id) {
				getUserTransactionList();
			} else {
				getTransactionList();
			}
		}
		//eslint-disable-next-line
	}, [dates, selectedType, selectedStatus, search]);

	useEffect(() => {
		filerData();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (error !== null) {
				setError(null);
			}
		}, 3000);
	}, [error]);

	const customDateSort = (a, b, selector, given) => {
		let format = given ? given : "DD MMM YYYY"
		if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
		else if (a[selector]) return -1;
		else if (b[selector]) return 1;
		else return 0;
	}

	/*---- transactions table columns -----*/

	const columns = [
		{
			name: 'Transaction ID',
			selector: (row) => row.transaction_id ? row.transaction_id : '--',
			sortable: true,
			left: true,
			minWidth: "140px",
			defaultSortAsc: false
		},
		{
			name: props?.tabIndex ? null : 'Name',
			selector: (row) => props?.tabIndex ? null : row.name ? row.name : '--',
			sortable: props.tabIndex ? false : true,
			left: props.tabIndex ? false : true,
			minWidth: props.tabIndex ? "0px" : "150px",
			maxWidth: props.tabIndex ? "0px" : "100%",
		},
		{
			name: 'Dated on',
			selector: "transaction_date",
			sortable: true,
			left: true,
			minWidth: '150px',
			sortFunction: (a, b) => customDateSort(a, b, 'transaction_date',),
			cell: row => <div className="col-lg-12">
				{row.transaction_date}
			</div>
		},
		{
			name: 'Type & Units',
			selector: 'units',
			sortable: true,
			left: true,
			minWidth: '140px',
			cell: (row) => <div className="col-lg-12" onClick={() => modalOpen(row)} >
				{row.type}
				<div className="e-time">{row.units}</div>
			</div>
		},
		{
			name: 'Amount',
			selector: (row) => row.amount ? row.amount : '--',
			sortable: true,
			left: true,
		},
		{
			name: 'Status',
			selector: 'status',
			sortable: true,
			left: true,
			// cell: row => <div className="">
			//   <Badge type="success" text="success"/>
			// </div>

			cell: row => <>{row.status !== null ?
				<div className="d-inline-flex">
					<div className="col-lg-10">
						<div className={
							row.status === "Success" ? `e-status-badge e-status-badge-success` :
								row.status === "Failed" ? `e-status-badge e-status-badge-fail` :
									row.status === "Pending" ? `e-status-badge e-status-badge-pending` :
										'e-status-badge'
						}>
							{row.status.toLowerCase()}
						</div>
					</div>
					<div className="col-lg-2">
						<span onMouseEnter={() => setShow(row.transaction_id)} onMouseLeave={() => setShow(null)} className={
							row.status === "Failed" ? `e-icon` :
								row.status === "Pending" ? `e-icon` :
									null
						}>

						</span>
					</div>
					{
						show === row.transaction_id ?
							<Tooltip content={row.status === "Pending" ? "Transaction pending" : row.payment_failed_reason ? row.payment_failed_reason : "Transaction failed"} />
							:
							null
					}
				</div>
				:
				"--"}</>
		},
		{
			name: '',
			selector: 'actions',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: row => <div className="d-flex align-items-end" onClick={() => { setRefKay(row.transaction_id); setMore(!more); }} ref={wrapperRef}>
				<span className="e-more" >
				</span>
				{
					more && row.transaction_id === refKey ?
						<div className="e-card-transaction" ref={wrapperRef} onClick={() => modalOpen(row)}>
							<span >
								<img src={View} alt="View details" className="me-2" />View Details
							</span>
						</div>
						:
						null
				}

			</div>
		},
	]

	/*--- func to open transaction detail modal ---*/
	const modalOpen = (row) => {
		setDetails(row);
		var myModal = new Modal(document.getElementById("transactionDetail"), {});
		myModal.show();
	}

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false);
		}
	}

	/* --------selecting rows---------*/
	const handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['transaction_id']);
		}
		setCheck(selected);
	}

	/*--------- transactions list -----------*/
	const getTransactionList = () => {
		setLoader(true)
		let url = devconfig.admin + '/transaction/list';

		let data = JSON.stringify({
			from_date: dates.start,
			till_date: dates.end,
			status_id: selectedStatus.value,
			transaction_type_id: selectedType.value,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				var array = [...response.data]; // make a separate copy of the array
				for (let i = 0; i < array.length; i++) {
					delete array[i]['id']
				}
				setTransactionData(array);
				setLoader(false);
			} else if (response.status_code === 400) {
				setTransactionData(response.data);
				setLoader(false);
			}
			else {
				setLoader(false)
				setTransactionData([]);
				setError(response.message);
			}
		});
	}

	/*--------- transaction list of particular user -----------*/
	const getUserTransactionList = () => {
		setUserLoader(true);
		let url = devconfig.admin + '/user/transaction';

		let data = JSON.stringify({
			user_id: props.id,
			from_date: dates.start,
			to_date: dates.end,
			status_id: selectedStatus.value,
			transaction_type_id: selectedType.value,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				var array = [...response.data]; // make a separate copy of the array
				for (let i = 0; i < array.length; i++) {
					delete array[i]['id']
				}
				setUserTransactionData(array);
				setUserLoader(false);
			} else if (response.status_code === 400) {
				setUserTransactionData(response.data);
				setUserLoader(false);
			}
			else {
				setError(response.message);
				setUserLoader(false);
			}
		});
	}

	/*---- filter data for particular user --*/
	const filerData = () => {
		let type_options_temp = [];
		let status_options_temp = [];

		let url = devconfig.admin + '/user/transactions-filter-data';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				response.data.transaction_type.map((item) => {
					type_options_temp.push({ value: item.id, label: item.label });
					return true;
				});
				response.data.transaction_status.map((item) => {
					status_options_temp.push({ value: item.id, label: item.label });
					return true;
				});
				setStatusOptions(status_options_temp);
				setTypeOptions(type_options_temp);
			}
			else {
				setError(response.message);
			}
		});
	}

	/* ------------ Search transactions of particular user -----------*/
	const searchUsersTransactions = (value) => {
		setStatusOptions([]);
		setTypeOptions([]);
		// setSearch(value);
		setSearchLoader(true);
		let url = devconfig.admin + '/user/search-transactions'
		let data = JSON.stringify({
			query: search || value,
			user_id: props.id
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setSearchLoader(false);
				if (props.id) {
					setUserTransactionData(response.data);
				}
				else {
					setTransactionData(response.data);
				}
				filerData();
				setSearch(value);
				setSearch(search);
			}
			else {
				setSearchLoader(false);
				setTransactionData([]);
				setUserTransactionData([])
				setError(response.message);
			}
		});
	}

	/*---- download transactions--*/
	const downloadTransactions = () => {
		setDownloadLoader(true);
		let url = devconfig.admin + '/transaction/download';
		let data = JSON.stringify({
			transaction_ids: check
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					setDownloadLoader(false);
					let a = document.createElement("a");
					a.href = response.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setSearch("");
					setCheck([]);
				} else {
					setDownloadLoader(false);
					setError(response.message)
				}
			}
			else {
				setDownloadLoader(false);
				setError(response.message);
			}
		})
	}

	/*---- Download User's transactions--*/
	const downloadUserTransactions = () => {
		setDownloadLoader(true);
		let url = devconfig.admin + '/user/download-transactions';
		let data = JSON.stringify({
			user_id: Number(props.id),
			transaction_ids: check
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					setDownloadLoader(false)
					let a = document.createElement("a");
					a.href = response.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setSearch("");
					setCheck([]);
				} else {
					setDownloadLoader(false)
					setError(response.message)
				}
			}
			else {
				setDownloadLoader(false)
				setError(response.message);
			}
		})
	}

	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<div className="card e-dashboard-card border-0">
						<div className="row pt-2">
							<div className="col-lg-5">
								<h6 className="e-card-title p-2">All transactions</h6>
							</div>
							<>
								<div className="col-lg-3 align-items-center text-end" >
									<DownloadBtn
										check={check}
										onClick={() => {
											if (props.id) {
												downloadUserTransactions()
											} else {
												downloadTransactions()
											}
										}}
									/>
								</div>
								<div className="col-lg-3 position-relative">
									<SearchInput
										placeholder={props?.placeholder ? props?.placeholder : "Search with id"}
										value={search}
										valueChange={(value) => { setSearch(value) }}
										onSearch={(value) => {
											// if (props.id) {
											searchUsersTransactions(value)
											// } else {

											// }
										}}
									/>
									{
										search && search.length ?
											<>
												<span
													className={`${styles.e_search_submit} `}
													onClick={() => {
														// if (props.id) {
														searchUsersTransactions()
														// }
													}}>
												</span>
												<button
													className={`${styles.e_search_close} pe-3`}
													onClick={() => {
														setSearch("");
														if (props.id) {
															getUserTransactionList();
														} else {
															getTransactionList();
														}
													}}>
												</button>
											</>
											:
											null
									}

								</div>
								<div className="col-lg-1">
									<FilterBtn
										// img={Filter}
										className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
										handleClick={() => {
											setFilterDown(!filterDown);
											setSearch("");
											if (filterDown === true) {
												setDates({ start: null, end: null });
												setSelectedType({});
												setSelectedStatus({});
											}
										}}
									/>
								</div>
							</>
							{/* {
								!userLoader && userTransactionData.length > 0 && props.id ?
									
									:
									searchLoader === false ?
										<>
											<div className="col-lg-3 align-items-center text-end" >
												<DownloadBtn
													check={check}
													onClick={() => {
														if (props.id) {
															downloadUserTransactions()
														} else {
															downloadTransactions()
														}
													}}
												/>
											</div>
											<div className="col-lg-3 position-relative">
												<SearchInput
													placeholder={props?.placeholder ? props?.placeholder : "Search for users"}
													value={search}
													valueChange={(value) => { setSearch(value) }}
													onSearch={(value) => {
														if (props.id) {
															searchUsersTransactions(value)
														} else {

														}
													}}
												/>
												{
													search && search.length ?
														<>
															<span
																className={`${styles.e_search_submit} `}
																onClick={() => {
																	if (props.id) {
																		searchUsersTransactions()
																	}
																}}>
															</span>
															<button
																className={`${styles.e_search_close} pe-3`}
																onClick={() => {
																	setSearch("");
																	getUserTransactionList();
																}}>
															</button>
														</>
														:
														null
												}

											</div>
											<div className="col-lg-1">
												<FilterBtn
													img={Filter}
													className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
													handleClick={() => {
														setFilterDown(!filterDown);
														setSearch("");
														if (filterDown === true) {
															setDates({ start: null, end: null });
															setSelectedType({});
															setSelectedStatus({});
														}
													}}
												/>
											</div>
										</>
										:
										null
							}
							{
								!props.id ?
									<>
										<div className="col-lg-3 align-items-center text-end" >
											<DownloadBtn
												check={check}
												onClick={() => {
													if (props.id) {
														downloadUserTransactions()
													} else {
														downloadTransactions()
													}
												}}
											/>
										</div>
										<div className="col-lg-3 position-relative">
											<SearchInput
												placeholder={props?.placeholder ? props?.placeholder : "Search for users"}
												value={search}
												valueChange={(value) => { setSearch(value) }}
												onSearch={(value) => {
													// if (props.id) {
														searchUsersTransactions(value)
													// } else {

													// }
												}}
											/>
											{
												search && search.length ?
													<>
														<span
															className={`${styles.e_search_submit} `}
															onClick={() => {
																// if (props.id) {
																	searchUsersTransactions()
																// }
															}}>
														</span>
														<button
															className={`${styles.e_search_close} pe-3`}
															onClick={() => {
																setSearch("");
																getTransactionList();
															}}>
														</button>
													</>
													:
													null
											}

										</div>
										<div className="col-lg-1">
											<FilterBtn
												img={Filter}
												className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
												handleClick={() => {
													setFilterDown(!filterDown);
													setSearch("");
													if (filterDown === true) {
														setDates({ start: null, end: null });
														setSelectedType({});
														setSelectedStatus({});
													}
												}}
											/>
										</div>
									</>
									:
									null
							} */}

							{/*-------------- Filters  ---------------*/}
							{
								filterDown ?
									<div className="e-filter-wraper e-sort mt-3">
										<div className="row">
											<div className="col-lg-3 pe-0">
												<label className="mb-2">Transactions from - till</label>
												<DateRangePicker
													onDatesChange={(start, end) => {
														setDates({ start: start, end: end })
													}}
												/>
											</div>
											<div className="col-lg-2 px-2">
												<label className="mb-2">Status</label>
												<SelectBox
													value={selectedStatus}
													options={statusOptions}
													selectchange={(temp) => setSelectedStatus(temp)}
													isSearchable={false}
												/>
											</div>
											<div className="col-lg-2  p-0">
												<label className="mb-2">Type</label>
												<SelectBox
													value={selectedType}
													options={typeOptions}
													selectchange={(temp) => setSelectedType(temp)}
													isSearchable={false}
												/>
											</div>
										</div>
									</div>
									:
									null
							}

							{/*--------------- Transaction table -----------*/}
							{
								loader || userLoader ?
									<Loader />
									: (!loader || !userLoader) && (props.id ? !userTransactionData.length : !transactionData.length) ?
										<EmptyNote note="There is no transaction to show" />
										:
										<div className="col-lg-12 px-0">
											<DataTable
												pagination={true}
												columns={columns}
												data={props.id ? userTransactionData : transactionData}
												selectableRows={true}
												clearSelectedRows={downloadLoader ? true : false}
												selectRows={(row) => { handleChange(row) }}
												rowClick={(row) => modalOpen(row)}
											/>
										</div>
							}

						</div>
					</div>
				</div>
			</div>

			{/*--------- Transaction modal component ----------------*/}
			<div ref={wrapperRef}>
				<TransactionDetail
					data={details}
				/>
			</div>
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
		</>
	);
}

export default Index;

