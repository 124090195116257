/*
 *   File : filterBtn.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Filter
 *   Integrations :
 *   Version : v1.0
 *   Created : 9-12-2021
 */

/*Import Packages*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/filter.module.scss';

/* import components */
import Filter from '../../assets/images/cta/filter.png';

export default function FilterBtn(props) {
	return (
		<div
			className={`${styles.e_filter_btn}  ${props.className}`}
			onClick={props.handleClick}>
			<img src={props.img ? props.img : Filter} alt="" />
		</div>
	);
}
