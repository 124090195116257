/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for dashboard container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 15-12-2021
 */

/*import packages*/
import { useState, useRef, useEffect } from 'react';
import moment from 'moment';

/*import assets*/
import Line from '../../assets/images/card/line.svg';
import Percent from '../../assets/images/card/percent.svg';
import View from '../../assets/images/dataFormating/view.svg';
import Remainder from '../../assets/images/dataFormating/remainder.svg';
import Block from '../../assets/images/dataFormating/block.svg';
import Edit from '../../assets/images/card/edit.svg';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import components*/
import { SummaryRevenue, Summary } from '../../components/card/index';
import { DataTable } from '../../components/dataFormating/index';
import { FilterBtn, } from '../../components/cta/index';
import { SearchInput, DateRangePicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import Filter from '../../assets/images/cta/filter.svg';

const Index = (props) => {

	const wrapperRef = useRef(null);
	const [search, setSearch] = useState();
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: null, end: null });
	const [more, setMore] = useState(false);
	const [refKey, setRefKay] = useState(null);
	//eslint-disable-next-line
	const [selectedStatus, setSelectedStatus] = useState({});
	//eslint-disable-next-line
	const [selectedType, setSelectedType] = useState({});
	//eslint-disable-next-line
	const [check, setCheck] = useState([]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	const columns = [
		{
			name: 'Customer ID',
			selector: (row) => row.id,
			sortable: true,
			left: true,
			maxWidth: "160px",
		},
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
			left: true,
		},
		{
			name: 'Transaction ID',
			selector: (row) => row.transaction_id,
			sortable: true,
			left: true,

		},
		{
			name: 'Revenue',
			selector: (row) => row.revenue,
			sortable: true,
			left: true,
		},
		{
			name: 'Dated on',
			selector: (row) => <div className="col-lg-12">
				{row.dated_on}
				<div className="e-time">5.00 PM</div>
			</div>,
			sortable: true,
			left: true,
		},
		{
			name: '',
			selector: 'actions',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: row => <div className="d-flex align-items-end">
				<span className="e-more" onClick={() => { setRefKay(row.id); setMore(!more); }} ref={wrapperRef}></span>
				{
					more && row.id === refKey ?
						<div className="e-card-analytics" ref={wrapperRef} >
							<div onClick={() => { window.location.href = '/user-detail' }}><img src={View} alt="View details" className="me-3" />View Details</div>
							<div className='mt-3'><img src={Remainder} alt="View details" className="me-3" />Remind to Complete</div>
							<div className='mt-3'><img src={Block} alt="View details" className="me-3" />Block User</div>
						</div>
						:
						null
				}

			</div>
		},
	]

	const data = [
		{ id: 'M123', name: 'Ralph Edwards', transaction_id: 'MT126', revenue: '₹500', dated_on: '12 Jun 2020' },
		{ id: 'M124', name: 'Devon Lane', transaction_id: 'MT126', revenue: '₹500', dated_on: '12 Jun 2020' },
		{ id: 'M125', name: 'Brooklyn Simmons ', transaction_id: 'MT126', revenue: '₹500', dated_on: '12 Jun 2020' },
	]

	/* ---- status select options ---*/

	const statusOptions = [
		{
			value: 1, label: 'weekly',
		},
		{
			value: 2, label: 'monthly'
		},
		{
			value: 3, label: 'yearly'
		},
	]

	/* ------- type select box options ---*/

	const typeOptions = [
		{
			value: 1, label: 'All',
		},
		{
			value: 2, label: 'Open'
		},
		{
			value: 3, label: 'Hold'
		},
	]

	/* --- link to user details ---*/
	const userDetailLink = () => {
		window.location.href = '/user-detail'
	}

	const handleChange = (row) => {
		setCheck(row?.selectedRows)
	}

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false)
		}
	}

	return (
		<>
			<div className="row e-dashboard mt-3">
				<div className="col-lg-3">
					<SummaryRevenue data={{ title: 'AUM', count: '25', icon: Line, text: "₹" }} />
				</div>
				<div className="col-lg-3">
					<SummaryRevenue data={{ title: 'Revenue', count: '105', icon: Percent, text: "₹" }} />
				</div>
				<div className="col-lg-3">
					<Summary revenue={{ title: 'Platform fee', count: '0.5', className: 'e-blue-box', icon: Edit, text: "%" }} />
				</div>

			</div>
			<div className="row mt-5">
				<div className="col-lg-12">
					<div className="card e-dashboard-card border-0">
						<div className="row pt-2">
							<div className="col-lg-8">
								<h6 className="e-card-title p-2 ps-1">All commissions</h6>
							</div>
							<div className="col-lg-3 position-relative">
								<SearchInput placeholder="Search for users" value={search} valueChange={(value) => { setSearch(value) }} />
								{
									search && search.length ?
										<>
											<span className={`${styles.e_search_submit} `} onClick={() => setSearch("")} ></span>
											<button className={`${styles.e_search_close} pe-3`} onClick={() => setSearch("")} ></button>
										</>
										:
										null
								}
							</div>
							<div className="col-lg-1">
								<FilterBtn img={Filter} className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"} handleClick={() => setFilterDown(!filterDown)} />
							</div>
							{
								filterDown ?
									<div className="e-filter-wraper e-sort mt-3">
										<div className="row">
											<div className="col-lg-3 pe-0">
												<label className="mb-2">Joined from - till</label>
												<DateRangePicker start={dates.start} end={dates.end} selectPrevious={true} maxDate={moment().format('DD-MM-YYY')} onDatesChange={(start, end) => { setDates({ start: start, end: end }); }} />
											</div>
											<div className="col-lg-2 px-2">
												<label className="mb-2">Status</label>
												<SelectBox options={statusOptions} selectchange={(temp) => setSelectedStatus(temp)} isSearchable={false} />
											</div>
											<div className="col-lg-2  p-0">
												<label className="mb-2">Type</label>
												<SelectBox options={typeOptions} selectchange={(temp) => setSelectedType(temp)} isSearchable={false} />
											</div>
										</div>
									</div>
									:
									null
							}
							<div className="col-lg-12 pt-3 px-0">
								<DataTable pagination={true} columns={columns} data={data} selectRows={(row) => { handleChange(row) }} rowClick={(row) => userDetailLink(row)} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Index;