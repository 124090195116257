/*
 *   File : stackedbar.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : stackedbar.js
 *   Integrations : 
 *   Version : v1.0
 */

/*Package importing*/
import React from 'react';
import Chart from "react-apexcharts";

export default class StackedChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [{
				name: '',
				type: 'bar',
				data: this.props.joined
			}, {
				name: '',
				type: 'bar',
				data: this.props.lost

			}, 
			{
				name: 'Revenue',
				type: 'line',
				data: this.props.growth
			}],
			options: {
				chart: {
					height: 350,
					type: 'bar',
					stacked: true,
				},
				fill: {
					colors: this.props.colors,
				},
				colors: this.props.colors,
				plotOptions: {
					bar: {
						horizontal: false,
						// barHeight: '80%',
						columnWidth: '20%'
					},
				},
				dataLabels: {
					enabled: false
				},

				stroke: {
					width: [1, 1],
					curve: 'straight',
					dashArray: [0, 0, 2],
					// colors: ['#1329E6', '#C2C9FF'],
				},
				grid: {
					xaxis: {
						lines: {
							show: false
						}
					}
				},
				xaxis: {
					categories: this.props.labels,
					labels: {
						style: {
							colors: '#6D6D6D',
							fontSize: '10px',
							fontFamily: 'Monsterrat',
							fontWeight: 500,
						},
						allowDecimals: false,
						lines: {
							show: false
						},
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
							// color: '#008FFB'
						},
					},
				},
				yaxis: [
					{
						
						axisTicks: {
							show: false,
						},
						allowDecimals: false,
						lines: {
							show: false
						},
						axisBorder: {
							show: false,
							color: '#008FFB'
						},
						labels: {
							style: {
								colors: '#6D6D6D',
								fontSize: '10px',
								fontFamily: 'Monsterrat',
								fontWeight: 500,
								cssClass: 'apexcharts-yaxis-label',
							}
						},
						tooltip: {
							enabled: true
						}
					},
				],
				// tooltip: {
				// 	fixed: {
				// 		enabled: true,
						
						
				// 	},
				// },
				
			},

		};
	}


	render() {
		return (
			<Chart
				options={this.state.options}
				series={this.state.series}
				type="bar"
				height={410}
			/>
		);
	}
}




