/*
 *   File : bar.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : bar.js
 *   Integrations : 
 *   Version : v1.0
 */

/*import package*/
import React from 'react';
import Chart from "react-apexcharts";

export default class RadialChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			options: {
				series: [10, 55, 67],
				chart: {
					height: 400,
					type: 'radialBar',
				},
				stroke: {
					lineCap: 'round',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '5%',
							background: 'transparent',
							image: undefined,
						},
						track: {
							margin: 25, // margin is in pixels
						},
						dataLabels: {
							show: false,
						}
					},
				},
				labels: ['Successful', 'Pending', 'Failed'],
				colors: this.props.colors,
			}

		};
	}
	render() {
		const series = [44, 30, 15]
		return (
			<div className="e-radial">
				<Chart
					options={this.state.options}
					series={series}
					type="radialBar"
					width="540"
					height="340"
				/>
			</div>
		);
	}
}

