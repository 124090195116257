/*
 *   File : addFundClass.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : add fund class name and adding fund modal
 *   Integrations :14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import components*/
import { Input } from '../input/index';
import { SecondaryBtn, PrimaryBtn } from '../cta/index';
import { AggressiveFund } from '../../components/card/index';
import { Toast } from '../note';

/*import styles*/
import fundStyles from '../../styles/components/fundClass.module.scss';
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import SearchFunds from '../../assets/images/dataFormating/search-funds.svg'

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { Loader } from '../loader';
import SelectBox from '../dropdown/selectBox';
import APIGet from '../../services/getApi';
export default function FundClass(props) {

	const [name, setName] = useState("");
	const [search, setSearch] = useState("");
	const [funds, setFunds] = useState([]);
	const [globalList, setGlobalList] = useState([]);
	//eslint-disable-next-line
	const [fundId, setFundId] = useState(null);
	const [selectedFundScheme, setSelectedFundScheme] = useState("");
	const [error, setError] = useState(null);
	const [allocation, setAllocation] = useState("");
	const [response, setResponse] = useState(null);
	const [searchLoader, setSearchLoader] = useState(false);
	const [selectClass, setSelectedClass] = useState({ label: "Select global class", value: null })

	useEffect(() => {
		getGlobalClassList()
	}, []);

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error])

	/* ---- search funds api ---*/
	const searchFunds = (value) => {
		setSearchLoader(true)
		let url = devconfig.admin + '/funds/search-mf'

		let data = JSON.stringify({
			query: search,
			page_num: 1,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setFunds(response.data);
				setResponse(response.status);
				setSearchLoader(false);
			}
			else {
				setError(response.message);
				setSearchLoader(false);
			}
		});
	}

	/* list global class api */

	const getGlobalClassList = () => {
		let url = devconfig.admin + "/global-class/list";

		let temp = []
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				response.data.map((item) => {
					return (
						temp.push({
							label: item.class,
							value: item.id
						})

					)
				})
				setGlobalList(temp)
			} else {
				setGlobalList([]);
				// setError(response.message);
			}
		});
	}

	/* --- get scheme code of fund --*/
	const getData = (item) => {
		setFundId(item?.scheme_code)
	}

	/*-----save fund api---- */
	const saveFund = (value) => {
		// var pattern = new RegExp(/^0*(?:[1-9][0-9][0-9]?|100)$/);
		// if (allocation >= 100) {
		let url = devconfig.admin + '/funds/save-risk-fund'

		let data = JSON.stringify({
			risk_score_id: props.risk_id,
			global_class_id: selectClass?.value,
			scheme_code: selectedFundScheme,
			name: name,
			allocation: Number(allocation)
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setFunds(response.data);
				$('#addFundClass').trigger('click');
				props.addedFund(true)
				setAllocation("");
				setName("");
				setFunds([]);
				setSearch("");
				setSelectedClass({ label: "Select global class", value: null })
			}
			else {
				setError(response.message);
			}
		})
		setTimeout(() => {
			setError(null);
		}, 3000);
		// }
		// else {
		// 	setError("Maximum allocation is 100")
		// }
	}

	return (
		<div className={`${fundStyles.e_add_fund} modal fade`} id="addFundClass" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h6> Add new fund </h6>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
							onClick={() => {
								setName("");
								setAllocation("");
								setSearch("");
								setFunds([]);
								setSelectedFundScheme("");
								setResponse(null);
								setSelectedClass({ label: "Select global class", value: null })
							}}>
						</button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className="col-lg-10">
								<label>Asset class</label>
								<Input
									placeholder="Eg: Indian equities"
									value={name}
									valueChange={(value) => setName(value)}
									type="text"
								/>
							</div>
							<div className="col-lg-2 ps-0">
								<label>Allocation</label>
								<Input
									placeholder="100"
									value={allocation}
									valueChange={(value) => setAllocation(value)}
									type="number"
								/>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-lg-12">
								<label>Global class</label>
								<SelectBox
									options={globalList}
									value={selectClass}
									selectchange={(temp) => setSelectedClass(temp)} />
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-lg-12 px-0">
								<div className="px-3">
									<div className="row">
										<div className="col-lg-12 position-relative mb-4 ">
											<label>Search fund</label>
											<Input placeholder="Search for funds" value={search} valueChange={(value) => { setSearch(value) }} />
											{
												search && search.length ?
													<>
														<span className={`${fundStyles.e_search_submit} `} onClick={() => { searchFunds(); }}></span>
														<button className={`${fundStyles.e_search_close} pe-3`} onClick={() => { setSearch(""); setFunds([]); setResponse(null); setSelectedFundScheme(""); setResponse(null); }} ></button>
													</>
													:
													null
											}

										</div>
									</div>
									<div className="row">

										{
											response !== null ?
												funds?.length > 0 ?
													<>
														<p>Results</p>
														{
															funds.map((item, key) => {
																return (
																	<>

																		<div className="col-lg-6 mb-3 pb-2" key={key} onClick={() => { getData(item); }}>
																			<AggressiveFund selectedFund={selectedFundScheme} data={item} selectedFundData={(temp) => { setSelectedFundScheme(temp); console.log(temp) }} />
																		</div>
																	</>
																)
															})
														}
													</>
													:
													<div className="row">
														<h6 className="text-center"> No mutual funds found </h6>
													</div>

												:
												searchLoader === false ?
													<div align="center">
														<img src={SearchFunds} alt="" />
														<p >Search Funds</p>
													</div>
													:
													<Loader />
										}
										{/* {
											searchLoader === null ?
												<div align="center">
													<img src={SearchFunds} alt="" />
													<p >Search Funds</p>
												</div>
												:
												searchLoader === true ?
													<Loader />
													:
													null
										} */}
									</div>
								</div>
							</div>
						</div>
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn
										name="Cancel"
										className={`${styles.e_cancel} e-cancel`}
										cancel="modal"
										handleClick={() => {
											setName("");
											setAllocation("");
											setSearch("");
											setFunds([]);
											setResponse(null);
											setSelectedFundScheme("");
											setSelectedClass({ label: "Select global class", value: null })
										}} />
									{
										name && selectedFundScheme && allocation && selectClass?.value ?
											<PrimaryBtn name="Save fund" className="e-modal-submit" handleClick={() => saveFund()} />
											:
											<PrimaryBtn name="Save fund" className="e-disabled" />
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast type="fail" format={1} data={error} />
					:
					null
			}
		</div>
	)
}
