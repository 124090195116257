/*
 *   File : confirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Delete Modal
 *   Integrations : 09-02-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note/index';

/*import styles*/
import styles from '../../styles/components/confirmation.module.scss';
import { message } from 'antd';
import { useState } from 'react';

/*import services*/
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

export default function Confirmation(props) {

	const [error, setError] = useState(null);


	useEffect(() => {
		setTimeout(() => {
			setError(null)
		}, 3000)
	}, [error])

	/* --- close delete modal on click ---*/
	const closeModal = () => {
		$('#confirmation').trigger('click');
	}

	/* --- Delete risk fund api ---*/
	const DeleteFund = () => {
		let url = devconfig.admin + '/funds/delete-risk-fund';

		let data = JSON.stringify({
			portfolio_id: props?.deleteData?.portfolio_id,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success("Fund Deleted");
				closeModal();
				if (props.deleteFund === true) {
					props.updatedFund(true);
				}
			} else {
				setError(response.message);
			}
		});
	}

	/* --- Delete question api ---*/
	const DeleteQuestion = () => {
		let url = devconfig.admin + '/quiz/remove-question';

		let data = JSON.stringify({
			question_id: props?.data?.question_id,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success("Question Deleted");
				closeModal();
				if (props.deleteFund === true) {
					props.updatedFund(true);
				} else {
					props.updatedQuestion(true)
				}
			} else {
				setError(response.message);
			}
		});
	}

	/* --- Delete question api ---*/
	const DeleteGlobalClass = () => {
		let url = devconfig.admin + '/global-class/delete';

		let data = JSON.stringify({
			global_class_id: props.globalId,
		});
		APIPost(url, data).then((response) => {
			console.log(response)
			if (response.status_code === 200) {
				closeModal();
				if (props.deleteClass) {
					props.deletedClass(true)
				}
			} else {
				setError(response.message);
			}
		});
	}
	return (
		<div className={`${styles.e_confirmation} modal fade`} id="confirmation" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h6>{`Delete ${props?.title}`}</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className="col-lg-12">
								<p>{props?.content}</p>
							</div>
						</div>
						<div className="row pt-4 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" />
									<PrimaryBtn name="Submit" className="e-modal-submit"
										handleClick={() => {
											if (props.deleteFund === true) {
												DeleteFund()
											}
											else {
												if (props.deleteClass) {
													DeleteGlobalClass()
												} else {
													DeleteQuestion()
												}
											}
										}} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast data={error} type="fail" format={1} />
					:
					null
			}
		</div>
	);
}
