/*
 *   File : createPassword.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useState } from 'react';

/*import assets*/

/*import components*/
import { OuterHeader, Footer } from '../../components/navigation/index';
import { Input } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';

/*import container*/


const CreatePassword = (props) => {

	const [password, setPassword] = useState("");
	const [cpassword, setCpassword] = useState("");

	return (
		<>
			<div className="container-fluid e-auth-wrapper">
				<OuterHeader
					type={2}
				/>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 mt-2  pt-5 offset-lg-4">
							<div className="e-form-wrap">
								<h4>Create password</h4>
								<div className="pt-3">
									<label>Password</label>
									<Input
										placeholder="Password"
										value={password}
										valueChange={(value) => { setPassword(value) }}
										type="text"
									/>
								</div>
								<div className="pt-4">
									<label>Confirm password</label>
									<Input
										placeholder="Confirm Password"
										value={cpassword}
										valueChange={(value) => { setCpassword(value) }}
										type="text"
									/>
								</div>
								<div className="row mt-4 pt-2 mb-4">
									<div className="col-lg-12">
										{
											password && cpassword ?
												<PrimaryBtn
													name="Ceate password"
												/>
												:
												<PrimaryBtn
													name="Ceate password"
													className="e-disabled"
												/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="e-auth-footer">
					<Footer />
				</div>
			</div>

		</>
	);
}

export default CreatePassword;
