/*
 *   File : login.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : 19-01-2022
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useState, useEffect } from 'react';

/*import assets*/

/*import components*/
import { OuterHeader, Footer } from '../../components/navigation/index';
import { Input } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../../components/note';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';


const Login = (props) => {
	const [name, setName] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(null);
	const [loader,setLoader] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	/* -------- login api -----*/
	const Authentication = (e) => {
		setLoader(true);

		let url = devconfig.admin + '/auth/login';

		let data = JSON.stringify({
			username: name,
			password: password
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				localStorage.setItem('access_token', response.data.access_token);
				window.location.href = '/'
			} else {
				setLoader(false);
				setError(response.message);
			}
		})
	}

	/*---- validation for password eg: Admin@12 ----*/
	const validate = () => {
		var pattern = new RegExp(/^(?=.*\d)(?=.*?[a-zA-Z])(?=.*?[\W_]).{8,100}$/);
		if (!pattern.test(password)) {
			setError("Password should contain one alphabet, one special character and one digit with minimum 8 characters");
		} else {
			Authentication();
		}
	}

	return (
		<>
			<div className="container-fluid e-auth-wrapper">
				<OuterHeader
					type={1}
				/>
				<div className="container mb-5 pb-5">
					<div className="row mb-5">
						<div className="col-lg-4 col-md-6 mt-2 mb-2 pt-5 offset-lg-4 offset-md-3">
							<div className="e-form-wrap">
								<h4>Admin Login</h4>
								<div className="pt-3">
									<label>Username</label>
									<Input
										placeholder="Username"
										value={name}
										valueChange={(value) => { setName(value) }}
										type="text"
									/>
								</div>
								<div className="pt-4">
									<label>Password</label>
									<Input
										placeholder="Password"
										value={password}
										valueChange={(value) => { setPassword(value) }}
										onSearch={() => { validate() }}
										type="password"
									/>
								</div>
								<div className="row mt-4 pt-2">
									<div className="col-lg-4 col-md-5 col-sm-4">
										{
											name && password ?
												<PrimaryBtn
													name="Login"
													className={loader === true ? "e-btn-loader": ""}
													handleClick={() => { validate() }}
												/>
												:
												<PrimaryBtn
													name="Login"
													className="e-disabled"
												/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer
					type="mailto:support@mintd.com"
				/>
			</div>

			<div>
				{
					error !== null ?
						<Toast
							data={error}
							type="fail"
							format={1}
						/>
						:
						null
				}
			</div>
		</>
	);
}

export default Login;

