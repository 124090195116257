/*
 *   File : badge.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : badge
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-12-2021
 */

import React from 'react';

/*import styles*/
import styles from '../../styles/components/badge.module.scss';

export default function Badge(props) {
	return (
		<span
			className={
				props.type === "Success" ?
					`${styles.e_success} ${props.className}`
					:
					props.type === "Failed" ?
						`${styles.e_fail} ${props.className}`
						:
						`${styles.e_processing} ${props.className}`
			}
			onClick={props.handleClick}>{props.text}
		</span>
	);
}