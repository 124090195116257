/*
 *   File : transactionDetail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations : 10-02-2022
 *   Version : v1.0
 */

/*import packages*/
import React from 'react';

/*import componets*/
import { Badge } from '../../components/badge/index';

/*import styles*/
import styles from '../../styles/components/transactionDetail.module.scss';


export default function TransactionDetail(props) {
	return (
		<>
			{
				props.data ?
					<div className={`${styles.e_transaction_detail} modal fade`} id="transactionDetail" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className={`${styles.e_modal_content} modal-content`}>
								<div className="modal-header px-4 pt-4">
									<h6 className={styles.e_modal_title}>Transaction detail</h6>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
								<div className={`${styles.e_transaction_content}`}>
									<div className="container-fluid px-0">
										<div className="row p-4">
											<div className="col-lg-9 col-9 d-inline-flex">
												<span className={`${styles.e_icon}`}>{props?.data?.name?.charAt(0)}</span>
												<span className={`${styles.e_span} ms-3 pt-1`}>
													<h6>{props.data.name}</h6>
													<p className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.transaction_date}</p>
												</span>
											</div>
											<div className="col-lg-3 text-end">
												{
													props.data.status !== null ?
														<Badge type={props.data.status} text={props?.data?.status?.toLowerCase()} className={`${styles.e_badge}`} />
														:
														null
												}
											</div>
										</div>
									</div>
								</div>
								<div className="container pt-3 mt-1">
									<div className="row">
										<div className={`col-lg-3 ${styles.e_details} mb-2`}>
											<h6>Transaction ID:</h6>
											<p className={`${styles.e_transaction_id}`}>{props.data.transaction_id} </p>
										</div>
										<div className={`col-lg-3 ${styles.e_details} mb-2`}>
											<h6>Type</h6>
											<p className={`${styles.e_transaction_id}`}>{props.data.type} </p>
										</div>
										<div className={`col-lg-3 ${styles.e_details} mb-2`}>
											<h6>Amount</h6>
											<p className={`${styles.e_transaction_id}`}>{props.data.amount} </p>
										</div>
										<div className={`col-lg-3 ${styles.e_details} mb-2`}>
											<h6>Units</h6>
											<p className={`${styles.e_transaction_id}`}>{props.data.units} </p>
										</div>
										<div className={`col-lg-12 ${styles.e_details} mb-2`}>
											<h6>Invested in</h6>
											<p className={`${styles.e_transaction_id}`}>{props.data.mutual_fund} </p>
										</div>
										{/* {
											props.data.payment_failed_reason ?
												<div className={`col-lg-12 ${styles.e_details} mb-2`}>
													<h6>Reason</h6>
													<p className={`${styles.e_transaction_id}`}>{props.data.payment_failed_reason} </p>
												</div>
												:
												null
										} */}
									</div>
								</div>
							</div>
						</div>
					</div>
					:
					null
			}
		</>
	);
}
