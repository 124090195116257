/*
 *   File : addQuestion.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add question modal
 *   Integrations : 17-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { message } from 'antd';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/fundClass.module.scss';
import SelectBox from '../dropdown/selectBox';

/* import assets */
import AddNew from '../../assets/images/dataFormating/add-new.svg';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

export default function AddQuestion(props) {

	const [question, setQuestion] = useState("");
	const [weightage, setWeightage] = useState(null);
	const [type, setType] = useState({});
	const [error, setError] = useState(null);
	const [description, setDescription] = useState("");
	const [loader, setLoader] = useState(false);
	let [answersArrMCQ, setAnswersArrMCQ] = useState([{ answer: "", score: null, }]);
	let [answersArrYN, setanswesArrYN] = useState([{ answer: "", score: null, }]);

	useEffect(() => {
		setTimeout(() => {
			setError(null)
		}, 3000);
	}, [error]);

	const options = [
		{ value: 3, label: "Fill" },
		{ value: 1, label: "MCQ (Multiple Choice Questions)" },
		// { value: 2, label: "Y/N (Yes or No)" },
		{ value: 2, label: "Radio Button" },
		{ value: 4, label: "Score" }
	]

	/*----add Fields for mcq type ------*/
	const addFieldMCQ = () => {
		setAnswersArrMCQ([...answersArrMCQ, { answer: "", score: null, }])
	}

	/*----add Fields for y/n type ------*/
	const addFieldYN = () => {
		setanswesArrYN([...answersArrYN, { answer: "", score: null, }])
	}

	/*----pushing respective values to mcq array having same name------*/
	const handleChange = (val, e, i) => {
		const { name } = e.target
		const list = [...answersArrMCQ]
		list[i][name] = val;
		setAnswersArrMCQ(list)
	}

	/*----pushing respective values to y/n array having same name------*/
	const handleInputChange = (val, e, i) => {
		const { name } = e.target
		const list1 = [...answersArrYN]
		list1[i][name] = (val)
		setanswesArrYN(list1);
	}

	/* ----- add question api ----*/
	const addQuestion = () => {
		setLoader(true);

		let url = devconfig.admin + '/quiz/add-question';

		let data = JSON.stringify({
			topic_id: props?.details?.topic_id,
			question: question,
			question_type: type.value,
			description: description,
			weightage: Number(weightage),
			answers: type.value === 1 ? answersArrMCQ : type.value === 2 ? answersArrYN : []
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success('Topic added successfully')
				$('#addFundClass').trigger('click');
				props.addedQuestion(true);
				setQuestion("");
				setWeightage("");
				setDescription("");
				setType({});
				setAnswersArrMCQ([{ answer: "", score: null, }]);
				setanswesArrYN([{ answer: "", score: null, }]);
				setLoader(false);
			} else {
				setError(response.message);
				setLoader(false);
			}
		})
	}

	/*----   form validation ---*/
	const validateForm = () => {
		let valid = true;
		if (type.value === 1) {
			for (let index = 0; index < answersArrMCQ.length; index++) {
				const element = answersArrMCQ[index];
				if (element.answer === "" || element.score === null) valid = false;
			}
			if (question && weightage !== null && weightage <= 100 && valid) {
				return true;
			}
			else {
				return false;
			}
		}
		if (type.value === 2) {
			for (let index = 0; index < answersArrYN.length; index++) {
				const element = answersArrYN[index];
				if (element.answer === "" || element.score === null) valid = false;
			}
			if (question && weightage !== null && weightage <= 100 && valid) {
				return true;
			}
			else {
				return false;
			}
		}
		if (type.value === 3 || type.value === 4) {
			if (question && weightage !== null && weightage <= 100) {
				return true;
			}
			else {
				return false;
			}
		}
		return false;
	}

	return (
		<>
			<div className={`${styles.e_add_fund} modal fade`} id="addFundClass" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h6> Add question</h6>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									setQuestion("");
									setWeightage("");
									setDescription("");
									setType({});
									setAnswersArrMCQ([{ answer: "", score: null, }]);
									setanswesArrYN([{ answer: "", score: null, }])
								}}
							>
							</button>
						</div>
						<div className="container px-3">
							<div className="row">
								<div className="col-lg-10 mb-3">
									<label>Question</label>
									<Input
										placeholder="What if"
										value={question}
										valueChange={(value) => setQuestion(value)}
										type="text"
									/>
								</div>
								<div className='col-lg-2 mb-3 ps-0 position-relative'>
									<label>Weightage</label>
									<Input
										suffix="%"
										min="0"
										step="1"
										valueChange={(value) => setWeightage(parseFloat(value))}
										className={styles.e_input_box}
										placeholder="0"
										value={parseFloat(weightage)}
										type="number"
									/>
								</div>
								<div className="col-lg-12">
									<label>Description</label>
									<Input
										placeholder="Enter description"
										value={description}
										valueChange={(value) => setDescription(value)}
										type="text"
									/>
								</div>
							</div>
							<div className="row pt-2">
								<div className="col-lg-12 mb-3">
									<label>Type</label>
									<SelectBox
										value={type}
										placeholder="Fill"
										isSearchable={false}
										options={options}
										selectchange={(value) => setType(value)}
									/>
								</div>
							</div>
							{
								type?.value === 1 ?
									<div className="row ms-1 ">
										<p className="ps-0 mt-4 mb-1">Choices</p>

										{
											answersArrMCQ?.map((item, key) => (
												<>
													<h6 className='ps-0 mb-2 mt-1'>Option {key + 1}</h6>
													<div className="row px-0">
														<div className="col-lg-10 mb-3">
															<label>Answer</label>
															<Input
																placeholder="Answer"
																name="answer"
																value={item.answer}
																valueChange={(value, e) => handleChange(value, e, key)}
																type="text"
															/>
														</div>
														<div className='col-lg-2 mb-3 ps-0 pe-0 position-relative'>
															<label>Score</label>
															<Input
																placeholder="0"
																name="score"
																min="0"
																step="1"
																value={parseFloat(item.score)}
																valueChange={(value, e) => handleChange(parseFloat(value), e, key)}
																type="number"
															/>
														</div>
													</div>
												</>
											))
										}
										<div className={`${styles.e_add_new_quiz_sec} col-lg-2 ps-1 mt-1`} >
											<button className={styles.e_add_new_answer} onClick={() => addFieldMCQ()}>
												<span className="pe-2" >
													<img src={AddNew} alt="" />
												</span>Add New
											</button>
										</div>
									</div>
									:
									type?.value === 2 ?
										<>
											<div className="row ms-1">
												<p className="ps-0 mt-4 mb-1">Choices</p>
												{
													answersArrYN?.map((item, key) => (
														<>
															<h6 className='ps-0 mb-2 mt-1'>Option {key + 1}</h6>
															<div className="row px-0">
																<div className="col-lg-10 mb-3">
																	<label>Answer</label>
																	<Input
																		placeholder="Answer"
																		name="answer"
																		value={item.answer}
																		valueChange={(value, e) => handleInputChange(value, e, key)}
																		type="text"
																	/>
																</div>
																<div className='col-lg-2 mb-3 ps-0 pe-0 position-relative'>
																	<label>Score</label>
																	<Input
																		placeholder="0"
																		name="score"
																		value={parseFloat(item.score)}
																		valueChange={(value, e) => handleInputChange(parseFloat(value), e, key)}
																		type="number"
																	/>
																</div>
															</div>
														</>
													))
												}
												{/* {
													answersArrYN.length < 2 ? */}
												<div className={`${styles.e_add_new_quiz_sec} col-lg-2 ps-1 mt-1`}>
													<button className={styles.e_add_new_answer} onClick={() => addFieldYN()}><span className="pe-2" ><img src={AddNew} alt="" /></span>Add New</button>
												</div>
												{/* :
														null
												} */}
											</div>
										</>
										:
										null
							}
							<div className="row pt-4 mt-2 pb-2">
								<div className="col-lg-12">
									<div className="d-flex justify-content-end">
										<SecondaryBtn
											name="Cancel"
											className={`${styles.e_cancel} e-cancel`}
											cancel="modal"
											handleClick={() => {
												setQuestion("");
												setWeightage("");
												setDescription("");
												setType({});
												setAnswersArrMCQ([{ answer: "", score: null, }]);
												setanswesArrYN([{ answer: "", score: null, }])
											}}
										/>
										{
											validateForm() ?
												<PrimaryBtn
													name="Save question"
													className={loader === true ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
													handleClick={() => addQuestion()}
												/>
												:
												<PrimaryBtn
													name="Save question"
													className={`${styles.e_disabled}`}
												/>
										}

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				{
					error ?
						<Toast
							data={error}
							format={1}
							type="fail"
						/>
						:
						null
				}
			</div>
		</>
	);
}
