/*
 *   File : pie.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : pie.js
 *   Integrations : 
 *   Version : v1.0
 */

/*import package*/
import React from 'react';
import Chart from "react-apexcharts";

export default class PieChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			series: this.props.data,
			options: {
				chart: {
					type: 'donut',
				},
				dataLabels: {
					enabled: false,

				},
				legend: {
					fontSize: '14px',
					fontFamily: 'Inter',
					fontWeight: 600,
					show: false
				},
				fill: {
					type: "solid",
					// gradient: {
					//   shade: 'light',
					//   type: 'horizontal',
					//   shadeIntensity: 0.5,
					//   gradientToColors: [],
					//   inverseColors: true,
					//   opacityFrom: 1,
					//   opacityTo: 1,
					//   stops: [0, 50, 100]
					// }
				},
				stroke: {
					show: false
				},
				plotOptions: {
					pie: {
						donut: {
							size: '52%'
						}

					}
				},
				colors: this.props.colors,
				labels: this.props.labels,
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: 300
						},
						legend: {
							position: 'bottom'
						}
					}
				}]
			},
		};
	}
	render() {
		return (
			<div className="donut">
				<Chart
					options={this.state.options}
					series={this.state.series}
					type="donut"
					width="200"
					height="240"
				/>
			</div>
		);
	}
}

