/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import React, { useState } from 'react';
import { Modal } from 'bootstrap';

/*import components*/
import { TextBtn } from '../../components/cta/index';
import { AddFund, ChangeAllocation, EditFundClass, Confirmation } from '../../components/modal/index';

/*import style*/
import styles from '../../styles/components/accordion.module.scss';

/*import assets*/
import Rename from '../../assets/images/dataFormating/rename.svg';

export default function Accordion(props) {

	const [edit, setEdit] = useState(null);
	const [id, setId] = useState(null);



	/* ------ open edit fund Modal -----*/
	const openModal = (data) => {
		setEdit(data)
		var myModal = new Modal(document.getElementById("editFundClass"), {});
		myModal.show();
	}

	return (
		<div className={`${styles.e_faq_accordion}`}>
			<div className="accordion" id="accordionDescription">
				<div className="row mx-0">
					{
						props?.data?.map((item, key) => {
							return (
								<div className={`${styles.e_accordion_sec} col-lg-12`}>
									<div className={`${styles.e_accordion_item} accordion-item`}>
										<h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription" + key} >
											<button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`} >
												<div className="row" onClick={() => { setId(item.portfolio_id); }}>
													{
														item.portfolio_id !== id ?
															<div className="row" >
																<div className="col-lg-10" >
																	<span className={`${styles.e_accordion_title}`}>{item.name}</span>
																	<span className={`${styles.e_accordion_sub_title} ps-2`}>{item.allocation}%</span>
																	{
																		item.portfolio_id === id ?
																			<>
																				<span onClick={() => openModal(item)} edit="true" className="ps-2"><img src={Rename} alt="" /></span>
																			</>
																			:
																			null
																	}
																</div>
																<div className="col-lg-2" >
																	<span className={`${styles.e_accordion_icon}`} ></span>
																</div>
															</div>
															:
															null
													}
												</div>
												<div className="row" onClick={() => { setId(null); }}>
													{
														item.portfolio_id === id ?
															<div className="row" >
																<div className="col-lg-10" >
																	<span className={`${styles.e_accordion_title}`}>{item.name}</span>
																	<span className={`${styles.e_accordion_sub_title} ps-2`}>{item.allocation}%</span>
																	{
																		item.portfolio_id === id ?
																			<>
																				<span onClick={() => openModal(item)} edit="true" className="ps-2"><img src={Rename} alt="" /></span>
																			</>
																			:
																			null
																	}
																</div>
																<div className="col-lg-2" >
																	<span className={`${styles.e_accordion_icon_minus}`} ></span>
																</div>
															</div>
															:
															null
													}
												</div>
											</button>
										</h2>
										{
											item.portfolio_id === id ?
												<div  >
													<div className={`${styles.e_accordion_body} accordion-body mt-3`}>
														<div className={`${styles.e_accordion_wrapper} row pt-3`}>
															{
																item.mf_image ?
																	<span className={`${styles.e_accordion_content_img} col-lg-1`}><img src={item.mf_image} alt="" /></span>
																	:
																	null
															}
															<span className={`${styles.e_accordion_content} col-lg-11`}><div>{item.scheme_name}</div><h6 >Allocation: {item.allocation}%</h6></span>
														</div>
														<div className="row mt-3">
															<div className="col-lg-12 text-start">
																<TextBtn left={true} name="Change fund" className={`${styles.e_edit}`} toggle="modal" target="#addFund" handleClick={() => { setEdit({ ...item }) }} />
																<TextBtn left={true} name="Change allocation" className={`${styles.e_change} ms-3 me-3`} toggle="modal" target="#change" handleClick={() => { setEdit({ ...item }) }} />
																<TextBtn left={true} name="Delete fund" className={`${styles.e_delete}`} toggle="modal" target="#confirmation" handleClick={() => { setEdit({ ...item }) }} />
															</div>
														</div>
													</div>
												</div>
												:
												null
										}
									</div>
								</div>
							)
						})
					}
				</div>
			</div>

			{/*-------------- Change allocation modal -----------*/}
			<ChangeAllocation
				editedData={edit}
				riskId={props.riskId}
				updatedAllocation={(data) => { props.updatedFund(data) }}
			/>
			
			{/*-------------- edit fund class modal -----------*/}
			<EditFundClass
				editedData={edit}
				riskId={props.riskId}
				updatedClass={(data) => { props.updatedFund(data) }}
			/>

			{/*-------------- Add fund modal -----------*/}
			<AddFund
				editedData={edit}
				riskId={props.riskId}
				updatedFund={(data) => { props.updatedFund(data) }}
			/>
			
			{/*-------------- Delete fund modal -----------*/}
			<Confirmation
				title="fund"
				content="Are you sure you want to delete this fund?"
				deleteFund={true}
				deleteData={edit}
				updatedFund={(data) => { props.updatedFund(data) }}
			/>
		</div>
	);
}


