/*
 *   File : profileKyc.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Profile Kyc Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 13-12-2021
 */

/*import packages*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/profileKyc.module.scss';

/*import assets*/
import Tick from '../../assets/images/card/tick.svg';
// import Edit from '../../assets/images/card/edit.png';

const ProfileKyc = (props) => {
	return (
		<>
			<div className={`${styles.e_profileKyc_card} card`} id={props.id}>
				<div className="row">
					<div className="col-lg-12 col-12 text-start d-inline-flex ps-0 pb-2">
						<h5>{props.title}</h5>
						{
							props.verified ?
								<span className={`${styles.e_verified}`}>
									<img src={Tick} className="me-1" alt="" />Verified
								</span>
								:
								null
						}
						{/* {
							props.edit ?
								<img src={Edit} className={`${styles.e_edit_icon} position-absolute`} onClick={() => { props.Cliked(props) }} alt="edit"/>
								:
								null
						} */}
					</div>
					{
						props.type === 1 ?
							<div className={`${styles.e_content_wrap} `}>
								<div className="row">
									<div className="col-lg-4 col-12 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											Name
										</h5>
										<h5 className={`${styles.e_content}`}>
											{props.data?.personal_data?.first_name + ' ' + props.data?.personal_data?.last_name}
										</h5>
									</div>
									<div className="col-lg-4 col-12 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											DOB
										</h5>
										<h5 className={`${styles.e_content}`}>
											{props.data?.personal_data?.dob}
										</h5>
									</div>
									<div className="col-lg-4 col-12 mb-3 ">
										<h5 className={`${styles.e_content_title}`}>
											Marital status
										</h5>
										<h5 className={`${styles.e_content}`}>
											{props.data?.personal_data?.marital_status}
										</h5>
									</div>
									<div className="col-lg-4 col-12 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											Gender
										</h5>
										<h5 className={`${styles.e_content}`}>
											{props.data?.personal_data?.gender}
										</h5>
									</div>
									<div className="col-lg-4 col-12 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											Phone
										</h5>
										<h5 className={`${styles.e_content}`}>
											{props.data?.personal_data?.country_code} {props.data?.personal_data?.phone_number}
										</h5>
									</div>
									<div className="col-lg-4 col-12 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											Occupation
										</h5>
										<h5 className={`${styles.e_content}`}>
											{props.data?.personal_data?.occupation}
										</h5>
									</div>
									<div className="col-lg-4 col-12 mb-0">
										<h5 className={`${styles.e_content_title}`}>
											Email
										</h5>
										<h5 className={`${styles.e_content}`}>
											{props.data?.personal_data?.email}
										</h5>
									</div>
									<div className="col-lg-4 col-12 mb-0">
										<h5 className={`${styles.e_content_title}`}>
											Source of wealth
										</h5>
										<h5 className={`${styles.e_content}`}>
											{props.data?.personal_data?.wealth_source}
										</h5>
									</div>
									<div className="col-lg-4 col-12 mb-0">
										<h5 className={`${styles.e_content_title}`}>
											Annual income (in lakhs)
										</h5>
										<h5 className={`${styles.e_content}`}>
											{props.data?.personal_data?.annual_income}
										</h5>
									</div>
								</div>
							</div>
							: props.type === 2 ?
								<div className={`${styles.e_content_wrap} `}>
									<div className="row">
										<div className="col-lg-4 col-12 mb-3">
											<h5 className={`${styles.e_content_title}`}>
												PAN
											</h5>
											<h5 className={`${styles.e_content}`}>
												{props?.data?.personal_data?.pan}
											</h5>
										</div>
										<div className="col-lg-4 col-12 mb-3">
											<h5 className={`${styles.e_content_title}`}>
												Country
											</h5>
											<h5 className={`${styles.e_content}`}>
												{props?.data?.personal_data?.country}
											</h5>
										</div>
										<div className="col-lg-4 col-12 mb-3">
											<h5 className={`${styles.e_content_title}`}>
												City
											</h5>
											<h5 className={`${styles.e_content}`}>
												{props.data?.address_data?.permanent_address?.city}
											</h5>
										</div>
										<div className="col-lg-4 col-12 mb-3">
											<h5 className={`${styles.e_content_title} mb-1`}>
												Address
											</h5>
											<p className={`${styles.e_content} mb-1`}>
												{props.data?.address_data?.permanent_address?.address_line_1} 
												
												<p className='mb-1'>{props.data?.address_data?.permanent_address?.address_line_2}</p>
												<p className='mb-1'>
													{props.data?.address_data?.permanent_address?.city},
													
												</p>
												<p className='mb-1'>{props.data?.address_data?.permanent_address?.state}</p>
												{props.data?.address_data?.permanent_address?.pincode}
											</p>
										</div>
										{/* <div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title5}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item5}</h5>
										</div>
										<div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title6}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item6}</h5>
										</div>
										<div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title7}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item7}</h5>
										</div>
										<div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title8}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item8}</h5>
										</div>
										<div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title9}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item9}</h5>
										</div> */}
									</div>
								</div>
								: props.type === 3 ?
									<div className={`${styles.e_content_wrap} `}>
										<div className="row">
											<div className="col-lg-4 col-12 mb-3">
												<h5 className={`${styles.e_content_title}`}>
													Account holder
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data?.bank_account_data?.account_holder ?
															props.data?.bank_account_data?.account_holder
															: "--"
													}
												</h5>
											</div>
											<div className="col-lg-4 col-12 mb-3">
												<h5 className={`${styles.e_content_title}`}>
													Bank
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data?.bank_account_data?.bank
															?
															props.data?.bank_account_data?.bank
															:
															"--"
													}
												</h5>
											</div>
											<div className="col-lg-4 col-12 mb-3">
												<h5 className={`${styles.e_content_title}`}>
													Account No.
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data?.bank_account_data?.masked_account_number ?
															props.data?.bank_account_data?.masked_account_number
															:
															"--"
													}
												</h5>
											</div>
											<div className="col-lg-4 col-12 mb-3">
												<h5 className={`${styles.e_content_title}`}>
													IFSC
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data?.bank_account_data?.ifsc_code ?
															props.data?.bank_account_data?.ifsc_code
															:
															"--"
													}
												</h5>
											</div>
											<div className="col-lg-4 col-12 mb-3">
												<h5 className={`${styles.e_content_title}`}>
													Branch
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data?.bank_account_data?.branch ?
															props.data?.bank_account_data?.branch
															:
															"--"
													}
												</h5>
											</div>
										</div>
									</div>
									:
									null
					}
				</div>
			</div>
		</>
	);
}

export default ProfileKyc