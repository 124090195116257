/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Notification container;
 *   Integrations : 24-01-2022
 *   Version : v1.0
 *   Created : 17-12-2021
*/

/*import packages*/
import { useEffect, useState } from 'react';
import { message } from 'antd';

/*import components*/
import { Input, TextArea } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import { EmptyNote, Toast } from '../../components/note';
import { Loader } from '../../components/loader';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';

const Index = () => {

	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [error, setError] = useState(null);
	const [notificationData, setNotificationData] = useState([]);
	// const [tabIndex, setTabindex] = useState(1);
	const [loader, setLoader] = useState(true);
	const [notification, setNotification] = useState(false);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		getNotifications();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	/*----- create notification api ---*/
	const createNotification = () => {
		setNotification(true);
		let url = devconfig.admin + '/notifications/send-pushnotifications'
		let data = JSON.stringify({
			title: title,
			description: content
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success("Notification added successfully");
				getNotifications();
				setContent("");
				setTitle("");
				setNotification(false);
			} else {
				setError(response.message);
				setNotification(false);
			}
		});
	}

	/*----- get notifications list api ---*/
	const getNotifications = () => {
		setLoader(true);
		let url = devconfig.admin + '/notifications/get-notifications'

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setNotificationData(response.data.notifications);
				setLoader(false);
			} else {
				setError(response.message);
				setLoader(false);
				setNotificationData([]);
			}
		});
	}


	return (
		<div className="row e-notification">
			<div className="col-lg-6">
				<div className="card border-0 e-dashboard-card pt-4 px-4 pb-2">
					<div className="col-lg-12  mb-3">
						<h5 className='e-notification-form-title'>Push notifications</h5>
					</div>
					{/* {
						tabIndex === 1 ? */}
					<div className='row px-2'>
						<div className="col-lg-12 mb-4">
							<label>Title</label>
							<Input
								placeholder="Notification title"
								value={title}
								className="e-notification-form-element"
								valueChange={(temp) => { setTitle(temp) }}
							/>
						</div>
						<div className="col-lg-12 mb-4">
							<label>Content</label>
							<TextArea
								placeholder="Content of the push notification"
								value={content}
								className="e-notification-form-element"
								rows="9"
								valueChange={(temp) => { setContent(temp) }}
							/>
						</div>
						<div className="col-lg-12 text-left mb-3">
							{
								title && content ?
									<PrimaryBtn
										name="Send now"
										className={notification === true ? "e-notification-form-btn e-btn-loader" : "e-notification-form-btn"}
										handleClick={() => createNotification()}
									/>
									:
									<PrimaryBtn
										name="Send now"
										className="e-disabled"
									/>
							}
						</div>
					</div>
					{/* :
							null
					} */}
				</div>
			</div>

			{/*---------------- list of push notifications ------------*/}
			<div className="col-lg-12 mt-4 position-relative">
				{
					loader === true ?
						<Loader />
						:
						<div className="e-dashboard-card card border-0 p-4">
							{
								notificationData.length > 0 ?
									<>
										<h6 className="e-card-title mb-3">Push notification history</h6>
										{
											notificationData?.map((item, key) => (
												<div className="row" key={key}>
													<div className="col-lg-12">
														<div className="card border-0 e-notification-history p-3 mb-3">
															<span className="e-notification-history-time d-block">
																{item.date}
															</span>
															<h6 className="e-title mt-1 mb-3">{item.title}</h6>
															<span className="e-notification-history-content d-block">
																{item.description}
															</span>
														</div>
													</div>
												</div>
											))
										}
									</>
									:
									<EmptyNote note="No notification found" />
							}
						</div>
				}
			</div>
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
		</div>
	);
}

export default Index;