/*
 *   File : userDetail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : User Detail Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-12-2021
 */

/*import packages*/
import { useEffect, useState, useRef } from 'react';

/*Style Import*/
import styles from '../../styles/components/userDetail.module.scss';

/* import assets */
import Icon from '../../assets/images/dataFormating/Icon.svg'
import Tooltip from '../badge/tooltip';
import { IconStyleToolTip } from '../badge';

const UserDetail = (props) => {
	const wrapperRef = useRef(null);
	const [tooltip, setTooltip] = useState(false);

	/* ----- closing tooltip on mouse click in any other position of screen ----*/
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setTooltip(false)
		}
	}

	return (
		<div className={`${styles.e_user_detail_card} card`}>
			<div className="row pe-3">
				<div className="col-lg-6 col-2 d-inline-flex">
					<span className={`${styles.e_profile_img}`}>
						{
							props.data.personal_data.first_name ?
								props.data.personal_data.first_name[0].toUpperCase()
								:
								null
						}
						{
							props.data.personal_data.first_name ?
								props.data.personal_data.last_name[0].toUpperCase()
								:
								null
						}

						{/* ${( props.data.personal_data.last_name && props.data.personal_data.last_name[0]).toUpperCase()}`} */}
					</span>
					<span className={`${styles.e_span}`}>
						<h4 className='pt-3'>{props.data.name}</h4>
						<p>Score: {props.data.risk_score}/10</p>
					</span>
				</div>
				<div className={`${styles.e_detail_sec} col-lg-6 col-6`} >
					<div className="row">
						<div className="col-lg-4 col-4">
							<h5 className={`${styles.e_detail_title} mb-3`}>Amount Invested</h5>
							<p className={`${styles.e_detail_sub_title}`} >{props.data.invested_amount}</p>
						</div>
						<div className="col-lg-4 col-4 px-4">
							<h5 className={`${styles.e_detail_title} mb-3`}>Total Returns</h5>
							<p className={`${styles.e_detail_sub_title}`}>{props.data.returns}</p>
						</div>
						{/* <div className="col-lg-4 col-4 position-relative">
							<h5 className={`${styles.e_detail_title} me-3 mb-3`}>XIRR%
								<img src={Icon} ref={wrapperRef} alt=""
									onMouseEnter={() => setTooltip(true)}
									onMouseLeave={() => setTooltip(false)}
									style={{ cursor: "pointer" }}
								/>
							</h5>
							<p className={`${styles.e_detail_sub_title}`}>{props.data.xirr}</p>

						</div> */}

					</div>

				</div>

			</div>
			{
				tooltip ?

					<IconStyleToolTip className="e-icon-tooltip" content="The Extended Internal Rate of Return (XIRR) is a single rate of return that, when applied to each installment, yields the current value of the entire investment" />

					:
					null
			}
		</div>
	)
}

export default UserDetail