/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import React, { useEffect, useState } from 'react';

/*import components*/
import { TextBtn } from '../cta/index';
import {EditQuestion, Confirmation } from '../modal/index';


/*import style*/
import styles from '../../styles/components/accordion.module.scss';

/*import assets*/

export default function AccordionQuiz(props) {

	const [edit, setEdit] = useState(null);
	const [id, setId] = useState(null);

	/*----- getting object onclick of accordion ----*/
	const getEditedData = (data) => {
		props.edit(data)
	}

	useEffect(() => {
		console.log(id)
	}, [id])
	return (
		<div className={`${styles.e_quiz_accordion}`}>
			<div className="accordion" id="accordionDescription">
				<div className="row mx-0">
					{
						props.data.map((item, key) => {
							return (
								<div className={`${styles.e_accordion_sec} col-lg-12`}>
									<div className={`${styles.e_accordion_item} accordion-item`}>
										<h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription" + key}>
											<button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`} >
												<div className="row" onClick={() => { setId(item.question_id); }}>
													{
														item.question_id !== id ?
															<div className='row'>
																<div className="col-lg-10">
																	<span className={`${styles.e_accordion_title}`}>{item.question}</span>
																	<span className={`${styles.e_accordion_sub_title}`}>{item.allocation}</span>
																</div>
																<div className="col-lg-2" >
																	<span className={`${styles.e_accordion_icon}`}></span>
																</div>
															</div>
															:
															null
													}
												</div>
												<div className="row" onClick={() => { setId(null); }}>
													{
														item.question_id === id ?
															<div className='row'>
																<div className="col-lg-10">
																	<span className={`${styles.e_accordion_title}`}>{item.question}</span>
																	<span className={`${styles.e_accordion_sub_title}`}>{item.allocation}</span>
																</div>
																<div className="col-lg-2" >
																	<span className={`${styles.e_accordion_icon_minus}`} ></span>
																</div>
															</div>
															:
															null
													}
												</div>
											</button>
										</h2>
										{
											item.question_id === id ?
												<div >
													<div className={`${styles.e_accordion_body} accordion-body mt-3`}>
														<div className={`${styles.e_accordion_wrapper} row`}>

															{/* <span className={`${styles.e_accordion_content_img} col-lg-1`}><img src={item.icon} alt="" /></span> */}
															{
																item.question_type === 1 || item.question_type === 2 ?
																	<>
																		<h6 className="mb-2">Choices</h6>
																		<ul className={`${styles.e_accordion_content} col-lg-11 ps-3 ms-3 mb-2`}>
																			{
																				item?.answers?.map((choice) => (
																					<li>{choice.answer}</li>
																				))
																			}
																		</ul>
																	</>
																	:
																	item.question_type === 3 ?
																		<>
																			{
																				item.answers.length > 0 ?
																					<>
																						<h6 className="mb-2">Choices</h6>
																						{
																							item?.answers?.map((choice) => (
																								<p className={`${styles.e_accordion_content} col-lg-11 ps-3 mb-2`}>{choice.answer}</p>
																							))
																						}
																					</>
																					:
																					null
																			}
																		</>
																		:
																		<>
																			{
																				item.answers.length > 0 ?
																					<>
																						<h6 className="mb-2">Choices</h6>
																						{
																							item?.answers?.map((choice) => (
																								<span className={`${styles.e_accordion_content} col-lg-11 ps-3 mb-2`}>{choice.answer}</span>
																							))
																						}
																					</>
																					:
																					null
																			}
																		</>
															}
															<div className="row mt-1 ps-2 ">
																<div className="col-lg-12 text-start">
																	<TextBtn left={true} name="Delete question" className={`${styles.e_delete}`} toggle="modal" target="#confirmation" handleClick={() => { setEdit({ ...item }) }} />
																	<TextBtn left={true} name="Edit question" className={`${styles.e_edit} ms-3`} toggle="modal" target="#editQuestion" handleClick={() => { setEdit({ ...item }) }} />
																</div>
															</div>
														</div>
													</div>
												</div>
												:
												null
										}
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
			{/* <ChangeAllocation
				data={edit}
			/> */}
			<EditQuestion
				data={edit}
				details={props?.topicDetails}
				edit={(data) => getEditedData(data)}
			/>
			{/* <EditFundClass
				editData={edit}
			/> */}
			<Confirmation
				data={edit}
				title="question"
				content="Are you sure you want to delete this question?"
				updatedQuestion={(data) => { getEditedData(data) }}
			/>
			{/* <AddFund /> */}
		</div>
	);
}


