/*
 *   File : addTopic.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add topic Success Modal
 *   Integrations : 14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React from 'react';
import $ from 'jquery'

/*import componets*/
import { Input, TextArea } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../../components/note/index';
import { message } from 'antd';
import { Modal } from 'bootstrap';
import { EditTopic } from './index';

/*import styles*/
import styles from '../../styles/components/fundClass.module.scss';

/* import assets */
import Edit from '../../assets/images/cta/edit-topic.svg';
import Delete from '../../assets/images/cta/delete-topic.svg';
import DragIcon from '../../assets/images/card/drag-and-drop.png';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';

export default class AddTopic extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			topic: "",
			error: "",
			topicData: [],
			status: "",
		};
	}

	componentDidMount() {
		this.getTopic()
		if (this.state.status) {
			this.getTopic()
		}
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setTimeout(() => {
			this.setState({
				error: null
			})
		}, 3000)
	}

	/* ---- add topic api ---*/
	addTopic = () => {
		let url = devconfig.admin + '/quiz/add-topic';
		let data = JSON.stringify({
			topic: this.state.topic,
			description: "",
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success('Topic added successfully');
				$('#addTopic').trigger('click');
				this.getTopic();
				this.setState({ topic: "" })
			} else {
				this.setState({ error: response.message })

			}
		})
	}

	/*---- get topics api ---*/
	getTopic = () => {
		let url = devconfig.admin + '/quiz/get-topic';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				this?.props?.topics(response.data)
				this.setState({ topicData: response.data })
			} else {
				this.setState({ error: response.message })
			}
		})
	}

	/* ---- opening modal edit topic and passing the object to the modal ---*/
	editData = (item) => {
		this.setState({ edit: item })
		var myModal = new Modal(document.getElementById("editTopic"), {})
		myModal.show()
	}
	/*--- delete topic api ---*/
	deleteData = (item) => {
		let url = devconfig.admin + '/quiz/remove-topic';
		let data = JSON.stringify({
			topic_id: item.topic_id,
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success('Topic Removed');
				this.getTopic();
			} else {
				this.setState({ error: response.message })
			}
		})
	}
	/*---order api---*/
	saveOrder = () => {
		let url = devconfig.admin + '/quiz/topic-order';
		let request = JSON.stringify({
			topic_id: this.state.orderIdArr,
		})
		APIPost(url, request).then((response) => {
			if (response.status_code === 200) {
				this.getTopic();
				message.success("Topics sorted");
				$('#addTopic').trigger('click');
			} else {
				this.setState({ error: response.message })
			}
		})
	}

	dragStart(e) {
		this.dragged = e.currentTarget;
	}


	dragEnd(e) {
		this.dragged.style.display = 'block';
		this.dragged.style.marginTop = '20px';

		e.target.classList.remove("drag-up");
		this?.over?.classList.remove("drag-up");

		e.target.classList.remove("drag-down");
		this?.over?.classList.remove("drag-down");

		var data = this?.state?.topicData;
		var from = Number(this.dragged.dataset.id);
		console.log('from', from);
		
		var to = Number(this.over.dataset.id);
		console.log('to', to)
		data.splice(to, 0, data.splice(from, 1)[0]);

		//set newIndex to judge direction of drag and drop
		data = data.map((doc, index) => {
			doc.newIndex = index + 1;
			return doc;
		})

		this.setState({ data: data });
		let arr = [];
		for (let i = 0; i < data.length; i++) {
			arr.push(data[i]['topic_id'])
		}
		this.setState({ orderIdArr: arr })
	}

	dragOver(e) {
		e.preventDefault();
		this.dragged.style.display = "none";
		if (e.target.tagName !== "LI") {
			return;
		}


		const dgIndex = JSON.parse(this.dragged.dataset.item).newIndex;
		const taIndex = JSON.parse(e.target.dataset.item).newIndex;
		const animateName = dgIndex > taIndex ? "drag-up" : "drag-down";


		if (this.over && e.target.dataset.item !== this.over.dataset.item) {
			this.over.classList.remove("drag-up", "drag-down");
		}

		if (!e.target.classList.contains(animateName)) {
			e.target.classList.add(animateName);
			this.over = e.target;
		}
	}

	render() {
		return (
			<div className={`${styles.e_add_fund} modal fade`} id="addTopic" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h6> Add/Edit topic</h6>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({ topic: "" })}></button>
						</div>
						<div className="container px-3">
							<div className="row">
								<div className="col-lg-12 position-relative">
									<label>New topic name</label>
									<div className={styles.e_input_box}>
										<Input
											placeholder="Eg: Risk question"
											className={styles.e_input}
											value={this.state.topic}
											valueChange={(value) => this.setState({ topic: value })}
											type="text"
										/>
									</div>
									<label className='mt-3'>Description</label>
									<div className={styles.e_input_box}>
										<TextArea
											placeholder="Description about topic"
											className={styles.e_input}
											value={this.state.topic_desc}
											valueChange={(value) => this.setState({ topic_desc: value })}
											type="text"
										/>
									</div>
									{
										this.state.topic && this.state.topic_desc ?
											<span className={`${styles.e_save}`} onClick={() => this.addTopic()}>Save</span>
											:
											<span className={`${styles.e_save_disabled}`} >Save</span>
									}

								</div>
							</div>

							<div className="row mx-0 mt-4">
								<p className="ps-0 mb-0">Added topics</p>
								{
									this.state.topicData?.map((data, i) => (
										<div className={`${styles.e_choice_sec} col-lg-12 mb-2`} >
											<div onDragOver={this.dragOver.bind(this)} >
												<div className="row d-block">
													<li data-id={i}
														// style={{display:"flex"}}
														key={i}
														draggable='true'
														onDragEnd={this.dragEnd.bind(this)}
														onDragStart={this.dragStart.bind(this)}
														data-item={JSON.stringify(data)}>
														<div className={`row ${styles.e_drag} `}>
															<div className='col-lg-1 position-relative '>
																<img src={DragIcon} className={styles.e_drag_icon} width="20px"/>
															</div>
															<div className="col-md-8 text-start gx-0">
																<span className={`${styles.e_choices}`}>{data.topic}</span>
																<p className={`ps-0 mb-0 ${styles.e_desc_label} mt-3`}>Description</p>
																<span className={`${styles.e_choices}`}>{data.description ? data.description : '--'}</span>
															</div>
															<div className="col-md-3">
																<div className="text-end">
																	<span className="pe-4">
																		<img src={Edit} alt="edit" className={styles.e_topic_icon} onClick={() => { this.editData(data) }} />
																	</span>
																	<span >
																		<img src={Delete} className={styles.e_topic_icon} alt="del" onClick={() => { this.deleteData(data) }} />
																	</span>
																</div>
															</div>
														</div>
													</li>
												</div>
											</div>
										</div>
									))
								}
							</div>
							<div className="row pt-4 mt-2 pb-2">
								<div className="col-lg-12">
									<div className="d-flex justify-content-end">
										<SecondaryBtn
											name="Cancel"
											className={`${styles.e_cancel} e-cancel`}
											cancel="modal"
											handleClick={() => this.setState({ topic: "" })}
										/>
										{
											this.state.orderIdArr ?
												<PrimaryBtn
													name="Save changes"
													className="e-modal-submit"
													handleClick={() => this.saveOrder()}
												/>
												:
												<PrimaryBtn
													name="Save changes"
													className={`${styles.e_disabled}`}
												/>
										}

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				{
					this.state.error ?
						<Toast data={this.state.error} format={1} type="fail" />
						:
						null
				}
				<EditTopic data={this.state.edit} edit={() => this.getTopic()} />
			</div >
		);
	}
}
