/*
 *   File : footer.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Footer
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-12-2021
 */

/*import packages*/
import React from 'react';
import { Link } from 'react-router-dom';

/*import style*/
import styles from '../../styles/components/footer.module.scss';

/*import config*/
import devconfig from '../../config/config';

export default function Footer(props) {

	return (
		<>
			<div className={`${styles.e_footer}`}>
				<div className="row">
					<div className="col-lg-5 col-md-6">
						<div className="d-inline-flex">
							<img src={devconfig.footer.footerLogo} alt="footerLogo" className={`${styles.e_footer_logo} ms-2`} />
							<div className={`${styles.e_footer_left} pt-2`}>{devconfig.footer.footerCopyRight}</div>
						</div>
					</div>
					<div className={props.type ? "col-lg-5 col-md-3 text-end" : "col-lg-7 col-md-3 text-end"}>
						<div className={`${styles.e_social_wrap} d-inline-flex pt-2`}>
							{
								devconfig.footer.footerItems.map((item, key) => {
									return (
										<div  key={item.name} className={key === (devconfig.footer.footerItems.length - 1) ? `${styles.e_link}` : `${styles.e_link} me-5`}>
											<a href={item.link} target="_blank" className='me-2'>{item.name}</a>
										</div>
									)
								})
							}
						</div>
					</div>
					{
						props.type ?
							<div className="col-lg-2 text-end ps-0 pt-1 pe-5">
								<a className={`${styles.e_footer_company_link} d-inline-flex pt-1`} href={props?.type} target="_blank" rel="noreferrer">{devconfig.footer.supportLink}</a>
							</div>
							:
							null
					}

					{/* <div className="col-lg-6 ps-4 mt-4">
            <a className={`${styles.e_footer_company_link} `} href="https://support@cashyu.com/" target="_blank" rel="noreferrer">{devconfig.footer.supportLink}</a>
          </div> */}
					{/* <div className="col-lg-6 text-end mt-4">
            <span className={`${styles.e_footer_label} ms-2`}>Powered By: </span><a className={`${styles.e_footer_powered} `} href="https://evoqueinnovativelab.com/" target="_blank" rel="noreferrer">{devconfig.footer.poweredby}</a>
          </div> */}
				</div>
			</div>
		</>
	)
}

