/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for dashboard container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 21-12-2021
 */

/*import packages*/
import { useState, useRef, useEffect } from 'react';
import moment from 'moment';

/*import assets*/
import Added from '../../assets/images/card/users-added.svg';
import Users from '../../assets/images/card/users.png';
import Lost from '../../assets/images/card/users-lost.svg';
import Rate from '../../assets/images/card/rate.svg';
import Drop from '../../assets/images/card/drop.svg';
import Most from '../../assets/images/card/most-drop.svg';
import Notinvested from '../../assets/images/card/notinvested.svg';
import View from '../../assets/images/dataFormating/view.svg';
// import Remainder from '../../assets/images/dataFormating/remainder.svg';
// import Block from '../../assets/images/dataFormating/block.svg';


/*import styles*/
// import styles from '../../styles/components/searchInput.module.scss';

/*import components*/
import { Summary } from '../../components/card/index';
import { StackedChart } from '../../components/chart/index';
import { DataTable, Tab } from '../../components/dataFormating/index';
import { FilterBtn } from '../../components/cta/index';
import { TransactionDetail } from '../../components/modal/index';
import { DateRangePicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Loader } from '../../components/loader';
import {DownloadBtn} from '../../components/cta';
import {Toast} from '../../components/note';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import { EmptyNote } from '../../components/note';

const Index = (props) => {
	const wrapperRef = useRef(null);
	// const [search, setSearch] = useState();
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: null, end: null });
	const [more, setMore] = useState(false);
	const [refKey, setRefKay] = useState("");
	//eslint-disable-next-line
	const [selectedStatus, setSelectedStatus] = useState({});
	//eslint-disable-next-line
	const [cumulativeFilter, setCumulativeFilter] = useState({ label: "Last twelve months", value: 3 });
	//eslint-disable-next-line
	const [selectedType, setSelectedType] = useState({});
	//eslint-disable-next-line
	const [selectedRow, setSelectedRows] = useState([]);
	const [loader, setLoader] = useState(true);
	const [userData, setUserData] = useState([]);
	const [error, setError] = useState(null);
	const [analyticsJoinedUserData, setAnalyticsJoinedUserData] = useState([]);
	const [analyticsLostUserData, setAnalyticsLostUserData] = useState([]);
	const [analyticsCumulativeLabel, setAnalayticsCumulativeLabel] = useState([]);
	const [analyticsAverageData, setAnalayticsAverageData] = useState([]);
	const [cumulativeLoader, setCumulativeLoader] = useState(true);
	// const [graphData, setUGraphData] = useState();
	const [summaryData, setSummaryData] = useState();
	//eslint-disable-next-line
	const [selected, setSelected] = useState({});
	const [tabIndex, setTabindex] = useState(1);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [toast, setToast] = useState(null);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	useEffect(() => {
		getDropUsersList();
		//eslint-disable-next-line
	}, [dates, tabIndex]);

	useEffect(() => {
		getSummary();
		getCumulativePerformace();
		//eslint-disable-next-line
	}, [tabIndex]);

	/* ----analytics table colums ---*/
	const columns = [
		{
			name: 'Customer ID',
			selector: (row) => row.id,
			sortable: true,
			left: true,
			minWidth: "140px",
		},
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
			left: true,
			minWidth: '160px'
		},
		{
			name: 'Registered',
			selector: (row) => row.password_configured,
			sortable: true,
			left: true,
			cell: (row) => <div className="col-lg-12" onClick={() => nextPage(row)}>
				{row.password_configured === "Drop off" ?
					<div className="e-drop">{row.password_configured}</div>
					:
					<div>{row.password_configured}</div>
				}

			</div>
		},
		{
			name: 'Step 1',
			selector: row => parseFloat(row.step_1),
			sortable: true,
			left: true,
			cell: (row) => <div className="col-lg-12" onClick={() => nextPage(row)}>
				{row.step_1 === "Drop off" ?
					<div className="e-drop">{row.step_1}</div>
					:
					<div>{row.step_1}</div>
				}

			</div>
		},
		{
			name: 'Step 2',
			selector: row => parseFloat(row.step_2),
			sortable: true,
			left: true,
			cell: (row) => <div className="col-lg-12" onClick={() => nextPage(row)}>
				{row.step_2 === "Drop off" ?
					<div className="e-drop">{row.step_2}</div>
					:
					<div>{row.step_2}</div>
				}

			</div>
		},
		{
			name: 'Step 3',
			selector: row => parseFloat(row.step_3),
			sortable: true,
			left: true,
			cell: (row) => <div className="col-lg-12">
				{row.step_3 === "Drop off" ?
					<div className="e-drop" onClick={() => nextPage(row)}>{row.step_3}</div>
					:
					<div>{row.step_3}</div>
				}
			</div>
		},
		{
			name: 'Step 4',
			selector: row => parseFloat(row.step_4),
			sortable: true,
			left: true,
			cell: row => <div className="col-lg-12">
				{row.step_4 === "Drop off" ?
					<div className="e-drop" onClick={() => nextPage(row)}>{row.step_4}</div>
					:
					<div>{row.step_4}</div>
				}

			</div>
		},
		{
			name: 'Portfolio Configured',
			selector: row => <div className="col-lg-12">
				{row.portfolio === "Drop off" ?
					<div className="e-drop" onClick={() => nextPage(row)}>{row.portfolio}</div>
					:
					<div>{row.portfolio}</div>
				}

			</div>,
			sortable: true,
			left: true,
			minWidth: "200px !important;",
		},
		{
			name: '',
			// selector: 'actions',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: row =>
				<>
					{
						row.password_configured !== "Drop off" ?
							<div className="d-flex align-items-end">
								<span className="e-more" onClick={() => { setRefKay(row.id); setMore(!more); }} ref={wrapperRef}></span>
								{
									more && row.id === refKey ?
										<div className="e-card-analytics" ref={wrapperRef} >
											<div onClick={() => { nextPage(row) }}><img src={View} alt="View details" className="me-3" />View Details</div>
											{/* <div ><img src={Remainder} alt="View details" className="me-3" />Remind to Complete</div> */}
											{/* <div ><img src={Block} alt="View details" className="me-3" />Block User</div> */}
										</div>
										:
										null
								}

							</div>
							: null
					}
				</>
		},
	]

	/*--- get drop off users list--- */
	const getDropUsersList = () => {
		setLoader(true)
		let url = devconfig.admin + '/analytics/dropoff-user-list';

		let data = JSON.stringify({
			from_date: dates.start,
			till_date: dates.end,
			kyc_status: tabIndex === 1 ? true : false
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setUserData(response.data);
				setLoader(false);
			}
			else {
				setUserData([]);
				setLoader(false);
			}
		})
	}

	const getSummary = () => {
		let url = devconfig.admin + '/analytics/summary';
		let data = JSON.stringify({
			kyc_status: tabIndex === 1 ? true : false
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setSummaryData(response.data);
			}
		})
	}
	/* ---- route to user-detail ---*/
	const nextPage = (row) => {
		if (row.password_configured !== "Drop off" ) {
			window.location.href = `/user-detail?id=${row.id}&type=${2}`;
		}
	}

	const handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['id']);
		}
		setSelectedRows(selected);
	}

	/*----- options for select box ----*/

	const cumulativeOptions = [
		{
			value: 1, label: 'Last one month',
		},
		{
			value: 2, label: 'Last three months'
		},
		{
			value: 3, label: 'Last twelve months'
		},
	]

	// const statusOptions = [
	// 	{
	// 		value: 1, label: '2021',
	// 	},
	// 	{
	// 		value: 2, label: '2020'
	// 	},
	// 	{
	// 		value: 3, label: '2019'
	// 	},
	// ]

	// const typeOptions = [
	// 	{
	// 		value: 1, label: 'All',
	// 	},
	// 	{
	// 		value: 2, label: 'Open'
	// 	},
	// 	{
	// 		value: 3, label: 'Hold'
	// 	},
	// ]

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false)
		}
	}

	/*---- get cumulative performance ---*/
	const getCumulativePerformace = (value) => {

		if (value) {
			setAnalayticsCumulativeLabel([]);
			setAnalayticsAverageData([]);
			setAnalyticsJoinedUserData([]);
			setAnalyticsLostUserData([]);
		}
		setCumulativeLoader(true)
		let url = devconfig.admin + '/analytics/cumulative-performance'
		let data = JSON.stringify({
			type: value || cumulativeFilter?.value,
			kyc_status: tabIndex === 1 ? true : false
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setCumulativeLoader(false);
				// let arry = response.data.new_users.concat(response.data.departed_users)
				// setUGraphData(arry)
				setAnalyticsJoinedUserData(response.data.new_users);
				setAnalayticsCumulativeLabel(response.data.categories);
				setAnalayticsAverageData(response.data.average_users)
				setAnalyticsLostUserData(response.data.departed_users)
			}
			else {
				setCumulativeLoader(false);
				setError(response.message);
				setAnalayticsCumulativeLabel([]);
				setAnalayticsAverageData([]);
				setAnalyticsJoinedUserData([]);
				setAnalyticsLostUserData([]);
			}
		});
	}

	/*---- download list api--*/
	const downloadData = () => {
		setDownloadLoader(true);

		let url = devconfig.admin + '/user/download-dropped-users';

		let data = JSON.stringify({
			user_ids: selectedRow,
			kyc_status: tabIndex === 1 ? true : false
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					if( response.url) {
						window.open(response.url)
					}
					else{
						setToast('Nothing found');
					}
					setSelectedRows([]);
					setDownloadLoader(false);
				} else {
					setError(response.message);
					setDownloadLoader(false);
					setSelectedRows([]);
				}
			}
			else {
				setError(response.message);
				setDownloadLoader(false);
				setSelectedRows([]);
			}
		})
		setTimeout(()=>{
			setToast(null)
		},3000)
	}

	return (
		<div className='e-analytics-card'>
			{/*------------------- Cards -------------------- */}
			<Tab data={["KYC", "NON-KYC"]} setTab={(temp) => { setTabindex(temp); setFilterDown(false); setDates({ start: null, end: null }) }} currentIndex={tabIndex} />
			{
				tabIndex === 1 ?
					<>
						<div className="row e-dashboard mt-4">
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Total users', count: summaryData && summaryData.total_users, icon: Users, text: "" }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Users invested', count: summaryData && summaryData.new_users, icon: Added, text: "" }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Users not invested', count: summaryData && summaryData.total_drop_off, icon: Lost, text: "" }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Retention rate', count: summaryData && summaryData.retention_rate.toFixed(2), icon: Rate, text: "%" }}
								/>
							</div>

						</div>
						<div className="row mt-5 e-analytics">
							<div className='col-lg-10'>
								<h6 className="e-card-title">Cumulative performance</h6>
							</div>
							<div className='col-lg-2'>
								<SelectBox
									isSearchable={false}
									value={Object.keys(cumulativeFilter).length > 0 ? cumulativeFilter : null}
									options={cumulativeOptions}
									defaultValue={cumulativeOptions[2]}
									className="e-dashboard-card-sort"
									selectchange={(temp) => { setCumulativeFilter(temp); getCumulativePerformace(temp.value) }}
								/>
							</div>

						</div>

						{/* ---------------Analytics stacked graph showing joined , lost and total growth -----------------*/}

						<div className="row">
							<div className="col-lg-12 mt-3">
								<div className="card e-dashboard-card e-dashboard-row-2 border-0 p-3 ">
									<div className="col-lg-12 ms-3">
										<h6 className="e-card-title pt-2">Total customers</h6>
										{
											cumulativeLoader ?
												<Loader />
												:
												analyticsAverageData && analyticsAverageData.length > 0 &&
													analyticsJoinedUserData && analyticsJoinedUserData.length > 0 &&
													analyticsLostUserData && analyticsLostUserData.length > 0 &&
													analyticsCumulativeLabel && analyticsCumulativeLabel.length > 0 ?
													<StackedChart
														colors={['#1329E6', '#C2C9FF', '#ABABAB']}
														joined={analyticsJoinedUserData}
														labels={analyticsCumulativeLabel}
														lost={analyticsLostUserData}
														growth={analyticsAverageData}
													/>
													:
													null
										}
									</div>
								</div>
							</div>
						</div>

						{/* ---------------------Analytics cards -------------------------*/}
						<div className="row e-dashboard mt-5">
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Drop offs', count: summaryData && summaryData.total_drop_off, icon: Drop, text: "" }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Most drop-off step', currency: "", count: summaryData && summaryData.most_dropoff_step, icon: Most, }}
								/>
							</div>
							{/* <div className="col-lg-3">
								<Summary
									data={{ title: 'Avg drop off / month', count: summaryData && summaryData.avg_dropoff_by_month, icon: Drop, }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Not invested yet', count: summaryData && summaryData.no_invested, icon: Notinvested }}
								/>
							</div> */}
						</div>
						<div className="row mt-5">
							<div className="col-lg-12">
								<div className="card e-dashboard-card border-0">
									<div className="row">
										<div className="col-lg-8 ">
											<h6 className="e-card-title p-2">Drop-off users</h6>
											
										</div>
										{/* <div className="col-lg-3 position-relative">
								<SearchInput
									placeholder="Search for users"
									value={search}
									valueChange={(value) => { setSearch(value) }}
								/>
								{
									search && search.length ?
										<>
											<span className={`${styles.e_search_submit} `} onClick={() => setSearch("")} ></span>
											<button className={`${styles.e_search_close} pe-3`} onClick={() => setSearch("")} ></button>
										</>
										:
										null
								}

							</div> */}
										<div className="col-lg-4 d-flex justify-content-end">
										<DownloadBtn
												check={selectedRow}
												onClick={() => downloadData()}
												optionalDisable={true}
											/>
											<FilterBtn
												className={filterDown ? "e-filter-btn e-active ms-2" : "e-filter-btn ms-2"}
												handleClick={() => setFilterDown(!filterDown)}
											/>
										</div>

										{/* --------------Analytics filters -------------------*/}
										{
											filterDown ?
												<div className="e-filter-wraper e-sort mt-3">
													<div className="row">
														<div className="col-lg-3 pe-0">
															<label className="mb-2">Joined from - till</label>
															<DateRangePicker
																start={dates.start}
																end={dates.end}
																selectPrevious={true}
																maxDate={moment().format('DD-MM-YYY')}
																onDatesChange={(start, end) => { setDates({ start: start, end: end }); }}
															/>
														</div>
														{/* <div className="col-lg-2 px-2">
												<label className="mb-2">Status</label>
												<SelectBox
													options={statusOptions}
													selectchange={(temp) => setSelectedStatus(temp)}
													isSearchable={false}
												/>
											</div>
											<div className="col-lg-2  p-0">
												<label className="mb-2">Type</label>
												<SelectBox
													options={typeOptions}
													selectchange={(temp) => setSelectedType(temp)}
													isSearchable={false}
												/>
											</div> */}
													</div>
												</div>
												:
												null
										}

										{/* ------------Drop off user list -----------------*/}
										{
											loader ?
												<Loader />
												:
												userData.length > 0 ?
													<div className="col-lg-12 px-0">
														<DataTable
															pagination={true}
															columns={columns}
															data={userData}
															clearSelectedRows={downloadLoader ? true : false}
															selectableRows={true}
															selectRows={(row) => { handleChange(row) }}
															rowClick={(row) => nextPage(row)}
														/>
													</div>
													:
													<EmptyNote note="User list not found" />
										}
									</div>
								</div>
							</div>
							<TransactionDetail />
						</div>
					</>
					:
					<>
						<div className="row e-dashboard mt-4">
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Total users', count: summaryData && summaryData.total_users, icon: Users, text: "" }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Users invested', count: summaryData && summaryData.new_users, icon: Added, text: "" }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Users not invested', count: summaryData && summaryData.total_drop_off, icon: Lost, text: "" }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Retention rate', count: summaryData && summaryData.retention_rate.toFixed(2), icon: Rate, text: "%" }}
								/>
							</div>

						</div>
						<div className="row mt-5 e-analytics">
							<div className='col-lg-10'>
								<h6 className="e-card-title">Cumulative performance</h6>
							</div>
							<div className='col-lg-2'>
								<SelectBox
									isSearchable={false}
									value={Object.keys(cumulativeFilter).length > 0 ? cumulativeFilter : null}
									options={cumulativeOptions}
									defaultValue={cumulativeOptions[2]}
									className="e-dashboard-card-sort"
									selectchange={(temp) => { setCumulativeFilter(temp); getCumulativePerformace(temp.value) }}
								/>
							</div>

						</div>

						{/* ---------------Analytics stacked graph showing joined , lost and total growth -----------------*/}

						<div className="row">
							<div className="col-lg-12 mt-3">
								<div className="card e-dashboard-card e-dashboard-row-2 border-0 p-3 ">
									<div className="col-lg-12 ms-3">
										<h6 className="e-card-title pt-2">Total customers</h6>
										{
											cumulativeLoader ?
												<Loader />
												:
												analyticsAverageData && analyticsAverageData.length > 0 &&
													analyticsJoinedUserData && analyticsJoinedUserData.length > 0 &&
													analyticsLostUserData && analyticsLostUserData.length > 0 &&
													analyticsCumulativeLabel && analyticsCumulativeLabel.length > 0 ?
													<StackedChart
														colors={['#1329E6', '#C2C9FF', '#ABABAB']}
														joined={analyticsJoinedUserData}
														labels={analyticsCumulativeLabel}
														lost={analyticsLostUserData}
														growth={analyticsAverageData}
													/>
													:
													null
										}
									</div>
								</div>
							</div>
						</div>

						{/* ---------------------Analytics cards -------------------------*/}
						<div className="row e-dashboard mt-5">
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Drop offs', count: summaryData && summaryData.total_drop_off, icon: Drop, text: "" }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Most drop-off step', currency: "", count: summaryData && summaryData.most_dropoff_step, icon: Most, }}
								/>
							</div>
							{/* <div className="col-lg-3">
								<Summary
									data={{ title: 'Avg drop off / month', count: summaryData && summaryData.avg_dropoff_by_month, icon: Drop, }}
								/>
							</div>
							<div className="col-lg-3">
								<Summary
									data={{ title: 'Not invested yet', count: summaryData && summaryData.no_invested, icon: Notinvested }}
								/>
							</div> */}
						</div>
						<div className="row mt-5">
							<div className="col-lg-12">
								<div className="card e-dashboard-card border-0">
									<div className="row">
										<div className="col-lg-8">
											<h6 className="e-card-title p-2">Drop-off users</h6>
										</div>
										{/* <div className="col-lg-3 position-relative">
								<SearchInput
									placeholder="Search for users"
									value={search}
									valueChange={(value) => { setSearch(value) }}
								/>
								{
									search && search.length ?
										<>
											<span className={`${styles.e_search_submit} `} onClick={() => setSearch("")} ></span>
											<button className={`${styles.e_search_close} pe-3`} onClick={() => setSearch("")} ></button>
										</>
										:
										null
								}

							</div> */}
										<div className="col-lg-4 d-flex justify-content-end">
										<DownloadBtn
												check={selectedRow}
												onClick={() => downloadData()}
												optionalDisable={true}
											/>
											<FilterBtn
												className={filterDown ? "e-filter-btn e-active ms-2" : "e-filter-btn ms-2"}
												handleClick={() => setFilterDown(!filterDown)}
											/>
										</div>

										{/* --------------Analytics filters -------------------*/}
										{
											filterDown ?
												<div className="e-filter-wraper e-sort mt-3">
													<div className="row">
														<div className="col-lg-3 pe-0">
															<label className="mb-2">Joined from - till</label>
															<DateRangePicker
																start={dates.start}
																end={dates.end}
																selectPrevious={true}
																maxDate={moment().format('DD-MM-YYY')}
																onDatesChange={(start, end) => { setDates({ start: start, end: end }); }}
															/>
														</div>
														{/* <div className="col-lg-2 px-2">
												<label className="mb-2">Status</label>
												<SelectBox
													options={statusOptions}
													selectchange={(temp) => setSelectedStatus(temp)}
													isSearchable={false}
												/>
											</div>
											<div className="col-lg-2  p-0">
												<label className="mb-2">Type</label>
												<SelectBox
													options={typeOptions}
													selectchange={(temp) => setSelectedType(temp)}
													isSearchable={false}
												/>
											</div> */}
													</div>
												</div>
												:
												null
										}

										{/* ------------Drop off user list -----------------*/}
										{
											loader ?
												<Loader />
												:
												userData.length > 0 ?
													<div className="col-lg-12 px-0">
														<DataTable
															pagination={true}
															columns={columns}
															data={userData}
															selectableRows={true}
															clearSelectedRows={downloadLoader ? true : false}
															selectRows={(row) => { handleChange(row) }}
															rowClick={(row) => nextPage(row)}
														/>
													</div>
													:
													<EmptyNote note="User list not found" />
										}
									</div>
								</div>
							</div>
							<TransactionDetail />
						</div>
					</>
			}
			{
				toast ?
					<Toast data={toast} format={1} type="fail"/>
				:
					null
			}
		</div>
	);
}
export default Index;