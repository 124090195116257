/*
 *   File : config.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Config file for the template
 *   Version : 1.0.0
 *   Created : 10-11-2021
 */

/*import side bar icons*/
import Dashboard from './../assets/images/sidebar/dashboard.svg';
import Users from './../assets/images/sidebar/users.svg';
import Transations from './../assets/images/sidebar/transations.svg';
import Risk from './../assets/images/sidebar/risk.svg';
import Quiz from './../assets/images/sidebar/quiz.svg';
import Content from './../assets/images/sidebar/analytics.svg';
import App from './../assets/images/sidebar/app.svg';
import Report from './../assets/images/sidebar/report.svg';
import Request from './../assets/images/sidebar/request.svg';
import Revenue from './../assets/images/sidebar/revenue.svg'


import DashboardActive from './../assets/images/sidebar/dashboard-active.svg';
import UsersActive from './../assets/images/sidebar/users-active.svg';
import TransationsActive from './../assets/images/sidebar/transation-active.svg';
import RiskActive from './../assets/images/sidebar/risk-active.svg';
import QuizActive from './../assets/images/sidebar/quiz-active.svg';
import ContentActive from './../assets/images/sidebar/analytics-active.svg';
import AppActive from './../assets/images/sidebar/app-active.svg';
import ReportActive from './../assets/images/sidebar/report-active.svg';
import RequestActive from './../assets/images/sidebar/request-active.svg';
import RevenueActive from './../assets/images/sidebar/revenue-active.svg';

import DashboardCollapseActive from './../assets/images/sidebar/dashboard-collapse-active.svg';
import UsersCollapseActive from './../assets/images/sidebar/users-collapse-active.svg';
import TransationsCollapseActive from './../assets/images/sidebar/transation-collapse-active.svg';
import RiskCollapseActive from './../assets/images/sidebar/risk-collapse-active.svg';
import QuizCollapseActive from './../assets/images/sidebar/quiz-collapse-active.svg';
import ContentCollapseActive from './../assets/images/sidebar/analytics-collapse-active.svg';
import AppCollapseActive from './../assets/images/sidebar/app-collapse-active.svg';
import ReportCollapseActive from './../assets/images/sidebar/report-collapse-active.svg';
import RequestCollapseActive from './../assets/images/sidebar/request-collapse-active.svg';
import RevenueCollapseActive from './../assets/images/sidebar/revenue-collapse-active.svg';





/*import footer images*/
import FooterLogo from './../assets/images/navigation/logo.png';

/*import header images*/
import OuterHeaderLogo from './../assets/images/navigation/mintd-logo.svg';

/*----Meta -----*/
const Metadata = { title: 'Evo-admin-template' }

/*----- Sidebar ------ */
const Sidebar = [
	{
		name: 'Dashboard',
		icon: Dashboard,
		link: '/',
		header: 'Overview',
		collapseActiveIcon: DashboardCollapseActive,
		activeIcon: DashboardActive,
	},
	{
		name: 'Users',
		icon: Users,
		link: '/users',
		header: 'Users',
		collapseActiveIcon: UsersCollapseActive,
		activeIcon: UsersActive
	},
	{
		name: 'Orders',
		icon: Revenue,
		link: '/orders',
		header: 'Orders',
		collapseActiveIcon: RevenueCollapseActive,
		activeIcon: RevenueActive
	},
	{
		name: 'Transactions',
		icon: Transations,
		link: '/transactions',
		header: 'Transactions',
		collapseActiveIcon: TransationsCollapseActive,
		activeIcon: TransationsActive
	},
	{
		name: 'Risk and funds',
		icon: Risk,
		link: '/risk-funds',
		header: 'Risk and fund allocations',
		collapseActiveIcon: RiskCollapseActive,
		activeIcon: RiskActive
	},
	{
		name: 'Manage quiz',
		icon: Quiz,
		link: '/quiz',
		header: 'Questionnaire',
		collapseActiveIcon: QuizCollapseActive,
		activeIcon: QuizActive
	},
	{
		name: 'Analytics',
		icon: Content,
		link: '/analytics',
		header: 'User analytics',
		collapseActiveIcon: ContentCollapseActive,
		activeIcon: ContentActive
	},
	{
		name: 'App notifications',
		icon: App,
		link: '/notification',
		header: 'Send app notifications',
		collapseActiveIcon: AppCollapseActive,
		activeIcon: AppActive
	},
	{
		name: 'Monthly report',
		icon: Report,
		link: '/report',
		header: 'Monthly performance report',
		collapseActiveIcon: ReportCollapseActive,
		activeIcon: ReportActive
	},
	{
		name: 'Edit score request',
		icon: Request,
		link: '/request',
		header: 'Request',
		collapseActiveIcon: RequestCollapseActive,
		activeIcon: RequestActive,
		space: "4px",
		length: "19px",
		lengthCollapse: "38px",
		left: "-4px",
		paddingCollapse: "10px 0px 5px 12px",
		padding: "10px 0px 10px 5px"
	},
	{
		name: 'Manage global class',
		icon: Request,
		link: '/manage-class',
		header: 'Manage global class',
		collapseActiveIcon: RequestCollapseActive,
		activeIcon: RequestActive,
		space: "4px",
		length: "19px",
		lengthCollapse: "38px",
		left: "-4px",
		paddingCollapse: "10px 0px 5px 12px",
		padding: "10px 0px 10px 5px"
	},
]
/*-------Outer Header-----*/
const OuterHeader = {
	OuterHeaderLogo: OuterHeaderLogo,
}

/*----- Footer ----- */
const Footer = {
	footerLogo: FooterLogo,
	footerCopyRight: `©  ${new Date().getFullYear()} Mintd Pvt. Ltd.`,
	supportLink: 'support@mintd.com',
	poweredby: 'evoqueinnovativelab.com',
	footerItems: [
		{
			name: 'Home',
			link: 'https://www.mintd.in/#home',
		},
		{
			name: 'Terms',
			link: 'https://www.mintd.in/terms-and-conditions',
		},
		{
			name: 'Privacy',
			link: 'https://www.mintd.in/privacy-policy',
		},
	]
}
const url = "https://api.mintd.in/api/v1/admin"
// const url = "https://api-dev.mintd.in/api/v1/admin"

// const internalURL = "https://4ro2sz8vxf.execute-api.ap-south-1.amazonaws.com/internal/api/v1/admin"
// const updatedURL = "https://135xnkix7b.execute-api.ap-south-1.amazonaws.com/dev/api/v1/admin";
var devconfig = {
	sidebar: Sidebar,
	footer: Footer,
	outerHeader: OuterHeader,
	meta: Metadata,
	admin: url,
	AUTH_URL: '/'
}

export default devconfig;
