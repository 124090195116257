/*
 *   File : changepassword.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Change password Success Modal
 *   Integrations : 24-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';

/*import componets*/
import { Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../../components/note/index';

/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';


export default function ChangePassword(props) {

	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState(null);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	/*--------- password and confirm password validation ------*/
	const validate = () => {
		var pattern = new RegExp(/^(?=.*\d)(?=.*?[a-zA-Z])(?=.*?[\W_]).{8,100}$/);
		if (newPassword !== confirmPassword) {
			setError("Your password and confirm password does not match.");
		}
		else if (!pattern.test(newPassword)) {
			setError("Password contain one special character and one digit with minimum 8 characters");
		} else {
			changePassword();
		}
	}

	/*--------- change password api ------*/
	const changePassword = () => {
		let url = devconfig.admin + '/auth/change-password';

		let data = JSON.stringify({
			current_password: currentPassword,
			new_password: newPassword
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				localStorage.removeItem('access_token');
				window.location.href = '/'

			} else {
				setError(response.message);
			}
		})
	}

	const handlePassword = (value) => {
		setNewPassword(value);
	}

	const handleConfirmpassword = (value) => {
		setConfirmPassword(value);
	}


	return (
		<div className={`${styles.e_add_faq} modal fade`} id="changePassword" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h6>Change password</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className="col-lg-12">
								<label>Current password</label>
								<Input placeholder="Current password" value={currentPassword} valueChange={(value) => { setCurrentPassword(value) }} type="password" />
							</div>
							<div className="col-lg-12 pt-3 mt-2">
								<label>New password</label>
								<Input placeholder="New password" value={newPassword} valueChange={(value) => { handlePassword(value) }} type="password" />
							</div>
							<div className="col-lg-12 pt-3 mt-2">
								<label>Confirm password</label>
								<Input placeholder="Confirm password" value={confirmPassword} valueChange={(value) => { handleConfirmpassword(value) }} type="password" />
							</div>
						</div>
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" />
									{
										currentPassword && newPassword && confirmPassword ?
											<PrimaryBtn name="Save password" className="e-modal-submit" handleClick={() => { validate() }} />
											:
											<PrimaryBtn name="Save password" className={`${styles.e_disabled} e-modal-submit `} />
									}

								</div>
							</div>
						</div>
					</div>
					{
						error ?
							<Toast data={error} type="fail" format={1} />
							:
							null
					}
				</div>
			</div>
		</div>
	)
}
