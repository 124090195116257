/*
 *   File : editFundclass.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : edit fund Modal
 *   Integrations :14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { message } from 'antd';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/fundClass.module.scss';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

export default function EditFundClass(props) {

	const [name, setName] = useState("");
	const [getFundId, setGetFundId] = useState(null);
	const [error, setError] = useState(null);
	const [globalId, setGlobalId] = useState(null)

	useEffect(() => {
		setGetFundId(props?.editedData?.scheme_code)
		setName(props?.editedData?.name)
		setGlobalId(props.editedData?.global_class_id);
	}, [props?.editedData])

	/*-----update fund class name api---- */
	const updateFundClassName = () => {
		let url = devconfig.admin + '/funds/update-risk-fund'
		let data = JSON.stringify({
			portfolio_id: props.editedData.portfolio_id,
			risk_score_id: props.riskId,
			scheme_code: getFundId,
			name: name,
			global_class_id: globalId,
			allocation: Number(props.editedData.allocation)
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				message.success("Class updated successfully");
				$("#editFundClass").trigger('click');
				props.updatedClass(true);
			}
			else {
				setError(response.message);
			}
		})
		setTimeout(() => {
			setError(null);
		}, 3000);
	}

	return (
		<div className={`${styles.e_add_fund} modal fade`} id="editFundClass" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h6>Rename fund class</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className="col-lg-12">
								<label>Title</label>
								<Input placeholder="Title" value={name} valueChange={props?.editedData ? (value) => { setName(value) } : (value) => { setName(value) }} type="text" />
							</div>
						</div>
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn name="Cancel" className={`${styles.e_cancel} e-cancel`} cancel="modal" />
									{
										name ?
											<PrimaryBtn name="Save fund" className="e-modal-submit" handleClick={() => updateFundClassName()} />
											:
											<PrimaryBtn name="Save fund" className="e-disabled" />
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast data={error} type="fail" format={1} />
					:
					null
			}
		</div>
	);
}
