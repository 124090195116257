/*
 *   File : empty.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Empty Note
 *   Integrations :
 *   Version : v1.0
 *   Created : 29-10-2021
 */

/*Import Packages*/

// import style
import style from '../../styles/components/emptyNote.module.scss';

// import assets
import Illustration from '../../assets/images/note/Error-bro.png';

const Nodata = (props) =>{
    return(
        <div className={`${style.e_nodata_wrapper} col-lg-12 text-center mt-5  ` }>
            <img src={Illustration} className={props.className}/>
            <h5>{props.title ? props.title : 'Please try later'}</h5>
            <p>{props.note ? props.note : 'There is nothing found'}</p>
        </div>
    )
}

export default Nodata;
